import {useMemo, useState} from "react";
import {LivreCategorie, livreCategorieDataImpl} from "../../../../constants/type/LivreCategorie";
import moment from "moment";
import _ from "lodash";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";


const useLivreCategorieVariables = (props: any): any => {
    const {snackbarShowMessage, theme, classes} = props;

    const [livreCategorieSel, setLivreCategorieSel] = useState<LivreCategorie>(_.cloneDeep(livreCategorieDataImpl));
    const [livreCategorieList, setLivreCategorieList] = useState<LivreCategorie[]>([]);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [tableKeyValue, setTableKeyValue] = useState(moment().valueOf());
    const [openDialog, setOpenDialog] = useState(false);
    const [savingStateBtn, setSavingStateBtn] = useState(false);

    const mytableState = useMyMaterialReactTable({tableId:'CategoreLivrePage',  updateId:1725875936000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<LivreCategorie>(mytableState), []);
    const activeLang = props.locale.split('-')[0];

    return {
        snackbarShowMessage,theme,classes,tableDataLoading,setTableDataLoading,openDialog,setOpenDialog,
        livreCategorieList,setLivreCategorieList,activeLang,tableKeyValue,setTableKeyValue,
        livreCategorieSel,setLivreCategorieSel,savingStateBtn,setSavingStateBtn,
        ...logicMRT
    }
}

export default useLivreCategorieVariables;