import {LOCALES} from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        'boilerplate.components.Sidebar.dashboard': 'Dashboard',
        'boilerplate.components.Sidebar.config': 'Configuration',
        'boilerplate.components.Sidebar.config.config_submenu': 'Period',
        'boilerplate.components.Sidebar.config.school_period': 'School Period',
        'boilerplate.components.Sidebar.config.school_day': 'School Day',
        'boilerplate.components.Sidebar.config.personal_type': 'Position',
        'boilerplate.components.Sidebar.config.school_document_type': 'School document type',
        'boilerplate.components.Sidebar.config.categorielivre': 'Category of book',
        'boilerplate.components.Sidebar.config.inscription_file_type': 'Inscription file type',
        'boilerplate.components.Sidebar.config.folder_type': 'Folder',
        'boilerplate.components.Sidebar.config.image_rights': 'Image Rights',
        'boilerplate.components.Sidebar.config.dish_menu': 'Meal',
        'boilerplate.components.Sidebar.config.schooling_payment': 'Tuition payment',
        'boilerplate.components.Sidebar.config.classroom': 'Class Names',
        'boilerplate.components.Sidebar.config.appointment_type': 'Appointment Type',
        'boilerplate.components.Sidebar.config.timetable_class': 'Time/Class',
        'boilerplate.components.Sidebar.config.schoolfees': 'Type of fees',
        'boilerplate.components.Sidebar.appointment': 'Appointment',
        'boilerplate.components.Sidebar.appointment.appointment_calendar': 'Appointment calendar',
        'boilerplate.components.Sidebar.appointment.appointment_list': 'Appointment list',
        'boilerplate.components.Sidebar.mesnotifs': 'My notifications',
        'boilerplate.components.Sidebar.user_profile': 'My profile',
        'boilerplate.components.Sidebar.message_center': 'Messages',
        'boilerplate.components.Sidebar.alertmessage': 'Notification',
        'boilerplate.components.Sidebar.school_document': 'School Documents',
        'boilerplate.components.Sidebar.products': 'Products',
        'boilerplate.components.Sidebar.personal': 'Team',
        'boilerplate.components.Sidebar.children': 'Students',
        'boilerplate.components.Sidebar.children_list': 'Students list',
        'boilerplate.components.Sidebar.ims_day': 'My day at IMS',
        'boilerplate.components.Sidebar.security_parent': 'Security',
        'boilerplate.components.Sidebar.week_ims': 'Canteen Week',
        'boilerplate.components.Sidebar.schooling': 'Tuition',
        'boilerplate.components.Sidebar.menu_of_year': 'Menu of year',
        'boilerplate.components.Sidebar.my_books': 'Teacher’s Choice',
        'boilerplate.components.Sidebar.image': 'Image',
        'boilerplate.components.Sidebar.mini_club': 'Mini club',
        'boilerplate.components.Sidebar.exchange_library': 'Exchange Library',
        'boilerplate.components.Sidebar.marketing': 'Marketing',
        'boilerplate.components.Sidebar.books_mangement': 'Exchange Library',
        'boilerplate.components.Sidebar.books': 'Books',
        'boilerplate.components.Sidebar.books_loan': 'Books loaned',
        'boilerplate.components.Sidebar.fraisoneshotenfant': 'One shot fee per child',
        'boilerplate.components.Sidebar.photo': 'Photo',
        'boilerplate.components.Sidebar.sondage': 'Survey',

        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.schooling_dashboard": "Turnover of all fees",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.until_today": "Until today",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.incident": "Incident(s)",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.this_week_dashboard": "This week",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.allergies": "Allergy(s)",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.children_dashboard": "Students",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.this_year_dashboard": "This year",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.turnover_schooling_compare": "Comparative curve tuition actual turnover / Forecast turnover",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.turnover_schooling_forecast": "Forecast theoretical turnover",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.turnover_schooling_actual": "Actual turnover",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.title_amount_box": "Total tuition / Additional fees payment",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.total_amount_schooling_and_fees": "Total turnover",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.total_amount_schooling": "Total tuition payment",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.total_amount_additionalfees": "Total additional fees payment",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.student_number_box": "Children number by gender",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.payment_tuition_type": "Payment by type of fee",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.note_average_box": "Top 5 averages",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.appointment_dashboard_box": "Appointment",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.exchange_book_list": "Book(s)",

        'boilerplate.components.Widgets.page.appointment_calendar.rdv_type': "Appointment type",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_employee_feedback': "Appointment requested",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_report_feedback': "Appointment postpone",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_cancel_feedback': "Appointment canceled",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_parent_feedback': "Awaiting parent's reply",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_parent_noresponse_yet': "The parent didn't reply yet",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_parent_wait_response': "The parent is waiting your reply",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_employee_which_outcome': "Your response ?",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_employee_report': "You have suggested a date",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_parent_report': "Parent have suggested a date",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_someone_report': "have suggested a date",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_confirm': "Appointment agreed",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_employee_cancel': "You canceled appointment",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_someone_cancel': "canceled the appointment",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_parent_cancel': "Parent canceled appointment",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_confirm_rapport': "Appointment agreed",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_notheld_rapport': "Appointment not agreed",
        'boilerplate.components.Widgets.page.appointment_calendar.class_effectif': 'Number of students',
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_status_libelle': 'Status',
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_requested_libelle': 'Requested by',
        'boilerplate.components.Widgets.page.appointment_calendar.comment': 'Comment',
        'boilerplate.components.Widgets.page.appointment_calendar.add_normal': 'Normal',
        'boilerplate.components.Widgets.page.appointment_calendar.add_preset': 'Preset',
        'boilerplate.components.Widgets.page.appointment_calendar.type_normal': 'Normal',
        'boilerplate.components.Widgets.page.appointment_calendar.type_preset': 'Preset',
        'boilerplate.components.Widgets.page.appointment_calendar.all_profiles': 'All profiles',
        'boilerplate.components.Widgets.page.appointment_calendar.all_classes': 'All classes',
        'boilerplate.components.Widgets.page.appointment_calendar.user_appointment_list': 'Appointment list of :',
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.normal_title": "Normal appointment",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.choose_interlocutor": "With an interlocutor",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.choose_child": "For a child",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.preset_title": "Preset appointment",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.rdv_filter_title": "Filter the appointments",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.actions.dialog_post": "Validate",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.actions.dialog_cancel": "Cancel",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.actions.dialog_close": "Close",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.radio.appointment_radio": "Appointment",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.radio.preset_appointment": "Preset appointment",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.title_appointment": "Title",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.title_document_optional": "Document title (optional)",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_status": "Status",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_deleted": "Deleted",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_not_held": "Not held",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_cancel": "Canceled",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_confirm": "Confirmed",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_past": "Past",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_current": "Current",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_upcoming": "Up coming",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_creno": "Time slot",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_done": "Carried out",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_not_respected": "Not respected",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_report": "Postponed",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_reopen": "Reopen",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_report_confirm": "Postpone confirmed",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_partial_confirm": "Partially confirmed",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_wait": "Pending",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.description_appointment": "Description",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.class_preset": "Classroom",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.duration_preset": "Duration (min)",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.deadline_rdv": "Deadline outcome (hours)",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.deadline_rdv_date": "Deadline outcome",
        //"boilerplate.components.Widgets.page.appointment_list.datagrid.export_xls": "Export to xls",
        "boilerplate.components.Widgets.page.message_center.compose_message": "Write a message",
        "boilerplate.components.Widgets.page.message_center.compose_ticket": "Open a new ticket",
        "boilerplate.components.Widgets.page.message_center.conversation_list": "Conversation list",
        "boilerplate.components.Widgets.page.message_center.type_message": "Type a message",
        "boilerplate.components.Widgets.page.message_center.chat_list_empty_message": "You haven't made any conversation yet",
        "boilerplate.components.Widgets.page.message_center.please_write_message": "Please write your message",
        "boilerplate.components.Widgets.page.message_center.please_write_subject_message": "Please write the subject of your message",
        "boilerplate.components.Widgets.page.message_center.please_select_parent": "Please choose parent",
        "boilerplate.components.Widgets.page.message_center.opened_space": "Opened tickets",
        "boilerplate.components.Widgets.page.message_center.closed_space": "Closed tickets",
        "boilerplate.components.Widgets.page.message_center.online_space": "Online",
        "boilerplate.components.Widgets.page.message_center.offline_space": "Offline",
        "boilerplate.components.Widgets.page.message_center.object_message": "Subject",
        "boilerplate.components.Widgets.page.message_center.close_space": "Close the ticket",
        "boilerplate.components.Widgets.page.message_center.delete_space": "Delete the ticket",

        'boilerplate.components.Widgets.page.config.school_period.toolbar_top.add_action': 'Add',
        'boilerplate.components.Widgets.page.config.school_period.toolbar_top.generer_action': 'Generate',
        'boilerplate.components.Widgets.page.config.school_period.toolbar_top.delete_action': 'Delete',
        'boilerplate.components.Widgets.page.config.school_period.toolbar_top.printer_action': 'Print',
        'boilerplate.components.Widgets.page.config.school_period.table.columns.school_period_year': 'Year',
        'boilerplate.components.Widgets.page.config.school_period.table.columns.school_period': 'School period',
        'boilerplate.components.Widgets.page.config.school_period.table.columns.start_period': 'Start period',
        'boilerplate.components.Widgets.page.config.school_period.table.columns.end_period': 'End period',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.dialog_title_period': 'Add school period',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.dialog_edit_title_period': 'Edit school period',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.year_period': 'Year',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.title_school_period': 'School period',
        "boilerplate.components.Widgets.page.config.period.school_day_title": "List of school day",
        "boilerplate.components.Widgets.page.config.period.day_off": "Day off",
        "boilerplate.components.Widgets.page.config.period.day_open": "Day open",
        'boilerplate.components.Widgets.page.config.canteen_week.dialog.form.dialog_title_canteen_week': 'Add a week',
        'boilerplate.components.Widgets.page.config.canteen_week.dialog.form.dialog_edit_title_canteen_week': 'Edit a week',
        'boilerplate.components.Widgets.page.config.canteen_week.dialog.form.title_canteen_week': 'Week',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.dialog_title_personal_type': 'Add position',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.dialog_edit_title_job': 'Edit position',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.dialog_job_role': 'Role',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.title_personal_type': 'Job',
        'boilerplate.components.Widgets.page.config.school_document.dialog.form.dialog_title_school_document_type': 'Add school document type',
        'boilerplate.components.Widgets.page.config.school_document.dialog.form.dialog_edit_title_school_document_type': 'Edit school document type',
        'boilerplate.components.Widgets.page.config.school_document.dialog.form.title_school_document_type': 'School document type',
        'boilerplate.components.Widgets.page.config.school_document.dialog.form.title_document_type': 'Document type',
        'boilerplate.components.Widgets.page.config.inscription_file.dialog.form.dialog_title_inscription_file_type': 'Add inscription file type',
        'boilerplate.components.Widgets.page.config.inscription_file.dialog.form.dialog_edit_title_inscription_file_type': 'Edit inscription file type',
        'boilerplate.components.Widgets.page.config.inscription_file.dialog.form.title_inscription_file_type': 'Inscription file type',
        'boilerplate.components.Widgets.page.config.inscription_file.dialog.form.inscription_file_type': 'File type',
        'boilerplate.components.Widgets.page.config.folder.sub_folder_type': 'Sub folder type',
        'boilerplate.components.Widgets.page.config.folder.dialog.form.dialog_title_sub_folder_type': 'Add sub folder type',
        'boilerplate.components.Widgets.page.config.folder.dialog.form.dialog_edit_title_sub_folder_type': 'Edit sub folder type',
        'boilerplate.components.Widgets.page.config.folder.folder_type': 'Folder type',
        'boilerplate.components.Widgets.page.config.folder.dialog.form.dialog_title_folder_type': 'Add folder type',
        'boilerplate.components.Widgets.page.config.folder.dialog.form.dialog_edit_title_folder_type': 'Edit folder type',
        'boilerplate.components.Widgets.page.config.folder.dialog.form.dialog_folder_type_file': 'Folder type files',
        'boilerplate.components.Widgets.page.config.image_rights.table_columns.image_rights': 'Image rights',
        'boilerplate.components.Widgets.page.config.image_rights.dialog.form.dialog_title_image_right': 'Add image rights',
        'boilerplate.components.Widgets.page.config.image_rights.dialog.form.dialog_edit_title_image_right': 'Edit image rights',


        'boilerplate.components.Widgets.page.config.dish.dialog.form.dialog_title_dish_config': 'Add a meal',
        'boilerplate.components.Widgets.page.config.dish.dialog.form.dialog_title_edit_dish': 'Edit a meal',
        'boilerplate.components.Widgets.page.config.dish.dialog.form.dish_type_config': 'Dish type',
        'boilerplate.components.Widgets.page.config.dish.dialog.form.title_dish_config': 'Name',
        'boilerplate.components.Widgets.page.config.appointment_type.table_columns.appointment_type': 'Appointment type',
        'boilerplate.components.Widgets.page.config.appointment_type.dialog.form.title_appoint_type_config': 'Add appointment type',
        'boilerplate.components.Widgets.page.config.appointment_type.dialog.form.edit_title_appoint_type_config': 'Edit appointment type',
        'boilerplate.components.Widgets.page.config.appointment_type.dialog.form.input_appoint_type': 'Appointment type',

        'boilerplate.components.Widgets.page.config.schooling.proforma_du': 'Proforma invoice from',
        'boilerplate.components.Widgets.page.config.schooling.generer_facture': 'GENERATE PROFORMA INVOICE',
        'boilerplate.components.Widgets.page.config.schooling.notifier_parent': 'NOTIFY THE PARENTS',
        'boilerplate.components.Widgets.page.config.schooling.schooling_echeance_label': 'Due date wording',
        'boilerplate.components.Widgets.page.config.schooling.schooling_add_payment': 'Add a payment',
        'boilerplate.components.Widgets.page.config.schooling.schooling_payment_list': 'Payment list',
        'boilerplate.components.Widgets.page.config.schooling.schooling_proforma_invoice': 'Proforma invoice',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees_label': 'Tuition fee wording',
        'boilerplate.components.Widgets.page.config.schooling.schooling_payment_period': 'Tuition payment period',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees': 'Additional tuition fees',
        'boilerplate.components.Widgets.page.config.schooling.fees': 'Fees',
        'boilerplate.components.Widgets.page.config.schooling.class_fees': 'Fees by group',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees_title': 'Tuition fees',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees_type': 'Type of fees',
        'boilerplate.components.Widgets.page.config.schooling.schooling_versement_etat': 'PAYMENTS STATEMENTS',
        'boilerplate.components.Widgets.page.config.schooling.schooling_save_payment': 'Save payment',
        'boilerplate.components.Widgets.page.config.schooling.schooling_total_payment': 'TOTAL PAYMENT',
        'boilerplate.components.Widgets.page.config.schooling.schooling_restant_apayer': 'OUTSTANDING BALANCE',
        'boilerplate.components.Widgets.page.config.schooling.schooling_total_montant': 'AMOUNT',
        'boilerplate.components.Widgets.page.config.schooling.schooling_total_payment_done': 'PAYMENT DONE',
        'boilerplate.components.Widgets.page.config.schooling.schooling_total_new_payment': 'CURRENT PAYMENT',
        'boilerplate.components.Widgets.page.config.schooling.schooling_total_restant': 'REMAINDER TO PAY',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees_title_custom': 'Custom fee',
        'boilerplate.components.Widgets.page.config.schooling.schooling_other_fees_list': 'List of tuition fees',
        'boilerplate.components.Widgets.page.config.schooling.schooling': 'Schooling',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.dialog_title_schooling': 'Add tuition',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.dialog_edit_title_schooling': 'Edit tuition',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.input.schooling_profile': 'Profile',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.input.schooling_amount': 'Amount',
        'boilerplate.components.Widgets.page.config.schooling.table_grid.schooling_period_payment': 'Deadline',
        'boilerplate.components.Widgets.page.config.schooling.table_grid.payment_date_limite': 'Payment max date',
        'boilerplate.components.Widgets.page.config.schooling.table_grid.date_limite': 'Deadline payment',
        'boilerplate.components.Widgets.page.config.schooling.table_grid.schooling_rappel_payment': 'Title',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.dialog_title_schooling_payment_period': 'Add payment period',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.dialog_edit_title_schooling_payment_period': 'Edit payment period',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees.table_grid.schooling_fees_obligatory': 'Obligatory',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees.table_grid.schooling_fees_echeance': 'Due date',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees.dialog.form.dialog_title_additional_fees': 'Add additional tuition fees',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees.dialog.form.dialog_edit_title_additional_fees': 'Edit additional tuition fees',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees.dialog.form.input.additional_fees_title': 'Fees title',

        'boilerplate.components.Widgets.page.profile.title_password_profile': 'Update password',
        'boilerplate.components.Widgets.page.profile.username_profile': 'Username',
        'boilerplate.components.Widgets.page.profile.old_password_profile': 'Old password',
        'boilerplate.components.Widgets.page.profile.new_password_profile': 'New password',
        'boilerplate.components.Widgets.page.profile.confirm_new_password_profile': 'Confirm new password',
        'boilerplate.components.Widgets.page.profile.password_not_match_profile': 'Password don\'t match',
        'boilerplate.components.Widgets.page.profile.title_profile': 'Profile',


        'boilerplate.components.Widgets.page.personal.dialog.form.dialog_title_personal': 'Add a team member',
        'boilerplate.components.Widgets.page.personal.dialog.form.dialog_title_edit_personal': 'Edit information',
        'boilerplate.components.Widgets.page.personal.dialog.form.firstname_personal': 'First name',
        'boilerplate.components.Widgets.page.personal.dialog.form.lastname_personal': 'Last name',
        'boilerplate.components.Widgets.page.personal.dialog.form.birth_date_personal': 'Date of birth',
        'boilerplate.components.Widgets.page.personal.dialog.form.hire_date_personal': 'Hire Date',
        'boilerplate.components.Widgets.page.personal.dialog.form.email_personal': 'Email',
        'boilerplate.components.Widgets.page.personal.dialog.form.phone_number_personal': 'Phone number',
        'boilerplate.components.Widgets.page.personal.dialog.form.type_personal': 'Team type',
        'boilerplate.components.Widgets.page.personal.dialog.form.genre_personal': 'Gender',
        'boilerplate.components.Widgets.page.personal.dialog.form.more_info_personal': 'More information',
        'boilerplate.components.Widgets.page.personal.dialog.form.job_name_personal': 'Position',
        'boilerplate.components.Widgets.page.personal.dialog.form.post_personal': 'Post',
        'boilerplate.components.Widgets.page.personal.table.header.table_h_person_avatar': 'Avatar',
        'boilerplate.components.Widgets.page.personal.table.header.table_h_person_email': 'Email',
        'boilerplate.components.Widgets.page.personal.table.header.table_h_person_phone': 'Phone number',
        'boilerplate.components.Widgets.page.personal.table.header.table_h_person_job': 'Position',
        'boilerplate.components.Widgets.page.personal.table.header.table_h_account_status': 'Account status',




        'boilerplate.components.Widgets.page.children.dialog.form.dialog_title_child': 'Add a student',
        'boilerplate.components.Widgets.page.children.dialog.form.dialog_title_edit_child': 'Edit a student',
        'boilerplate.components.Widgets.page.children.dialog.form.identity_child': 'Identity',
        'boilerplate.components.Widgets.page.children.dialog.form.parent_child': 'Parent(s)',
        'boilerplate.components.Widgets.page.children.dialog.form.other_details_child': 'Other information',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_child': 'Emergency',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_contact_child': 'Emergency contacts',
        'boilerplate.components.Widgets.page.children.dialog.form.firstname_child': 'First name',
        'boilerplate.components.Widgets.page.children.dialog.form.lastname_child': 'Last name',
        'boilerplate.components.Widgets.page.children.dialog.form.birth_date_child': 'Date of birth',
        'boilerplate.components.Widgets.page.children.dialog.form.classroom_child': 'Classroom',
        'boilerplate.components.Widgets.page.children.dialog.form.scolaritee_annee': 'Schooling',
        'boilerplate.components.Widgets.page.children.dialog.form.nationality_child': 'Nationality',
        'boilerplate.components.Widgets.page.children.dialog.form.country': 'Country',
        'boilerplate.components.Widgets.page.children.dialog.form.genre_child': 'Gender',
        'boilerplate.components.Widgets.page.children.dialog.form.address_child': 'Address',
        'boilerplate.components.Widgets.page.children.dialog.form.phone_number_child': 'Phone number',
        'boilerplate.components.Widgets.page.children.dialog.form.email_child': 'Email',
        'boilerplate.components.Widgets.page.children.dialog.form.father_child': 'Father',
        'boilerplate.components.Widgets.page.children.dialog.form.mother_child': 'Mother',
        'boilerplate.components.Widgets.page.children.dialog.form.health_detail_child': 'Child health details',
        'boilerplate.components.Widgets.page.children.dialog.form.allergies_child': 'Allergies',
        'boilerplate.components.Widgets.page.children.dialog.form.food_restriction_child': 'Food restriction',
        'boilerplate.components.Widgets.page.children.dialog.form.health_issue_child': 'Health issue',
        'boilerplate.components.Widgets.page.children.dialog.form.siblings_child': 'Siblings',
        'boilerplate.components.Widgets.page.children.dialog.form.empty_siblings': 'No siblings',
        'boilerplate.components.Widgets.page.children.dialog.form.registration_file_child': 'Registration files',
        'boilerplate.components.Widgets.page.children.dialog.form.select_file_child': 'Select file',
        'boilerplate.components.Widgets.page.children.dialog.form.image_rights_child': 'Image rights',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_contact_one_child': 'First name & last name',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_contact_one_phone_child': 'Phone number',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_contact_two_child': 'First name & last name',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_contact_two_phone_child': 'Phone number',
        'boilerplate.components.Widgets.page.children.dialog.form.authorization_person_child': 'Authorization Person',
        'boilerplate.components.Widgets.page.children.dialog.form.add_person_authorization_child': 'Add new person',
        'boilerplate.components.Widgets.page.children.dialog.form.required_field_child': 'Required field',
        'boilerplate.components.Widgets.page.children.table.header.table_h_ims_day_eat': 'How I ate ?',
        'boilerplate.components.Widgets.page.children.table.header.table_h_ims_day_naptime': 'Nap time',
        'boilerplate.components.Widgets.page.children.table.header.table_h_ims_day_date': 'Date',
        'boilerplate.components.Widgets.page.children.table.header.table_h_ims_day_obs_general': 'Observation',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_avatar': 'Avatar',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_name': 'Name',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_firstname': 'First name',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_lastname': 'Last name',
        'boilerplate.components.Widgets.page.children.table.header.table_h_fullname': 'Full name',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_birthday': 'Birth date',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_gender': 'Gender',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_classroom': 'Classroom',
        'boilerplate.components.Widgets.page.children.dialog.form.profile_child': 'Profile',
        'boilerplate.components.Widgets.page.children.form.return_children_list': 'Students list',
        'boilerplate.components.Widgets.page.children.form.main_parent': 'Main contact',
        'boilerplate.components.Widgets.page.children.form.all_mother_information': 'All Mother\'s fields are required',
        'boilerplate.components.Widgets.page.children.form.all_father_information': 'All Father\'s fields are required',
        'boilerplate.components.Widgets.page.children.form.incorrect_email': 'Incorrect email format',
        'boilerplate.components.Widgets.page.children.form.warning_child_registration': 'Avoid to add the same email several times',


        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_available': 'Available',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_nom': 'Title',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_livre': 'Book',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_emprunt': 'Loan',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_horsdelai': 'Out of date',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_emprunt_retour': 'Returned the',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_emprunt_datedemande': 'Requested since',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_emprunt_date': 'Loaned the',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_emprunt_deadline': 'Deadline',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_parent': 'Parent',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_enfant': 'Child',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_catlivre': 'Book type',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_auteur': 'Author',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_agemin': 'Age min',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_agemax': 'Age max (year)',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_langue': 'Language',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_empruntmaxjour': 'Day max loan',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_prix': 'Price (Fcfa)',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_quantite': 'Quantity',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_photo': 'Photo',


        'boilerplate.components.Widgets.page.menu_year.tab_panel.empty_tab': 'No menu defined',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.add_to_menu_year': 'Add to menu',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.add_new_menu_year': 'Add a new menu',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.edit_menu_year': 'Edit a menu',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.add_dish_year': 'Add a dish',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.pause_menu_year': 'Pause',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.play_menu_year': 'Start',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.total_menu_year': 'Total',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.starter_menu_year': 'Starter dish',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.dish_menu_year': 'Dish',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.dessert_menu_year': 'Dessert',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.generate_menu_other_week': 'Generate weeks and Menus',
        'boilerplate.components.Widgets.page.classroom.dialog.form.dialog_title_classroom': 'Add a classroom',
        'boilerplate.components.Widgets.page.classroom.dialog.form.dialog_edit_title_classroom': 'Edit a classroom',
        'boilerplate.components.Widgets.page.classroom.dialog.form.dialog_input_classroom': 'Classroom',
        'boilerplate.components.Widgets.page.classroom.dialog.form.dialog_table_header_classroom': 'Classroom',

        'boilerplate.components.Widgets.system.ui.table_h_agent_caisse': 'Cashier agent',
        'boilerplate.components.Widgets.system.ui.fees_type': 'Type of fees',
        'boilerplate.components.Widgets.system.ui.loading_list_for_notifications': 'Loading the list',
        'boilerplate.components.Widgets.system.ui.table_h_montant': 'Amount',
        'boilerplate.components.Widgets.system.ui.table_h_echeancier_date': 'Date',
        'boilerplate.components.Widgets.system.ui.table_h_echeancier_nom': 'Financial schedule',
        'boilerplate.components.Widgets.system.ui.table_h_echeancier_type': 'Financial schedule type',
        'boilerplate.components.Widgets.system.ui.table_h_profil': 'Profile',
        'boilerplate.components.Widgets.system.ui.table_h_group': 'Group',
        'boilerplate.components.Widgets.system.ui.table_h_scolarite': 'Schooling',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_illustration': 'Illustration',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_title': 'Title',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_time': 'Time',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_start_time': 'Start time',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_end_time': 'End time',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_description': 'Description',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_number': 'Registered',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_price': 'Price',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_modal_club_title': 'Add mini club',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_modal_club_limit': 'Limit',
        'boilerplate.components.Widgets.page.mini_club.form.return_mini_club_list': 'Mini club list',
        'boilerplate.components.Widgets.page.mini_club.form.save_edit_mini_club_list': 'Save',
        'boilerplate.components.Widgets.page.mini_club.form.edit_mini_club_list': 'Edit mini club',
        'boilerplate.components.Widgets.page.mini_club.form.registered_mini_club_list': 'Registered list',
        'boilerplate.components.Widgets.page.mini_club.form.empty_registered_mini_club': 'No registration available',

        'boilerplate.components.Widgets.page.marketing.table.marketing_product_photo': 'Photo',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_nom': 'Name',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_pix': 'Price',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_quantity': 'Quantity',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_delivery': 'Product delivered',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_delete': 'Delete order',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_command': 'Orders',
        'boilerplate.components.Widgets.page.marketing.form.dialog_modal_title': 'Add new product',
        'boilerplate.components.Widgets.page.marketing.form.dialog_modal_edit_title': 'Edit product',
        'boilerplate.components.Widgets.page.marketing.form.return_product_list': 'Product list',
        'boilerplate.components.Widgets.page.marketing.form.orders_product_list': 'Order list',
        'boilerplate.components.Widgets.page.marketing.form.empty_order_product': 'No orders available',
        'boilerplate.components.Widgets.page.marketing.form.order_identity_product': 'Parent of',
        'boilerplate.components.Widgets.page.marketing.form.date_order_product': 'Date',


        "boilerplate.components.Widgets.system.ui.historical_loaned": "History of books loaned",
        "boilerplate.components.Widgets.system.ui.personal_member": "Member of the team",
        "boilerplate.components.Widgets.system.ui.biblio_quantitedispo": "book(s) available",
        'boilerplate.components.Widgets.system.ui.books_loan_requested': 'Books loan requested',
        "boilerplate.components.Widgets.system.ui.bibliotheque": "Library",
        "boilerplate.components.Widgets.system.ui.en_stock": "in stock",
        "boilerplate.components.Widgets.system.ui.donateur": "Donators",
        "boilerplate.components.Widgets.system.ui.mes_livres_empruntes": "The books loaned",
        "boilerplate.components.Widgets.system.ui.disponible": "Available",
        "boilerplate.components.Widgets.system.ui.indisponible": "Unavailable",
        "boilerplate.components.Widgets.system.ui.emprunter": "Loan",
        "boilerplate.components.Widgets.system.ui.description": "Description",
        "boilerplate.components.Widgets.system.ui.biblio_edit_book": "Book edition",
        "boilerplate.components.Widgets.system.ui.biblio_catlivre": "Book type",
        "boilerplate.components.Widgets.system.ui.book_loan_valid": "Book given the",
        "boilerplate.components.Widgets.system.ui.book_loan_retour_valid": "Book returned",
        "boilerplate.components.Widgets.system.ui.doc_file": "Fichier(s)",
        "boilerplate.components.Widgets.system.ui.doc_folder_todelete": "Remove the folder",
        "boilerplate.components.Widgets.system.ui.doc_file_todelete": "Remove the file",
        "boilerplate.components.Widgets.system.ui.doc_upload_file": "Upload file",
        "boilerplate.components.Widgets.system.ui.doc_folder_delete": "Folder removal",
        "boilerplate.components.Widgets.system.ui.gestion_compte": "Account management",
        "boilerplate.components.Widgets.system.ui.doc_file_delete": "File removal",
        "boilerplate.components.Widgets.system.ui.doc_folder": "Folder",
        "boilerplate.components.Widgets.system.ui.jour": "Day",
        "boilerplate.components.Widgets.system.ui.doc_edit_folder": "Folder edition",
        "boilerplate.components.Widgets.system.ui.doc_add_folder": "Add folder",
        "boilerplate.components.Widgets.system.ui.doc_del_folder": "Delete folder",
        "boilerplate.components.Widgets.system.ui.doc_update_folder": "Update folder",
        "boilerplate.components.Widgets.system.ui.doc_del_file": "Delete file",
        "boilerplate.components.Widgets.system.ui.doc_add_file": "Add file",
        "boilerplate.components.Widgets.system.ui.ims_day_bowel": "Toilets",
        "boilerplate.components.Widgets.system.ui.ims_day_bowel_many": "How many a day ?",
        "boilerplate.components.Widgets.system.ui.ims_day_week_ims": "Canteen week",
        "boilerplate.components.Widgets.system.ui.ims_day_sieste_true": "Yes, I had a nap",
        "boilerplate.components.Widgets.system.ui.ims_day_sieste_false": "No, I didn't have a nap",

        "boilerplate.components.Widgets.system.ui.paiement_mode_numero_placeholder": "Cheque number, bank transfer...",
        "boilerplate.components.Widgets.system.ui.paiement_mode_numero": "Payment code",
        "boilerplate.components.Widgets.system.ui.paiement_mode_date": "Operation date of the payment mode",
        "boilerplate.components.Widgets.system.ui.paiement_mode": "Payment mode",
        "boilerplate.components.Widgets.system.ui.paiement_cheque": "Check",
        "boilerplate.components.Widgets.system.ui.paiement_espece": "Cash",
        "boilerplate.components.Widgets.system.ui.paiement_virement": "Bank transfer",
        "boilerplate.components.Widgets.system.ui.paiement_by": "Payed by",
        "boilerplate.components.Widgets.system.ui.paiement_date": "Payment date",
        "boilerplate.components.Widgets.system.ui.survey_list": "The surveys",
        "boilerplate.components.Widgets.system.ui.add_survey_list": "Add a survey",
        "boilerplate.components.Widgets.system.ui.open_survey_list": "Open surveys",
        "boilerplate.components.Widgets.system.ui.close_survey_list": "Close surveys",
        "boilerplate.components.Widgets.system.ui.gestion_compte_parent": "Parent account management",
        "boilerplate.components.Widgets.system.ui.gestion_compte_equipe": "Team account management",
        "boilerplate.components.Widgets.system.ui.accountetat_ok": "Active",
        "boilerplate.components.Widgets.system.ui.accountetat_locked": "Locked",
        "boilerplate.components.Widgets.system.ui.accountetat_pending_validation": "Idle",
        "boilerplate.components.Widgets.system.ui.accountetat_suspended": "Suspended",
        "boilerplate.components.Widgets.system.ui.generer_compte_question": "Would you like to generate accounts for this selection ?",
        "boilerplate.components.Widgets.system.ui.suspendre_compte_question": "Do you wish to suspend access for this selection ?",
        "boilerplate.components.Widgets.system.ui.activer_compte_question": "Would you like to activate access for this selection ?",
        "boilerplate.components.Widgets.system.ui.supprimer_compte_question": "Do you wish to pernamently delete this user ?",
        "boilerplate.components.Widgets.system.ui.generer_compte": "Generate accounts",
        "boilerplate.components.Widgets.system.ui.fermer_compte": "Suspend accounts",
        "boilerplate.components.Widgets.system.ui.activer_compte": "Reactivate accounts",
        "boilerplate.components.Widgets.system.ui.supprimer_compte": "Delete accounts",
        "boilerplate.components.Widgets.system.ui.libelle_libelle": "Label",
        "boilerplate.components.Widgets.system.ui.account_ok": "Active",
        "boilerplate.components.Widgets.system.ui.account_locked": "Locked",
        "boilerplate.components.Widgets.system.ui.account_pending_validation": "Idle",
        "boilerplate.components.Widgets.system.ui.account_suspended": "Suspended",
        "boilerplate.components.Widgets.system.ui.libelle_title": "Title",
        "boilerplate.components.Widgets.system.ui.close_this": "Close",
        "boilerplate.components.Widgets.system.ui.close_sondage": "Close the survey",
        "boilerplate.components.Widgets.system.ui.notif_create": " added ",
        "boilerplate.components.Widgets.system.ui.notif_update": " updated ",
        "boilerplate.components.Widgets.system.ui.notif_delete": " deleted ",
        "boilerplate.components.Widgets.system.ui.notif_update_action": "Updated",
        "boilerplate.components.Widgets.system.ui.notif_read": " read ",
        "boilerplate.components.Widgets.system.ui.header_nom": "Name",
        "boilerplate.components.Widgets.system.ui.header_code": "Code",
        "boilerplate.components.Widgets.system.ui.header_quantite": "Quantite",
        "boilerplate.components.Widgets.system.ui.send_to": "Send to",
        "boilerplate.components.Widgets.system.ui.ims_nap_time": "Nap time",
        "boilerplate.components.Widgets.system.ui.sieste": "Nap",
        "boilerplate.components.Widgets.system.ui.ims_menu_cantine_obs": "Lunch observation",
        "boilerplate.components.Widgets.system.ui.ims_menu_obs_very_well": "Very well",
        "boilerplate.components.Widgets.system.ui.ims_menu_obs_easily": "Easily",
        "boilerplate.components.Widgets.system.ui.ims_menu_obs_good": "GOOD",
        "boilerplate.components.Widgets.system.ui.ims_menu_obs_not_good": "Not good",
        "boilerplate.components.Widgets.system.ui.ims_menu_obs_not_at_all": "Not at all",
        "boilerplate.components.Widgets.system.ui.rdv_read_void": " ",
        "boilerplate.components.Widgets.system.ui.child": "Child",
        "boilerplate.components.Widgets.system.ui.times_number": "Times",
        "boilerplate.components.Widgets.system.ui.obs_gen": "General observation",
        "boilerplate.components.Widgets.system.ui.obs_parent": "Parent comment",
        "boilerplate.components.Widgets.system.ui.child_stuff": "What I need for tomorrow",
        "boilerplate.components.Widgets.system.ui.day": "Day",
        "boilerplate.components.Widgets.system.ui.ims_day_title": "My day at IMS of",
        "boilerplate.components.Widgets.system.ui.ims_plat_entree": "Appetizer",
        "boilerplate.components.Widgets.system.ui.ims_plat_plat": "Dish",
        "boilerplate.components.Widgets.system.ui.ims_plat_dessert": "Dessert",
        "boilerplate.components.Widgets.system.ui.ims_day_how_ate": "How I ate ?",
        "boilerplate.components.Widgets.system.ui.ims_menu_day": "Menu of Day",
        "boilerplate.components.Widgets.system.ui.note": "Note",

        "boilerplate.components.Widgets.system.ui.tooltip_generate_account": "Accounts will be active. New user names will be generated. New passwords will be generated. A message containing new accesses will be sent by e-mail.",
        "boilerplate.components.Widgets.system.ui.tooltip_activate_account": "Accounts will be active. New passwords will be generated. A message containing access details will be sent by e-mail. Only suspended account could be activated.",
        "boilerplate.components.Widgets.system.ui.tooltip_suspended_account": "Accounts will be suspended. Users will not be able to log in. New information related to suspended users will be saved and visible once accounts are reactivated.",
        "boilerplate.components.Widgets.system.ui.tooltip_deleted_account": "Accounts will be deleted. Users will not be able to log in.",
        "boilerplate.components.Widgets.system.ui.all_label": "All",
        "boilerplate.components.Widgets.system.ui.all_label_rdv": "All appointments",
        "boilerplate.components.Widgets.system.ui.mes_notifications": "All my notifications",
        "boilerplate.components.Widgets.system.ui.selection_edition": "Edition of the selection",
        "boilerplate.components.Widgets.system.ui.add_item": "Add new item",
        "boilerplate.components.Widgets.system.ui.add_children": "Add children",
        "boilerplate.components.Widgets.system.ui.generate_financial_schedule": "Generate financial schedule",
        "boilerplate.components.Widgets.system.ui.selection_to_delete": "Do you want to delete your selection ?",
        "boilerplate.components.Widgets.system.ui.selection_delete": "Removal",
        "boilerplate.components.Widgets.system.ui.emprunter_book": "Loan of book",
        "boilerplate.components.Widgets.system.ui.emprunt_sentence_sujet": "Number of books I would like to loan",
        "boilerplate.components.Widgets.system.ui.scolarite_typepaiement": "The fees",
        "boilerplate.components.Widgets.system.ui.scolarite_echeancier": "Financial schedules",
        "boilerplate.components.Widgets.system.ui.scolarite_echeancier_enfant": "Financial schedule per child",
        "boilerplate.components.Widgets.system.ui.groups": "The groups",
        "boilerplate.components.Widgets.system.ui.the_group": "The group",
        "boilerplate.components.Widgets.system.ui.load_children": "Loading Children",
        "boilerplate.components.Widgets.system.ui.groupsenfants": "Children per groups",
        "boilerplate.components.Widgets.system.ui.fraisoneshot_enfant": "One Time Fee",
        "boilerplate.components.Widgets.system.ui.emprunt_delete_title": "Suppresion d'un emprunt",
        "boilerplate.components.Widgets.system.ui.emprunteur": "Requestor",
        "boilerplate.components.Widgets.system.ui.biblio_livre": "Book",
        "boilerplate.components.Widgets.system.ui.biblio_livre_delete": "Book removal",
        "boilerplate.components.Widgets.system.ui.biblio_livre_todelete": "Book(s) to delete",
        "boilerplate.components.Widgets.system.ui.biblio_edit_folder": "Book edition",
        "boilerplate.components.Widgets.system.ui.start_day": "Start day",
        "boilerplate.components.Widgets.system.ui.end_day": "End day",
        "boilerplate.components.Widgets.system.ui.hour": "Hour",
        "boilerplate.components.Widgets.system.ui.none": "Aucun",
        "boilerplate.components.Widgets.system.ui.start_hour": "Start hour",
        "boilerplate.components.Widgets.system.ui.end_hour": "End hour",
        "boilerplate.components.Widgets.system.ui.save_form_crud_btn": "Save",
        "boilerplate.components.Widgets.system.ui.edit": "Edit",
        "boilerplate.components.Widgets.system.ui.delete_in_table": "Delete",
        "boilerplate.components.Widgets.system.ui.back_action_stepper": "Back",
        "boilerplate.components.Widgets.system.ui.next_action_stepper": "Next",
        'boilerplate.components.Widgets.system.ui.import_action': 'Import',
        'boilerplate.components.Widgets.system.ui.la_valeur': 'The value',
        'boilerplate.components.Widgets.system.ui.must_be_uniq': 'should be unique',
        'boilerplate.components.Widgets.system.ui.return_to_list': 'Show the list',
        'boilerplate.components.Widgets.system.ui.period': 'Period',
        'boilerplate.components.Widgets.system.ui.rdv_list': 'Appointment list',
        'boilerplate.components.Widgets.system.ui.oui': 'Yes',
        'boilerplate.components.Widgets.system.ui.non': 'No',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_add_success': 'Successful registration',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_edit_success': 'Successful modification',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_delete_success': 'Successful delete',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_success': 'Saved successful',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_error': 'Connection Problem with database',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_action_error': 'Registration error',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_selection_empty': 'Please make a selection',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_selection_only_suspended_account': 'Only suspended accounts could be activated. Please make sure to select only suspended accounts',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_employee_exist': 'A team member with the same email was registered in application',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_only_suspended_to_active': 'Only suspended account could be activated',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_filesize_limit': 'File size too large',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_not_enough_book_in_store': 'There is not enough book in store',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_parent_already_exist': 'The parent is already registered in the application',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_error_400': 'Request failed with status 400',
        'boilerplate.components.Widgets.system.ui.enum.role.enum_admin': 'Administration',
        'boilerplate.components.Widgets.system.ui.enum.role.enum_teacher': 'Educational team',
        'boilerplate.components.Widgets.system.ui.enum.role.enum_support': 'Support team',

        "boilerplate.components.Widgets.page.user.login.form.title_form_login": "Log in",
        "boilerplate.components.Widgets.page.user.login.form.input.mail_number_form_login": "Username",
        "boilerplate.components.Widgets.page.user.login.form.input.password_form_login": "Password",
        "boilerplate.components.Widgets.page.user.login.form.input.remember_form_login": "Remember me",
        "boilerplate.components.Widgets.page.user.login.form.input.forgot_password_form_login": "Forgot password ?",
        "boilerplate.components.Widgets.page.user.login.form.button.connection_form_login": "Log in",
        "boilerplate.components.Widgets.page.user.login.form.button.logout_form_app": "Log out",
        "boilerplate.components.Widgets.page.user.forgot_password.form.title _form_reset": "Forgot password",
        "boilerplate.components.Widgets.page.user.forgot_password.form.input.email_form_reset": "Email",
        "boilerplate.components.Widgets.page.user.forgot_password.form.button.reset_password_form_reset": "Reset request",



        'boilerplate.components.Errors.BAD_REQUEST': 'Your request cannot be processed by the server.',
        'boilerplate.components.Errors.CONSTRAINT_VIOLATION': "The new data does not comply with the correct operation of the Database.",
        'boilerplate.components.Errors.DATA_EXIST': 'This data already exists.',
        'boilerplate.components.Errors.DTO_SENT_FAILED_VALIDATION': 'Please be sure to fill in all the mandatory informations before any request.',
        'boilerplate.components.Errors.DEFAULT_ERROR': 'Your inquiry cannot be processed.',
        'boilerplate.components.Errors.ERR_CONFLICT': 'Your query conflicts with data that already exists.',
        "boilerplate.components.Errors.NOT_FOUND": "No matches were found.",
        "boilerplate.components.Errors.PERSON_SAME_EMAIL_ALREADY_EXISTED": "A person with an identical e-mail address is already registered in the database.",
        "boilerplate.components.Errors.RDV_NOT_FOUND": "The appointment is no longer available.",
        "boilerplate.components.Errors.SERVICE_AVAILABLE_ONLY_FOR_PRESET_APPOINTMENT": "This feature is only available for preset appointments.",
        "boilerplate.components.Errors.UNIQUE_CONSTRAINT": "One or more data already exist in the application. You need to modify them to make them unique.",

    }
}
