import {useMemo} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {DataTableState, dataTableStateImpl, setDataTableState} from "../../../features/datatable/dataTableSlice";
import type {MRT_Localization} from "material-react-table";
import {isNotBlank, isPositiveValue} from "../../../helpers/helpers";

const initState = ( {stateStore={}, tableId='', customOptions= undefined, updateId} : {stateStore:any; tableId:string; customOptions ?: any; updateId ?: number | undefined;}) => {
    let dataTableState:any = _.cloneDeep(_.find(stateStore, {"dataTableId":tableId}) ??  {}) ;

    if(dataTableState?.updateId === updateId){
        return dataTableState;
    }

    let etat = {};
    const newDataTableState = _.cloneDeep(dataTableStateImpl);
    if(_.isObject(customOptions) && isNotBlank(customOptions)){
        etat = {...customOptions}
        if(_.isObject(dataTableState.etat)){
            etat = {
                ...dataTableState.etat,
                ...customOptions,
            } ;
        }

    }
    newDataTableState.dataTableId   = tableId ;
    newDataTableState.etat          = {...etat} ;
    newDataTableState.updateId      = updateId ;
    return newDataTableState ;
};


export const useMyMaterialReactTable = (
    {
        tableId = "",
        customOptions= undefined,
        updateId = 0,
    } : {
        tableId : string;
        customOptions ?: any;
        updateId ?: number | undefined;
    } = {tableId: ""}
) => {
    const dispatch = useDispatch();
    const stateStore = useSelector((state: any) => state.dataTable.tableStates);
    const dataTableInitialState = useMemo<MRT_Localization>(() => initState({stateStore, tableId, customOptions, updateId}), []) ;
   // const dataTableInitialStateRef = useRef<any>(initState({stateStore, tableId}));

    const storeState = async (tableState:any) => {
        const data : DataTableState = {
            dataTableId: tableId,
            etat: tableState,
            updateId: updateId,
        }
        dispatch(setDataTableState(data));
    };
    return {
        storeState,
        ...dataTableInitialState,
    };
}