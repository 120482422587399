export const scope = 'boilerplate.components.Widgets';

const messages:any = {
    schooling_dashbord: `${scope}.KpIndicatorWidget.dashboard.schooling_dashboard`,
    until_today: `${scope}.KpIndicatorWidget.dashboard.until_today`,
    incident: `${scope}.KpIndicatorWidget.dashboard.incident`,
    this_week_dashboard: `${scope}.KpIndicatorWidget.dashboard.this_week_dashboard`,
    allergies: `${scope}.KpIndicatorWidget.dashboard.allergies`,
    children_dashboard: `${scope}.KpIndicatorWidget.dashboard.children_dashboard`,
    this_year_dashboard: `${scope}.KpIndicatorWidget.dashboard.this_year_dashboard`,
    turnover_schooling_compare: `${scope}.SchoolingIndicator.dashboard.turnover_schooling_compare`,
    turnover_schooling_forecast: `${scope}.SchoolingIndicator.dashboard.turnover_schooling_forecast`,
    turnover_schooling_actual: `${scope}.SchoolingIndicator.dashboard.turnover_schooling_actual`,
    exchange_book_list: `${scope}.KpIndicatorWidget.dashboard.exchange_book_list`,

    title_amount_box: `${scope}.SchoolingIndicator.dashboard.title_amount_box`,
    total_amount_schooling_and_fees: `${scope}.SchoolingIndicator.dashboard.total_amount_schooling_and_fees`,
    total_amount_schooling: `${scope}.SchoolingIndicator.dashboard.total_amount_schooling`,
    total_amount_additionalfees: `${scope}.SchoolingIndicator.dashboard.total_amount_additionalfees`,
    student_number_box: `${scope}.SchoolingIndicator.dashboard.student_number_box`,
    payment_tuition_type: `${scope}.SchoolingIndicator.dashboard.payment_tuition_type`,
    note_average_box: `${scope}.SchoolingIndicator.dashboard.note_average_box`,
    appointment_dashboard_box: `${scope}.SchoolingIndicator.dashboard.appointment_dashboard_box`,

    rdv_type: `${scope}.page.appointment_calendar.rdv_type`,
    rdv_read_employee_feedback: `${scope}.page.appointment_calendar.rdv_read_employee_feedback`,
    rdv_read_report_feedback: `${scope}.page.appointment_calendar.rdv_read_report_feedback`,
    rdv_read_cancel_feedback: `${scope}.page.appointment_calendar.rdv_read_cancel_feedback`,
    rdv_read_parent_feedback: `${scope}.page.appointment_calendar.rdv_read_parent_feedback`,
    rdv_read_parent_noresponse_yet: `${scope}.page.appointment_calendar.rdv_read_parent_noresponse_yet`,
    rdv_read_parent_wait_response: `${scope}.page.appointment_calendar.rdv_read_parent_wait_response`,
    rdv_read_employee_which_outcome: `${scope}.page.appointment_calendar.rdv_read_employee_which_outcome`,
    rdv_read_title_employee_report: `${scope}.page.appointment_calendar.rdv_read_title_employee_report`,
    rdv_read_title_parent_report: `${scope}.page.appointment_calendar.rdv_read_title_parent_report`,
    rdv_read_title_someone_report: `${scope}.page.appointment_calendar.rdv_read_title_someone_report`,
    rdv_read_title_confirm: `${scope}.page.appointment_calendar.rdv_read_title_confirm`,
    rdv_read_title_employee_cancel: `${scope}.page.appointment_calendar.rdv_read_title_employee_cancel`,
    rdv_read_title_someone_cancel: `${scope}.page.appointment_calendar.rdv_read_title_someone_cancel`,
    rdv_read_title_parent_cancel: `${scope}.page.appointment_calendar.rdv_read_title_parent_cancel`,
    rdv_read_title_confirm_rapport: `${scope}.page.appointment_calendar.rdv_read_title_confirm_rapport`,
    rdv_read_title_notheld_rapport: `${scope}.page.appointment_calendar.rdv_read_title_notheld_rapport`,
    class_effectif: `${scope}.page.appointment_calendar.class_effectif`,
    add_normal: `${scope}.page.appointment_calendar.add_normal`,
    comment: `${scope}.page.appointment_calendar.comment`,
    add_preset: `${scope}.page.appointment_calendar.add_preset`,
    type_normal: `${scope}.page.appointment_calendar.type_normal`,
    type_preset: `${scope}.page.appointment_calendar.type_preset`,
    all_profiles: `${scope}.page.appointment_calendar.all_profiles`,
    all_classes: `${scope}.page.appointment_calendar.all_classes`,
    user_appointment_list: `${scope}.page.appointment_calendar.user_appointment_list`,
    normal_title: `${scope}.page.appointment_calendar.dialog.form.normal_title`,
    choose_interlocutor: `${scope}.page.appointment_calendar.dialog.form.choose_interlocutor`,
    choose_child: `${scope}.page.appointment_calendar.dialog.form.choose_child`,
    preset_title: `${scope}.page.appointment_calendar.dialog.form.preset_title`,
    rdv_filter_title: `${scope}.page.appointment_calendar.dialog.form.rdv_filter_title`,
    dialog_cancel: `${scope}.page.appointment_calendar.dialog.form.actions.dialog_cancel`,
    dialog_close: `${scope}.page.appointment_calendar.dialog.form.actions.dialog_close`,
    dialog_post: `${scope}.page.appointment_calendar.dialog.form.actions.dialog_post`,
    appointment_radio: `${scope}.page.appointment_calendar.dialog.form.radio.appointment_radio`,
    preset_appointment: `${scope}.page.appointment_calendar.dialog.form.radio.preset_appointment`,
    rdv_status_libelle: `${scope}.page.appointment_calendar.rdv_status_libelle`,
    rdv_requested_libelle: `${scope}.page.appointment_calendar.rdv_requested_libelle`,
    title_appointment: `${scope}.page.appointment_calendar.dialog.form.input.title_appointment`,
    title_document_optional: `${scope}.page.appointment_calendar.dialog.form.input.title_document_optional`,
    appointment_status: `${scope}.page.appointment_calendar.dialog.form.input.appointment_status`,
    appointment_deleted: `${scope}.page.appointment_calendar.dialog.form.input.appointment_deleted`,
    appointment_not_held: `${scope}.page.appointment_calendar.dialog.form.input.appointment_not_held`,
    appointment_cancel: `${scope}.page.appointment_calendar.dialog.form.input.appointment_cancel`,
    appointment_confirm: `${scope}.page.appointment_calendar.dialog.form.input.appointment_confirm`,
    appointment_past: `${scope}.page.appointment_calendar.dialog.form.input.appointment_past`,
    appointment_current: `${scope}.page.appointment_calendar.dialog.form.input.appointment_current`,
    appointment_upcoming: `${scope}.page.appointment_calendar.dialog.form.input.appointment_upcoming`,
    appointment_creno: `${scope}.page.appointment_calendar.dialog.form.input.appointment_creno`,
    appointment_done: `${scope}.page.appointment_calendar.dialog.form.input.appointment_done`,
    appointment_not_respected: `${scope}.page.appointment_calendar.dialog.form.input.appointment_not_respected`,
    appointment_report: `${scope}.page.appointment_calendar.dialog.form.input.appointment_report`,
    appointment_reopen: `${scope}.page.appointment_calendar.dialog.form.input.appointment_reopen`,
    appointment_report_confirm: `${scope}.page.appointment_calendar.dialog.form.input.appointment_report_confirm`,
    appointment_partial_confirm: `${scope}.page.appointment_calendar.dialog.form.input.appointment_partial_confirm`,
    appointment_wait: `${scope}.page.appointment_calendar.dialog.form.input.appointment_wait`,
    description_appointment: `${scope}.page.appointment_calendar.dialog.form.input.description_appointment`,
    class_preset: `${scope}.page.appointment_calendar.dialog.form.input.class_preset`,
    duration_preset: `${scope}.page.appointment_calendar.dialog.form.input.duration_preset`,
    deadline_rdv: `${scope}.page.appointment_calendar.dialog.form.input.deadline_rdv`,
    deadline_rdv_date: `${scope}.page.appointment_calendar.dialog.form.input.deadline_rdv_date`,
    //export_xls: `${scope}.page.appointment_list.datagrid.export_xls`,
    compose_message: `${scope}.page.message_center.compose_message`,
    compose_ticket: `${scope}.page.message_center.compose_ticket`,
    conversation_list: `${scope}.page.message_center.conversation_list`,
    type_message: `${scope}.page.message_center.type_message`,
    chat_list_empty_message: `${scope}.page.message_center.chat_list_empty_message`,
    please_select_parent: `${scope}.page.message_center.please_select_parent`,
    please_write_message: `${scope}.page.message_center.please_write_message`,
    opened_space: `${scope}.page.message_center.opened_space`,
    closed_space: `${scope}.page.message_center.closed_space`,
    online_space: `${scope}.page.message_center.online_space`,
    offline_space: `${scope}.page.message_center.offline_space`,
    object_message: `${scope}.page.message_center.object_message`,
    close_space: `${scope}.page.message_center.close_space`,
    delete_space: `${scope}.page.message_center.delete_space`,
    please_write_subject_message: `${scope}.page.message_center.please_write_subject_message`,
    add_action: `${scope}.page.config.school_period.toolbar_top.add_action`,
    generer_action: `${scope}.page.config.school_period.toolbar_top.generer_action`,
    delete_action: `${scope}.page.config.school_period.toolbar_top.delete_action`,
    printer_action: `${scope}.page.config.school_period.toolbar_top.printer_action`,

    school_period_year: `${scope}.page.config.school_period.table.columns.school_period_year`,
    school_period: `${scope}.page.config.school_period.table.columns.school_period`,
    start_period: `${scope}.page.config.school_period.table.columns.start_period`,
    end_period: `${scope}.page.config.school_period.table.columns.end_period`,

    dialog_title_period: `${scope}.page.config.school_period.dialog.form.dialog_title_period`,
    dialog_edit_title_period: `${scope}.page.config.school_period.dialog.form.dialog_edit_title_period`,
    year_period: `${scope}.page.config.school_period.dialog.form.year_period`,
    title_school_period: `${scope}.page.config.school_period.dialog.form.title_school_period`,
    school_day_title: `${scope}.page.config.period.school_day_title`,
    day_off: `${scope}.page.config.period.day_off`,
    day_open: `${scope}.page.config.period.day_open`,
    dialog_title_canteen_week: `${scope}.page.config.canteen_week.dialog.form.dialog_title_canteen_week`,
    dialog_edit_title_canteen_week: `${scope}.page.config.canteen_week.dialog.form.dialog_edit_title_canteen_week`,
    title_canteen_week: `${scope}.page.config.canteen_week.dialog.form.title_canteen_week`,
    dialog_title_personal_type: `${scope}.page.config.school_period.dialog.form.dialog_title_personal_type`,
    dialog_edit_title_job: `${scope}.page.config.school_period.dialog.form.dialog_edit_title_job`,
    title_personal_type: `${scope}.page.config.school_period.dialog.form.title_personal_type`,
    dialog_job_role: `${scope}.page.config.school_period.dialog.form.dialog_job_role`,

    dialog_title_school_document_type: `${scope}.page.config.school_document.dialog.form.dialog_title_school_document_type`,
    dialog_edit_title_school_document_type: `${scope}.page.config.school_document.dialog.form.dialog_edit_title_school_document_type`,
    title_school_document_type: `${scope}.page.config.school_document.dialog.form.title_school_document_type`,
    title_document_type: `${scope}.page.config.school_document.dialog.form.title_document_type`,
    dialog_title_inscription_file_type: `${scope}.page.config.inscription_file.dialog.form.dialog_title_inscription_file_type`,
    dialog_edit_title_inscription_file_type: `${scope}.page.config.inscription_file.dialog.form.dialog_edit_title_inscription_file_type`,
    title_inscription_file_type: `${scope}.page.config.inscription_file.dialog.form.title_inscription_file_type`,
    inscription_file_type: `${scope}.page.config.inscription_file.dialog.form.inscription_file_type`,
    sub_folder_type: `${scope}.page.config.folder.sub_folder_type`,
    dialog_title_sub_folder_type: `${scope}.page.config.folder.dialog.form.dialog_title_sub_folder_type`,
    dialog_edit_title_sub_folder_type: `${scope}.page.config.folder.dialog.form.dialog_edit_title_sub_folder_type`,
    folder_type: `${scope}.page.config.folder.folder_type`,
    dialog_title_folder_type: `${scope}.page.config.folder.dialog.form.dialog_title_folder_type`,
    dialog_edit_title_folder_type: `${scope}.page.config.folder.dialog.form.dialog_edit_title_folder_type`,
    dialog_folder_type_file: `${scope}.page.config.folder.dialog.form.dialog_folder_type_file`,
    image_rights: `${scope}.page.config.image_rights.table_columns.image_rights`,
    dialog_title_image_right: `${scope}.page.config.image_rights.dialog.form.dialog_title_image_right`,
    dialog_edit_title_image_right: `${scope}.page.config.image_rights.dialog.form.dialog_edit_title_image_right`,

    dialog_title_dish_config: `${scope}.page.config.dish.dialog.form.dialog_title_dish_config`,
    dialog_title_edit_dish: `${scope}.page.config.dish.dialog.form.dialog_title_edit_dish`,
    dish_type_config: `${scope}.page.config.dish.dialog.form.dish_type_config`,
    title_dish_config: `${scope}.page.config.dish.dialog.form.title_dish_config`,
    appointment_type: `${scope}.page.config.appointment_type.table_columns.appointment_type`,
    title_appoint_type_config: `${scope}.page.config.appointment_type.dialog.form.title_appoint_type_config`,
    edit_title_appoint_type_config: `${scope}.page.config.appointment_type.dialog.form.edit_title_appoint_type_config`,
    input_appoint_type: `${scope}.page.config.appointment_type.dialog.form.input_appoint_type`,

    proforma_du: `${scope}.page.config.schooling.proforma_du`,
    generer_facture: `${scope}.page.config.schooling.generer_facture`,
    notifier_parent: `${scope}.page.config.schooling.notifier_parent`,
    schooling_echeance_label: `${scope}.page.config.schooling.schooling_echeance_label`,
    schooling_add_payment: `${scope}.page.config.schooling.schooling_add_payment`,
    schooling_payment_list: `${scope}.page.config.schooling.schooling_payment_list`,
    schooling_proforma_invoice: `${scope}.page.config.schooling.schooling_proforma_invoice`,
    schooling_fees_label: `${scope}.page.config.schooling.schooling_fees_label`,
    schooling_payment_period: `${scope}.page.config.schooling.schooling_payment_period`,
    schooling_fees: `${scope}.page.config.schooling.schooling_fees`,
    class_fees: `${scope}.page.config.schooling.class_fees`,
    fees: `${scope}.page.config.schooling.fees`,
    schooling_fees_title: `${scope}.page.config.schooling.schooling_fees_title`,
    schooling_fees_type: `${scope}.page.config.schooling.schooling_fees_type`,
    schooling_versement_etat: `${scope}.page.config.schooling.schooling_versement_etat`,
    schooling_save_payment: `${scope}.page.config.schooling.schooling_save_payment`,
    schooling_total_payment: `${scope}.page.config.schooling.schooling_total_payment`,
    schooling_total_restant: `${scope}.page.config.schooling.schooling_total_restant`,
    schooling_total_montant: `${scope}.page.config.schooling.schooling_total_montant`,
    schooling_restant_apayer: `${scope}.page.config.schooling.schooling_restant_apayer`,
    schooling_total_payment_done: `${scope}.page.config.schooling.schooling_total_payment_done`,
    schooling_total_new_payment: `${scope}.page.config.schooling.schooling_total_new_payment`,
    schooling_fees_title_custom: `${scope}.page.config.schooling.schooling_fees_title_custom`,
    schooling_other_fees_list: `${scope}.page.config.schooling.schooling_other_fees_list`,
    schooling: `${scope}.page.config.schooling.schooling`,
    dialog_title_schooling: `${scope}.page.config.schooling.dialog.form.dialog_title_schooling`,
    dialog_edit_title_schooling: `${scope}.page.config.schooling.dialog.form.dialog_edit_title_schooling`,
    schooling_profile: `${scope}.page.config.schooling.dialog.form.input.schooling_profile`,
    schooling_amount: `${scope}.page.config.schooling.dialog.form.input.schooling_amount`,
    schooling_period_payment: `${scope}.page.config.schooling.table_grid.schooling_period_payment`,
    payment_date_limite: `${scope}.page.config.schooling.table_grid.payment_date_limite`,
    date_limite: `${scope}.page.config.schooling.table_grid.date_limite`,
    schooling_rappel_payment: `${scope}.page.config.schooling.table_grid.schooling_rappel_payment`,
    dialog_title_schooling_payment_period: `${scope}.page.config.schooling.dialog.form.dialog_title_schooling_payment_period`,
    dialog_edit_title_schooling_payment_period: `${scope}.page.config.schooling.dialog.form.dialog_edit_title_schooling_payment_period`,
    schooling_fees_obligatory: `${scope}.page.config.schooling.schooling_fees.table_grid.schooling_fees_obligatory`,
    schooling_fees_echeance: `${scope}.page.config.schooling.schooling_fees.table_grid.schooling_fees_echeance`,
    dialog_title_additional_fees: `${scope}.page.config.schooling.schooling_fees.dialog.form.dialog_title_additional_fees`,
    dialog_edit_title_additional_fees: `${scope}.page.config.schooling.schooling_fees.dialog.form.dialog_edit_title_additional_fees`,
    additional_fees_title: `${scope}.page.config.schooling.schooling_fees.dialog.form.input.additional_fees_title`,

    title_password_profile: `${scope}.page.profile.title_password_profile`,
    username_profile: `${scope}.page.profile.username_profile`,
    old_password_profile: `${scope}.page.profile.old_password_profile`,
    new_password_profile: `${scope}.page.profile.new_password_profile`,
    confirm_new_password_profile: `${scope}.page.profile.confirm_new_password_profile`,
    password_not_match_profile: `${scope}.page.profile.password_not_match_profile`,
    title_profile: `${scope}.page.profile.title_profile`,

    dialog_title_personal: `${scope}.page.personal.dialog.form.dialog_title_personal`,
    dialog_title_edit_personal: `${scope}.page.personal.dialog.form.dialog_title_edit_personal`,
    firstname_personal: `${scope}.page.personal.dialog.form.firstname_personal`,
    lastname_personal: `${scope}.page.personal.dialog.form.lastname_personal`,
    birth_date_personal: `${scope}.page.personal.dialog.form.birth_date_personal`,
    hire_date_personal: `${scope}.page.personal.dialog.form.hire_date_personal`,
    email_personal: `${scope}.page.personal.dialog.form.email_personal`,
    phone_number_personal: `${scope}.page.personal.dialog.form.phone_number_personal`,
    type_personal: `${scope}.page.personal.dialog.form.type_personal`,
    genre_personal: `${scope}.page.personal.dialog.form.genre_personal`,
    more_info_personal: `${scope}.page.personal.dialog.form.more_info_personal`,
    job_name_personal: `${scope}.page.personal.dialog.form.job_name_personal`,
    post_personal: `${scope}.page.personal.dialog.form.post_personal`,
    table_h_person_avatar: `${scope}.page.personal.table.header.table_h_person_avatar`,
    table_h_person_email: `${scope}.page.personal.table.header.table_h_person_email`,
    table_h_person_phone: `${scope}.page.personal.table.header.table_h_person_phone`,
    table_h_account_status: `${scope}.page.personal.table.header.table_h_account_status`,
    table_h_person_job: `${scope}.page.personal.table.header.table_h_person_job`,

    dialog_title_child: `${scope}.page.children.dialog.form.dialog_title_child`,
    dialog_title_edit_child: `${scope}.page.children.dialog.form.dialog_title_edit_child`,
    identity_child: `${scope}.page.children.dialog.form.identity_child`,
    parent_child: `${scope}.page.children.dialog.form.parent_child`,
    other_details_child: `${scope}.page.children.dialog.form.other_details_child`,
    emergency_child: `${scope}.page.children.dialog.form.emergency_child`,
    emergency_contact_child: `${scope}.page.children.dialog.form.emergency_contact_child`,
    firstname_child: `${scope}.page.children.dialog.form.firstname_child`,
    lastname_child: `${scope}.page.children.dialog.form.lastname_child`,
    birth_date_child: `${scope}.page.children.dialog.form.birth_date_child`,
    classroom_child: `${scope}.page.children.dialog.form.classroom_child`,
    scolaritee_annee: `${scope}.page.children.dialog.form.scolaritee_annee`,
    nationality_child: `${scope}.page.children.dialog.form.nationality_child`,
    country: `${scope}.page.children.dialog.form.country`,
    genre_child: `${scope}.page.children.dialog.form.genre_child`,
    address_child: `${scope}.page.children.dialog.form.address_child`,
    phone_number_child: `${scope}.page.children.dialog.form.phone_number_child`,
    email_child: `${scope}.page.children.dialog.form.email_child`,
    father_child: `${scope}.page.children.dialog.form.father_child`,
    mother_child: `${scope}.page.children.dialog.form.mother_child`,
    health_detail_child: `${scope}.page.children.dialog.form.health_detail_child`,
    allergies_child: `${scope}.page.children.dialog.form.allergies_child`,
    food_restriction_child: `${scope}.page.children.dialog.form.food_restriction_child`,
    health_issue_child: `${scope}.page.children.dialog.form.health_issue_child`,
    siblings_child: `${scope}.page.children.dialog.form.siblings_child`,
    empty_siblings: `${scope}.page.children.dialog.form.empty_siblings`,
    registration_file_child: `${scope}.page.children.dialog.form.registration_file_child`,
    select_file_child: `${scope}.page.children.dialog.form.select_file_child`,
    image_rights_child: `${scope}.page.children.dialog.form.image_rights_child`,
    emergency_contact_one_child: `${scope}.page.children.dialog.form.emergency_contact_one_child`,
    emergency_contact_one_phone_child: `${scope}.page.children.dialog.form.emergency_contact_one_phone_child`,
    authorization_person_child: `${scope}.page.children.dialog.form.authorization_person_child`,
    required_field_child: `${scope}.page.children.dialog.form.required_field_child`,
    add_person_authorization_child: `${scope}.page.children.dialog.form.add_person_authorization_child`,
    table_h_ims_day_date: `${scope}.page.children.table.header.table_h_ims_day_date`,
    table_h_ims_day_naptime: `${scope}.page.children.table.header.table_h_ims_day_naptime`,
    table_h_ims_day_eat: `${scope}.page.children.table.header.table_h_ims_day_eat`,
    table_h_ims_day_obs_general: `${scope}.page.children.table.header.table_h_ims_day_obs_general`,
    table_h_child_avatar: `${scope}.page.children.table.header.table_h_child_avatar`,
    table_h_child_name: `${scope}.page.children.table.header.table_h_child_name`,
    table_h_child_firstname: `${scope}.page.children.table.header.table_h_child_firstname`,
    table_h_child_lastname: `${scope}.page.children.table.header.table_h_child_lastname`,
    table_h_fullname: `${scope}.page.children.table.header.table_h_fullname`,
    table_h_child_birthday: `${scope}.page.children.table.header.table_h_child_birthday`,
    table_h_child_gender: `${scope}.page.children.table.header.table_h_child_gender`,
    table_h_child_classroom: `${scope}.page.children.table.header.table_h_child_classroom`,
    profile_child: `${scope}.page.children.dialog.form.profile_child`,
    return_children_list: `${scope}.page.children.form.return_children_list`,
    main_parent: `${scope}.page.children.form.main_parent`,
    all_mother_information: `${scope}.page.children.form.all_mother_information`,
    all_father_information: `${scope}.page.children.form.all_father_information`,
    incorrect_email: `${scope}.page.children.form.incorrect_email`,
    warning_child_registration: `${scope}.page.children.form.warning_child_registration`,

    table_h_available: `${scope}.page.bibliotheque.table.header.table_h_available`,
    table_h_nom: `${scope}.page.bibliotheque.table.header.table_h_nom`,
    table_h_livre: `${scope}.page.bibliotheque.table.header.table_h_livre`,
    table_h_emprunt: `${scope}.page.bibliotheque.table.header.table_h_emprunt`,
    table_h_horsdelai: `${scope}.page.bibliotheque.table.header.table_h_horsdelai`,
    table_h_emprunt_date: `${scope}.page.bibliotheque.table.header.table_h_emprunt_date`,
    table_h_emprunt_retour: `${scope}.page.bibliotheque.table.header.table_h_emprunt_retour`,
    table_h_emprunt_datedemande: `${scope}.page.bibliotheque.table.header.table_h_emprunt_datedemande`,
    table_h_emprunt_deadline: `${scope}.page.bibliotheque.table.header.table_h_emprunt_deadline`,
    table_h_parent: `${scope}.page.bibliotheque.table.header.table_h_parent`,
    table_h_enfant: `${scope}.page.bibliotheque.table.header.table_h_enfant`,
    table_h_catlivre: `${scope}.page.bibliotheque.table.header.table_h_catlivre`,
    table_h_auteur: `${scope}.page.bibliotheque.table.header.table_h_auteur`,
    table_h_agemin: `${scope}.page.bibliotheque.table.header.table_h_agemin`,
    table_h_agemax: `${scope}.page.bibliotheque.table.header.table_h_agemax`,
    table_h_langue: `${scope}.page.bibliotheque.table.header.table_h_langue`,
    table_h_empruntmaxjour: `${scope}.page.bibliotheque.table.header.table_h_empruntmaxjour`,
    table_h_prix: `${scope}.page.bibliotheque.table.header.table_h_prix`,
    table_h_quantite: `${scope}.page.bibliotheque.table.header.table_h_quantite`,
    table_h_photo: `${scope}.page.bibliotheque.table.header.table_h_photo`,

    emergency_contact_two_child: `${scope}.page.children.dialog.form.emergency_contact_two_child`,
    emergency_contact_two_phone_child: `${scope}.page.children.dialog.form.emergency_contact_two_phone_child`,
    add_to_menu_year: `${scope}.page.menu_year.dialog.form.add_to_menu_year`,
    empty_tab: `${scope}.page.menu_year.tab_panel.empty_tab`,
    starter_menu_year: `${scope}.page.menu_year.dialog.form.starter_menu_year`,
    dish_menu_year: `${scope}.page.menu_year.dialog.form.dish_menu_year`,
    dessert_menu_year: `${scope}.page.menu_year.dialog.form.dessert_menu_year`,
    add_new_menu_year: `${scope}.page.menu_year.dialog.form.add_new_menu_year`,
    edit_menu_year: `${scope}.page.menu_year.dialog.form.edit_menu_year`,
    add_dish_year: `${scope}.page.menu_year.dialog.form.add_dish_year`,
    pause_menu_year: `${scope}.page.menu_year.dialog.form.pause_menu_year`,
    play_menu_year: `${scope}.page.menu_year.dialog.form.play_menu_year`,
    total_menu_year: `${scope}.page.menu_year.dialog.form.total_menu_year`,
    generate_menu_other_week: `${scope}.page.menu_year.dialog.form.generate_menu_other_week`,
    dialog_title_classroom: `${scope}.page.classroom.dialog.form.dialog_title_classroom`,
    dialog_edit_title_classroom: `${scope}.page.classroom.dialog.form.dialog_edit_title_classroom`,
    dialog_input_classroom: `${scope}.page.classroom.dialog.form.dialog_input_classroom`,
    dialog_table_header_classroom: `${scope}.page.classroom.dialog.form.dialog_table_header_classroom`,

    dialog_tab_hd_club_illustration: `${scope}.page.mini_club.form.dialog_tab_hd_club_illustration`,
    dialog_tab_hd_club_title: `${scope}.page.mini_club.form.dialog_tab_hd_club_title`,
    dialog_tab_hd_club_start_time: `${scope}.page.mini_club.form.dialog_tab_hd_club_start_time`,
    dialog_tab_hd_club_time: `${scope}.page.mini_club.form.dialog_tab_hd_club_time`,
    dialog_tab_hd_club_end_time: `${scope}.page.mini_club.form.dialog_tab_hd_club_end_time`,
    dialog_tab_hd_club_number: `${scope}.page.mini_club.form.dialog_tab_hd_club_number`,
    dialog_tab_hd_club_description: `${scope}.page.mini_club.form.dialog_tab_hd_club_description`,
    dialog_tab_hd_club_price: `${scope}.page.mini_club.form.dialog_tab_hd_club_price`,
    dialog_modal_club_title: `${scope}.page.mini_club.form.dialog_modal_club_title`,
    dialog_modal_club_limit: `${scope}.page.mini_club.form.dialog_modal_club_limit`,
    return_mini_club_list: `${scope}.page.mini_club.form.return_mini_club_list`,
    save_edit_mini_club_list: `${scope}.page.mini_club.form.save_edit_mini_club_list`,
    edit_mini_club_list: `${scope}.page.mini_club.form.edit_mini_club_list`,
    registered_mini_club_list: `${scope}.page.mini_club.form.registered_mini_club_list`,
    empty_registered_mini_club: `${scope}.page.mini_club.form.empty_registered_mini_club`,

    marketing_product_photo: `${scope}.page.marketing.table.marketing_product_photo`,
    marketing_product_nom: `${scope}.page.marketing.table.marketing_product_nom`,
    marketing_product_pix: `${scope}.page.marketing.table.marketing_product_pix`,
    marketing_product_quantity: `${scope}.page.marketing.table.marketing_product_quantity`,
    marketing_product_delivery: `${scope}.page.marketing.table.marketing_product_delivery`,
    marketing_product_delete: `${scope}.page.marketing.table.marketing_product_delete`,
    marketing_product_command: `${scope}.page.marketing.table.marketing_product_command`,
    dialog_modal_title: `${scope}.page.marketing.form.dialog_modal_title`,
    dialog_modal_edit_title: `${scope}.page.marketing.form.dialog_modal_edit_title`,
    return_product_list: `${scope}.page.marketing.form.return_product_list`,
    orders_product_list: `${scope}.page.marketing.form.orders_product_list`,
    empty_order_product: `${scope}.page.marketing.form.empty_order_product`,
    order_identity_product: `${scope}.page.marketing.form.order_identity_product`,
    date_order_product: `${scope}.page.marketing.form.date_order_product`,


    historical_loaned: `${scope}.system.ui.historical_loaned`,
    personal_member: `${scope}.system.ui.personal_member`,
    table_h_agent_caisse: `${scope}.system.ui.table_h_agent_caisse`,
    loading_list_for_notifications: `${scope}.system.ui.loading_list_for_notifications`,
    fees_type: `${scope}.system.ui.fees_type`,
    table_h_echeancier_nom: `${scope}.system.ui.table_h_echeancier_nom`,
    table_h_echeancier_date: `${scope}.system.ui.table_h_echeancier_date`,
    table_h_montant: `${scope}.system.ui.table_h_montant`,
    table_h_echeancier_type: `${scope}.system.ui.table_h_echeancier_type`,
    table_h_profil: `${scope}.system.ui.table_h_profil`,
    table_h_group: `${scope}.system.ui.table_h_group`,
    table_h_scolarite: `${scope}.system.ui.table_h_scolarite`,
    books_loan_requested: `${scope}.system.ui.books_loan_requested`,
    biblio_quantitedispo: `${scope}.system.ui.biblio_quantitedispo`,
    en_stock: `${scope}.system.ui.en_stock`,
    donateur: `${scope}.system.ui.donateur`,
    mes_livres_empruntes: `${scope}.system.ui.mes_livres_empruntes`,
    bibliotheque: `${scope}.system.ui.bibliotheque`,
    disponible: `${scope}.system.ui.disponible`,
    indisponible: `${scope}.system.ui.indisponible`,
    description: `${scope}.system.ui.description`,
    prix_monnaie: `${scope}.system.ui.prix_monnaie`,
    biblio_catlivre: `${scope}.system.ui.biblio_catlivre`,
    biblio_edit_book: `${scope}.system.ui.biblio_edit_book`,
    emprunter: `${scope}.system.ui.emprunter`,
    doc_file: `${scope}.system.ui.doc_file`,
    jour: `${scope}.system.ui.jour`,
    doc_folder_todelete: `${scope}.system.ui.doc_folder_todelete`,
    doc_file_todelete: `${scope}.system.ui.doc_file_todelete`,
    doc_upload_file: `${scope}.system.ui.doc_upload_file`,
    doc_folder_delete: `${scope}.system.ui.doc_folder_delete`,
    gestion_compte: `${scope}.system.ui.gestion_compte`,
    doc_file_delete: `${scope}.system.ui.doc_file_delete`,
    doc_folder: `${scope}.system.ui.doc_folder`,
    doc_edit_folder: `${scope}.system.ui.doc_edit_folder`,
    doc_add_folder: `${scope}.system.ui.doc_add_folder`,
    doc_del_folder: `${scope}.system.ui.doc_del_folder`,
    doc_update_folder: `${scope}.system.ui.doc_update_folder`,
    doc_del_file: `${scope}.system.ui.doc_del_file`,
    doc_add_file: `${scope}.system.ui.doc_add_file`,
    ims_day_sieste_true: `${scope}.system.ui.ims_day_sieste_true`,
    ims_day_sieste_false: `${scope}.system.ui.ims_day_sieste_false`,
    ims_day_bowel: `${scope}.system.ui.ims_day_bowel`,
    ims_day_bowel_many: `${scope}.system.ui.ims_day_bowel`,
    ims_day_week_ims: `${scope}.system.ui.ims_day_week_ims`,
    ims_day_how_ate: `${scope}.system.ui.ims_day_how_ate`,
    book_loan_valid: `${scope}.system.ui.book_loan_valid`,
    book_loan_retour_valid: `${scope}.system.ui.book_loan_retour_valid`,
    emprunteur: `${scope}.system.ui.emprunteur`,
    biblio_livre_delete: `${scope}.system.ui.biblio_livre_delete`,
    emprunt_delete_title: `${scope}.system.ui.emprunt_delete_title`,
    biblio_livre_todelete: `${scope}.system.ui.biblio_livre_todelete`,
    emprunter_book: `${scope}.system.ui.emprunter_book`,
    emprunt_sentence_sujet: `${scope}.system.ui.emprunt_sentence_sujet`,
    biblio_livre: `${scope}.system.ui.biblio_livre`,
    none: `${scope}.system.ui.none`,
    selection_delete: `${scope}.system.ui.selection_delete`,
    selection_to_delete: `${scope}.system.ui.selection_to_delete`,
    selection_edition: `${scope}.system.ui.selection_edition`,
    add_item: `${scope}.system.ui.add_item`,
    add_children: `${scope}.system.ui.add_children`,
    generate_financial_schedule: `${scope}.system.ui.generate_financial_schedule`,
    scolarite_typepaiement: `${scope}.system.ui.scolarite_typepaiement`,
    scolarite_echeancier: `${scope}.system.ui.scolarite_echeancier`,
    scolarite_echeancier_enfant: `${scope}.system.ui.scolarite_echeancier_enfant`,
    fraisoneshot_enfant: `${scope}.system.ui.fraisoneshot_enfant`,
    groups: `${scope}.system.ui.groups`,
    the_group: `${scope}.system.ui.the_group`,
    load_children: `${scope}.system.ui.load_children`,
    groupsenfants: `${scope}.system.ui.groupsenfants`,


    tooltip_generate_account: `${scope}.system.ui.tooltip_generate_account`,
    tooltip_activate_account: `${scope}.system.ui.tooltip_activate_account`,
    tooltip_suspended_account: `${scope}.system.ui.tooltip_suspended_account`,
    tooltip_deleted_account: `${scope}.system.ui.tooltip_deleted_account`,
    all_label: `${scope}.system.ui.all_label`,
    all_label_rdv: `${scope}.system.ui.all_label_rdv`,
    mes_notifications: `${scope}.system.ui.mes_notifications`,
    paiement_mode_numero_placeholder: `${scope}.system.ui.paiement_mode_numero_placeholder`,
    paiement_mode_numero: `${scope}.system.ui.paiement_mode_numero`,
    paiement_mode_date: `${scope}.system.ui.paiement_mode_date`,
    paiement_cheque: `${scope}.system.ui.paiement_cheque`,
    paiement_espece: `${scope}.system.ui.paiement_espece`,
    paiement_virement: `${scope}.system.ui.paiement_virement`,
    paiement_mode: `${scope}.system.ui.paiement_mode`,
    paiement_by: `${scope}.system.ui.paiement_by`,
    paiement_date: `${scope}.system.ui.paiement_date`,
    survey_list: `${scope}.system.ui.survey_list`,
    add_survey_list: `${scope}.system.ui.add_survey_list`,
    open_survey_list: `${scope}.system.ui.open_survey_list`,
    close_survey_list: `${scope}.system.ui.close_survey_list`,
    gestion_compte_parent: `${scope}.system.ui.gestion_compte_parent`,
    gestion_compte_equipe: `${scope}.system.ui.gestion_compte_equipe`,
    accountetat_ok: `${scope}.system.ui.accountetat_ok`,
    accountetat_locked: `${scope}.system.ui.accountetat_locked`,
    accountetat_pending_validation: `${scope}.system.ui.accountetat_pending_validation`,
    accountetat_suspended: `${scope}.system.ui.accountetat_suspended`,
    generer_compte_question: `${scope}.system.ui.generer_compte_question`,
    suspendre_compte_question: `${scope}.system.ui.suspendre_compte_question`,
    activer_compte_question: `${scope}.system.ui.activer_compte_question`,
    supprimer_compte_question: `${scope}.system.ui.supprimer_compte_question`,
    generer_compte: `${scope}.system.ui.generer_compte`,
    fermer_compte: `${scope}.system.ui.fermer_compte`,
    activer_compte: `${scope}.system.ui.activer_compte`,
    supprimer_compte: `${scope}.system.ui.supprimer_compte`,
    account_ok: `${scope}.system.ui.account_ok`,
    account_locked: `${scope}.system.ui.account_locked`,
    account_pending_validation: `${scope}.system.ui.account_pending_validation`,
    account_suspended: `${scope}.system.ui.account_suspended`,
    libelle_libelle: `${scope}.system.ui.libelle_libelle`,
    libelle_title: `${scope}.system.ui.libelle_title`,
    close_this: `${scope}.system.ui.close_this`,
    close_sondage: `${scope}.system.ui.close_sondage`,
    notif_create: `${scope}.system.ui.notif_create`,
    notif_update: `${scope}.system.ui.notif_update`,
    notif_delete: `${scope}.system.ui.notif_delete`,
    notif_update_action: `${scope}.system.ui.notif_update_action`,
    notif_read: `${scope}.system.ui.notif_read`,
    header_nom: `${scope}.system.ui.header_nom`,
    header_code: `${scope}.system.ui.header_code`,
    header_quantite: `${scope}.system.ui.header_quantite`,
    send_to: `${scope}.system.ui.send_to`,
    rdv_list: `${scope}.system.ui.rdv_list`,
    oui: `${scope}.system.ui.oui`,
    non: `${scope}.system.ui.non`,
    period: `${scope}.system.ui.period`,
    return_to_list: `${scope}.system.ui.return_to_list`,
    ims_menu_cantine_obs: `${scope}.system.ui.ims_menu_cantine_obs`,
    ims_nap_time: `${scope}.system.ui.ims_nap_time`,
    ims_menu_obs_very_well: `${scope}.system.ui.ims_menu_obs_very_well`,
    ims_menu_obs_easily: `${scope}.system.ui.ims_menu_obs_easily`,
    ims_menu_obs_good: `${scope}.system.ui.ims_menu_obs_good`,
    ims_menu_obs_not_good: `${scope}.system.ui.ims_menu_obs_not_good`,
    ims_menu_obs_not_at_all: `${scope}.system.ui.ims_menu_obs_not_at_all`,
    ims_menu_day: `${scope}.system.ui.ims_menu_day`,
    note: `${scope}.system.ui.note`,
    ims_plat_entree: `${scope}.system.ui.ims_plat_entree`,
    ims_plat_plat: `${scope}.system.ui.ims_plat_plat`,
    ims_plat_dessert: `${scope}.system.ui.ims_plat_dessert`,
    rdv_read_void: `${scope}.system.ui.rdv_read_void`,
    obs_parent: `${scope}.system.ui.obs_parent`,
    child_stuff: `${scope}.system.ui.child_stuff`,
    obs_gen: `${scope}.system.ui.obs_gen`,
    day: `${scope}.system.ui.day`,
    ims_day_title: `${scope}.system.ui.ims_day_title`,
    times_number: `${scope}.system.ui.times_number`,
    child: `${scope}.system.ui.child`,
    start_day: `${scope}.system.ui.start_day`,
    end_day: `${scope}.system.ui.end_day`,
    hour: `${scope}.system.ui.hour`,
    start_hour: `${scope}.system.ui.start_hour`,
    end_hour: `${scope}.system.ui.end_hour`,
    save_form_crud_btn: `${scope}.system.ui.save_form_crud_btn`,
    edit: `${scope}.system.ui.edit`,
    delete_in_table: `${scope}.system.ui.delete_in_table`,
    back_action_stepper: `${scope}.system.ui.back_action_stepper`,
    next_action_stepper: `${scope}.system.ui.next_action_stepper`,
    import_action: `${scope}.system.ui.import_action`,
    la_valeur: `${scope}.system.ui.la_valeur`,
    must_be_uniq: `${scope}.system.ui.must_be_uniq`,
    sb_add_success: `${scope}.system.ui.snackbar.message.sb_add_success`,
    sb_edit_success: `${scope}.system.ui.snackbar.message.sb_edit_success`,
    sb_delete_success: `${scope}.system.ui.snackbar.message.sb_delete_success`,
    sb_success: `${scope}.system.ui.snackbar.message.sb_success`,
    sb_error: `${scope}.system.ui.snackbar.message.sb_error`,
    sb_action_error: `${scope}.system.ui.snackbar.message.sb_action_error`,
    sb_selection_empty: `${scope}.system.ui.snackbar.message.sb_selection_empty`,
    sb_selection_only_suspended_account: `${scope}.system.ui.snackbar.message.sb_selection_only_suspended_account`,
    sb_employee_exist: `${scope}.system.ui.snackbar.message.sb_employee_exist`,
    sb_only_suspended_to_active: `${scope}.system.ui.snackbar.message.sb_only_suspended_to_active`,
    sb_filesize_limit: `${scope}.system.ui.snackbar.message.sb_filesize_limit`,
    sb_not_enough_book_in_store: `${scope}.system.ui.snackbar.message.sb_not_enough_book_in_store`,
    sb_parent_already_exist: `${scope}.system.ui.snackbar.message.sb_parent_already_exist`,
    sb_error_400: `${scope}.system.ui.snackbar.message.sb_error_400`,
    enum_admin: `${scope}.system.ui.enum.role.enum_admin`,
    enum_teacher: `${scope}.system.ui.enum.role.enum_teacher`,
    enum_support: `${scope}.system.ui.enum.role.enum_support`,


    //FORM CONNEXTION
    title_form_login: `${scope}.page.user.login.form.title_form_login`,
    mail_number_form_login: `${scope}.page.user.login.form.input.mail_number_form_login`,
    password_form_login: `${scope}.page.user.login.form.input.password_form_login`,
    remember_form_login: `${scope}.page.user.login.form.input.remember_form_login`,
    forgot_password_form_login: `${scope}.page.user.login.form.input.forgot_password_form_login`,
    connection_form_login: `${scope}.page.user.login.form.button.connection_form_login`,
    logout_form_app: `${scope}.page.user.login.form.button.logout_form_app`,
    // RESET PASSWORD FORM
    title_form_reset: `${scope}.page.user.forgot_password.form.title_form_reset`,
    email_form_reset: `${scope}.page.user.forgot_password.form.input.email_form_reset`,
    reset_password_form_reset: `${scope}.page.user.forgot_password.form.button.reset_password_form_reset`,

    search: {
        id: `${scope}.search`,
        defaultMessage: 'Search',
    },
    contacts: {
        id: `${scope}.contacts`,
        defaultMessage: 'Contacts',
    },
    add_contacts: {
        id: `${scope}.add_contacts`,
        defaultMessage: 'Add New Contact',
    },
    all: {
        id: `${scope}.all`,
        defaultMessage: 'All',
    },
    favorites: {
        id: `${scope}.favorites`,
        defaultMessage: 'Favorites',
    },
    block: {
        id: `${scope}.block`,
        defaultMessage: 'Block Contact',
    },
    delete: {
        id: `${scope}.delete`,
        defaultMessage: 'Delete Contact',
    },
    phone: {
        id: `${scope}.phone`,
        defaultMessage: 'Phone',
    },
    secondary_phone: {
        id: `${scope}.secondary_phone`,
        defaultMessage: 'Secondary Phone',
    },
    personal_email: {
        id: `${scope}.personal_email`,
        defaultMessage: 'Personal Email',
    },
    company_email: {
        id: `${scope}.company_email`,
        defaultMessage: 'Company Email',
    },
    address: {
        id: `${scope}.address`,
        defaultMessage: 'Address',
    },
    website: {
        id: `${scope}.website`,
        defaultMessage: 'Website',
    },
    add_new: {
        id: `${scope}.add_new`,
        defaultMessage: 'Add New Item',
    },
    upload: {
        id: `${scope}.upload`,
        defaultMessage: 'UPLOAD AVATAR',
    },
    name: {
        id: `${scope}.name`,
        defaultMessage: 'Name',
    },
    title: {
        id: `${scope}.title`,
        defaultMessage: 'Title',
    },
    submit: {
        id: `${scope}.submit`,
        defaultMessage: 'Submit',
    },
    reset: {
        id: `${scope}.reset`,
        defaultMessage: 'Reset',
    }

}

export default messages;
