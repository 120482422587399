import React, {useEffect, useMemo, useRef} from 'react';
import Box from "@mui/material/Box";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {Button, CircularProgress, Grid, Icon, MenuItem, TextField} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import useScolariteProformaEnfantVariables from "./businesslogic/ProformaVariables";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import _ from "lodash";
import proformaStateUpdate from "./businesslogic/ProformaStateUpdate";
import profromaBusinessLogic from "./businesslogic/ProfromaBusinessLogic";
import PrintIcon from "@mui/icons-material/Print";
import factureStyles from "./jss/alertmessage-jss";
import LoadingButton from "@mui/lab/LoadingButton";
import PeopleIcon from '@mui/icons-material/People';
import {ScolariteProformaEnfant} from "../../../constants/type/ScolariteProformaEnfant";
import messages from "../../messages/general/messages";
import {scolariteProformaDataImpl} from "../../../constants/type/ScolariteProforma";
import PapperBlock from '../../../components/ui/papperBlock/PapperBlock';
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import SchoolingSubMenu from "../SchoolingSubMenu";
import ProformaEditForm from './ProformaEditForm';
import ProformaPdfForm from "./ProformaPdfForm";
import {isPositiveValue} from "../../../helpers/helpers";
import {
    getDefaultMRTOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useBusinessLogic = (props: any): any => {
    const styleClasses = factureStyles();

    return {
        theme: props.theme,
        styleClasses: styleClasses,
        ...useScolariteProformaEnfantVariables({styleClasses, ...props})
    }
};

const defaultMRTOptions = getDefaultMRTOptions<ScolariteProformaEnfant>();
export const FactureContext = React.createContext({});
function Proforma(props:any) {
    const { locale, theme} = props;
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();
    const intl = useIntl() ;
    const styleClasses = logic.styleClasses;

    const searchPlaceholderRef =  useRef<string>(logic.activeLang === 'en' ? 'Find a child' : 'Rechercher un enfant');

    const columns = useMemo<MRT_ColumnDef<ScolariteProformaEnfant>[]>(
        () => [
            {
                accessorKey: 'scolariteAnneeForm.nom',
                header:intl.formatMessage({id: messages.table_h_scolarite}),
                size: 100,
            },
            {
                accessorKey: 'enfantNomForm',
                header:  intl.formatMessage({id: messages.table_h_child_name}),
            },
            {
                accessorKey: 'groupesectionNomForm',
                header:intl.formatMessage({id: messages.table_h_group}),
            },
            {
                accessorKey: 'scolaritetypesnames',
                header:  intl.formatMessage({id: messages.schooling_fees_type}),
            },
            {
                accessorKey: 'montantForm',
                header: intl.formatMessage({id: messages.schooling_amount}),
                size: 150,
                Cell: ({ cell }) => (
                    <div style={{textAlign:'right', alignItems:"right", width:'100'}}>
                        {cell.getValue<any>()}
                    </div>
                ),
            },
        ],
        [logic.activeLang],
    );

    const imprimer = async (row:MRT_Row<any>) => {
        const data:any = logic.dataList[row.index];
        proformaStateUpdate.proformaEnfantSel(logic, data);
        proformaStateUpdate.openPDFDialogForm(logic, true);
    }
    const handleOnProformaGroupChange = (event: any) => {
        const proformaId = parseInt(event.target.value);
        const data = _.find(logic.proformaDataList, {id:proformaId}) ?? _.cloneDeep(scolariteProformaDataImpl);
        proformaStateUpdate.dataSel(logic, data);
    };

    const handleLoadEnfantProforma = () => {
        if(isPositiveValue(logic.dataSel.id) === false){
            return ;
        }

        proformaStateUpdate.tableDataLoading(logic, true);
        profromaBusinessLogic.loadEnfantProformas(logic, logic.dataSel.id).then((val:any)=>{
            proformaStateUpdate.tableDataLoading(logic, false);
            proformaStateUpdate.tableKeyValue(logic, moment().valueOf());
        }).catch((error:any) => {
            proformaStateUpdate.tableDataLoading(logic, false);
        });
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:ScolariteProformaEnfant[] = [];
        rows.map((row:any) => {
            const data:ScolariteProformaEnfant = logic.dataList[row.index];
            dataSelected.push(data);
        });
        profromaBusinessLogic.deleteDatas(logic, messages, dataSelected);

    }

    const handleNouvellesFactures = () => {
        proformaStateUpdate.dataSel(logic, _.cloneDeep(scolariteProformaDataImpl));
        proformaStateUpdate.openEditDialogForm(logic, true);
    }
    const handleNotifierParents = () => {
        if(isPositiveValue(logic.dataSel.id) === false){
            return ;
        }
        proformaStateUpdate.processingStateNormBtn(logic, true);
       profromaBusinessLogic.notifier(logic, messages, logic.dataSel.id).then((val:boolean)=>{
           proformaStateUpdate.processingStateNormBtn(logic, false);
       }).catch((error:any) => { proformaStateUpdate.processingStateNormBtn(logic, false);});
    }

    const handleNotifierParentsSelectionner = () => {
        if(isPositiveValue(logic.dataSel.id) === false){
            return ;
        }
        proformaStateUpdate.processingStateNormBtn(logic, true);
        profromaBusinessLogic.notifier(logic, messages, logic.dataSel.id).then((val:boolean)=>{
            proformaStateUpdate.processingStateNormBtn(logic, false);
        }).catch((error:any) => { proformaStateUpdate.processingStateNormBtn(logic, false);});
    }

    useEffect(() => {
        handleLoadEnfantProforma();
    }, [logic.dataSel.id]);

   useEffect(() => {
        moment.locale(logic.activeLang);
        profromaBusinessLogic.initPostConstruct(logic);

       return () => logic.storeState(logic.tableConfigRef.current.getState());
    }, []);

   const ToolbarOthersButtons = () => (
       <>
           <Button variant={"contained"}
                   component="label"
                   size="small"
                   color="primary"
                   sx={{ textTransform: 'none', marginLeft:1, paddingLeft:1, paddingRight:2}}
                   onClick={handleNouvellesFactures}
           >
               <Icon sx={{marginRight:1}}>add</Icon>
               <FormattedMessage id={messages.generer_facture}/>
           </Button>

           <LoadingButton
               loading={logic.processingStateNormBtn}
               color="primary"
               variant="contained"
               loadingPosition="start"
               startIcon={<PeopleIcon />}
               aria-label="Loading Parents"
               size="large"
               disabled={logic.dataSel.notifier}
               onClick={handleNotifierParents}
           >
               <FormattedMessage id={messages.notifier_parent}/>
           </LoadingButton>
       </>
   );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    const tableConfig = useMaterialReactTable({
        ...defaultMRTOptions,
        enableColumnFilters:false,
        enableFullScreenToggle:false,
        localization,
        columns,
        data: logic.dataList,
        initialState:{
            pagination: {
                pageIndex: 0,
                pageSize: 50,
            }
        },
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                showAddButton={false}
                styleClasses={styleClasses}
                otherComponents={<ToolbarOthersButtons />}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editIcon={<PrintIcon />}
                editRow={() => imprimer(row)}
                styleClasses={styleClasses}
            />
        )
    });


    const proformaMenuItems = useMemo<any>(() => {
        return _.map(logic.proformaDataList, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                {item.nomForm}
            </MenuItem>
        ));
    }, [logic.proformaDataList]) ;

    return (
        <div className={styleClasses.root}>
            <Box className={styleClasses.buttonContainer}>
                <SchoolingSubMenu classes={styleClasses} selected="proforma"/>
            </Box>
            <PapperBlock whiteBg noMargin displayNoTitle={true} >
                {
                    logic.contentLoaded === false &&
                    <Box  sx={{ width: '100%', height:'200px', textAlign: 'center', alignContent:'center', verticalAlign:'middle' }}>
                        <CircularProgress color="primary" />
                    </Box>
                }
                {
                    logic.contentLoaded &&
                    <Box className={styleClasses.schoolingContainer}>
                        <Box className={styleClasses.searchBox}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={6}></Grid>
                                <Grid item md={6} xs={6}>
                                    <TextField
                                        select
                                        sx={{width: {xs: '100% !important'},}}
                                        color={"secondary"}
                                        value={logic.dataSel.id}
                                        onChange={handleOnProformaGroupChange}
                                        label={<FormattedMessage id={messages.proforma_du} />}
                                    >
                                        <MenuItem key="0" value="" sx={{fontWeight: 400}}>-------</MenuItem>
                                        {proformaMenuItems}
                                    </TextField>
                                </Grid>

                            </Grid>

                        </Box>

                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}  >
                                <MaterialReactTable table={tableConfig} />
                            </Grid>
                        </Grid>
                    </Box>
                }
            </PapperBlock>

            <FactureContext.Provider value={logic}>
                <ProformaEditForm />
            </FactureContext.Provider>
            <FactureContext.Provider value={logic}>
                <ProformaPdfForm />
            </FactureContext.Provider>
        </div>
    );
}

export default withSnackbar(Proforma);
