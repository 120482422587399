import React, {useContext} from "react";
import {SchoolDocumentContext} from "./SchoolDocument";
import {Box, Button, Grid} from "@mui/material";
import {DocumentScolaire} from "../../constants/type/DocumentScolaire";
import schoolDocStateUpdate from "./businesslogic/SchoolDocumentStateUpdate";

function DocumentFolder() {
    const logic: any = useContext(SchoolDocumentContext);
    const styleClasses = logic.styleClasses ;
    const docForSel = logic.docFolderSel;
    const onSelect = (event:any, doc:DocumentScolaire) => {
        if (event.detail === 1) {
            schoolDocStateUpdate.docFolderSel(logic, doc);
        } else if (event.detail === 2) {
            schoolDocStateUpdate.goInDocFolderSel(logic, doc);
        }
    };

    if(logic.showFiles){
        return <></>;
    }//sx={item.id !== docForSel.id ? {backGroundColor:'#'}:{}}
    return(
        logic.docFolders.map((item:any) => (
            <Grid item xs={2} sm={2} md={2}  key={item.id} className={styleClasses.spacingGridContainer}>
                <Button
                    variant={item.id !== docForSel.id ? "text":"contained"}
                    onClick={(event:any) => onSelect(event, item)}>
                    <Box className={styleClasses.containerBtnFileFolder}>
                        <img
                            width="48"
                            height="48"
                            src={logic.folderPictureRef.current} alt={item.nom} />
                        <span>{item.nom}</span>
                    </Box>
                </Button>
            </Grid>
        ))
    );
}


export default DocumentFolder ;