import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {MaterialReactTable, MRT_ColumnDef, type MRT_Localization, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {Box} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import React, {useEffect, useMemo} from "react";
import {makeStyles} from "@mui/styles";
import {LivreCategorie, livreCategorieDataImpl, livreCategorieSchema} from "../../../constants/type/LivreCategorie";
import useLivreCategorieVariables from "./businesslogic/CategoreLivreVariables";
import {request} from "../../../helpers/axios_helpers";
import categoreLivreBusinessLogic from "./businesslogic/CategoreLivreBusinessLogic";
import _ from "lodash";
import categoreLivreStateUpdate from "./businesslogic/CategoreLivreStateUpdate";
import {getErrorMessage} from "../../../helpers/errorManager";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import CategoreLivreForm from "./CategoreLivreForm";
import styles from "./../config-table-jss";
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";


const useStyles = makeStyles(styles);


const useBusinessLogic = (props: any): any => {


    return {
        theme: props.theme,
        styleClasses: useStyles(),
        ...useLivreCategorieVariables(props),
    };
}

export const CategorieLivreContext = React.createContext({});

const CategoreLivrePage= (props:any) => {
    const { locale,  } = props;
    const logicRacine = useBusinessLogic(props);
    const intl = useIntl() ;

    //const classes = logicRacine.classes;

    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(livreCategorieDataImpl),
        // @ts-ignore
        resolver: yupResolver(livreCategorieSchema),
    }));
    const {errors} = formRef.current.formState;


    const handleClickOpen = () => categoreLivreStateUpdate.openDialog(logicRacine, true);
    const addData = () => {
        categoreLivreStateUpdate.livreCategorieSel(logicRacine, _.cloneDeep(livreCategorieDataImpl));
        handleClickOpen();
    };
    const handleRowsDelete = (rows:any) => {

        const dataSelected:any = [];
        const dataSelectedId:any = [];
        rows.map((row:any) => {
            const data = logicRacine.livreCategorieList[row.index];
            dataSelected.push(data);
            dataSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/categorielivre/bouquet', dataSelectedId).then((response) => {
            categoreLivreBusinessLogic.loadingDatas(logicRacine);
            logicRacine.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            // Error message
            logicRacine.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    }

    const editData = (row: any) => {
        const data: any = _.cloneDeep(logicRacine.livreCategorieList[row.index]);
        categoreLivreStateUpdate.livreCategorieSel(logicRacine,data);
        handleClickOpen();
    }
    const columns = useMemo<MRT_ColumnDef<LivreCategorie>[]>(
        () => [
            {
                accessorKey: 'nom',
                header: intl.formatMessage({id: messages.header_nom}),
            },
        ],
        [logicRacine.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logicRacine.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logicRacine.activeLang]) ;
    const tableConfig = useMaterialReactTable({
        ...logicRacine.defaultOptions,
        localization,
        columns: columns,
        data: logicRacine.livreCategorieList,
        state:{
            showProgressBars: logicRacine.tableDataLoading,
            showSkeletons: logicRacine.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addData}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={logicRacine.styleClasses}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editData(row)}
                styleClasses={logicRacine.styleClasses}
            />
        )
    });

    useEffect(() => {
        categoreLivreBusinessLogic.initPostConstruct(logicRacine);

        return () => logicRacine.storeState(tableConfig.getState());
    }, []);

    return (
        <div className={logicRacine.styleClasses.root}>
            <MaterialReactTable
                table={tableConfig}
                key={logicRacine.tableKeyValue}
            />
            <CategorieLivreContext.Provider value={logicRacine}>
                <Box>
                    <CategoreLivreForm />
                </Box>
            </CategorieLivreContext.Provider>

        </div>
    )
}

export default withSnackbar(CategoreLivrePage);