import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {ScolariteType, scolariteTypeDataImpl} from "./ScolariteType";
import moment from "moment";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import {isPositiveValue} from "../../helpers/helpers";
import {ScolariteGroup, scolariteGroupDataImpl} from "./ScolariteGroup";
import {
    ScolariteVersementEcheancierEnfant,
    SCOLARITEVERSEMENTECHEANCIERENFANT_CONSTANT
} from "./ScolariteVersementEcheancierEnfant";
import {Child, childCustomOptionsPlugins, childDataImpl, columnsChildPlugin} from "./Child";
import messages from "../../page/messages/general/messages";
import {columnsGroupPlugin} from "./Groupesection";
import React from "react";
import {GroupEnfant, groupEnfantDataImpl} from "./GroupEnfant";

export const SCOLARITEVERSEMENT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteVersement) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteVersementDataImpl);
        item = {...itemInit, ...item} ;

        item.groupEnfantForm    = logic.groupEnfantsMapRef?.current.get(item.groupEnfantId) ?? _.cloneDeep(childDataImpl);
        console.log(item.groupEnfantForm);
        // @ts-ignore
        item.childForm          = item.groupEnfantForm.childForm;

        // @ts-ignore
        item.recuForm            = `${BASEURL_RESOURCES}/${item.recu}` ;
        item.ladateForm          = moment(item.ladate);
        // @ts-ignore
        item.liredateForm        = item.ladateForm.format("L");
        item.dateModePaiementForm = isPositiveValue(item.dateModePaiement) ?  moment(item.dateModePaiement) : null
        item.liredateModePaiementForm = isPositiveValue(item.dateModePaiement) ? item.dateModePaiementForm.format("L") : ``;


        // @ts-ignore
        item.scolariteVersementEcheanciers = _.map(item.scolariteVersementEcheanciers, item => SCOLARITEVERSEMENTECHEANCIERENFANT_CONSTANT.convertToFrontEnd(logic, item)) ;

        // @ts-ignore
        item.scolariteVersementEcheanciers  = _.sortBy(item.scolariteVersementEcheanciers, ['laDateEcheance']);

        // @ts-ignore
        item.montantForm            = new Intl.NumberFormat("fr-FR").format(item.montant ?? 0) ;

        item.scolariteGroupForm     = _.find(logic.scolariteGroupsRef.current, {id:item.scolariteGroupId}) ?? _.cloneDeep(scolariteGroupDataImpl);

        item.scolariteTypeForm      = _.find(logic.scolariteTypeRef.current, {id:item.scolariteTypeId}) ?? _.cloneDeep(scolariteTypeDataImpl);
        return item ;
    },
    convertToBackEnd: (logic:any, item:ScolariteVersement) => {
        item.ladate          = item.ladateForm?.valueOf();
        // @ts-ignore
        item.rappeldate      = _.isObject(item.rappeldateForm) ? item.rappeldateForm?.valueOf() : null;

        // @ts-ignore
        item.scolariteVersementEcheanciers = _.map(item.scolariteVersementEcheanciers, item => SCOLARITEVERSEMENTECHEANCIERENFANT_CONSTANT.convertToBackEnd(logic, item)) ;
        return item ;
    },
};
export  const scolariteVersementDataImpl: ScolariteVersement = {
    id: null,
    ladate: 0,
    nom: ``,
    commentaire: ``,
    montant: 0,
    groupEnfantId: 0,
    classeId: 0,
    scolariteGroupId: 0,
    scolariteTypeId: 0,
    scolariteTypeTag: null,
    parentId: 0,
    code:``,
    modePaiementTag:'espece',
    scolaritePaiementmodeId: 1,
    codeModePaiement:``,
    dateModePaiement: null,
    resteapayer: 0,
    nomFraisGroup: ``,
    recu: ``,
    agentCaisse: ``,
    payerpar: ``,
    scolariteVersementEcheanciers:[],
    childForm: _.cloneDeep(childDataImpl),
    groupEnfantForm: _.cloneDeep(groupEnfantDataImpl),
    dateModePaiementForm:null,
    scolariteGroupForm: null,
    montantForm:'',
    recuForm:'',
    ladateForm: moment(),
    liredateForm: '',
    liredateModePaiementForm: '',
    scolariteTypeForm: null,
    common: _.cloneDeep(commonDataImp)
}

export const scolariteVersementSchema = yup.object().shape({
    id: yup.number().nullable(),
    commentaire: yup.string(),
    nom: yup.string(),
    ladate: yup.number().nullable(),
    montant: yup.number().nonNullable().required(),
    groupEnfantId: yup.number().nonNullable().notRequired(),
    classeId: yup.number().nonNullable().notRequired(),
    scolariteGroupId: yup.number().nonNullable().required(),
    scolariteTypeId: yup.number().nonNullable().required(),
    scolariteTypeTag: yup.string().notRequired(),
    parentId: yup.number().nonNullable().notRequired(),
    scolaritePaiementmodeId: yup.number(),
    code: yup.string().notRequired(),
    codeModePaiement: yup.string().notRequired(),
    resteapayer: yup.number().notRequired(),
    agentCaisse: yup.string().notRequired(),
    payerpar: yup.string().notRequired(),
    nomFraisGroup: yup.string().notRequired(),
    recu: yup.string().notRequired(),
    modePaiementTag: yup.string().notRequired().default('espece'),
    dateModePaiement: yup.number().notRequired().nullable(),
    scolariteVersementEcheanciers: yup.array().of(yup.mixed<ScolariteVersementEcheancierEnfant>()).nonNullable().default([]),
    common: yup.mixed<Common>(),
    childForm: yup.mixed<Child>(),
    groupEnfantForm: yup.mixed<GroupEnfant>().nullable().notRequired(),
    scolariteGroupForm: yup.mixed<ScolariteGroup>().nullable().notRequired(),
    dateModePaiementForm: yup.mixed<any>().notRequired().nullable(),
    recuForm: yup.string().notRequired(),
    montantForm:  yup.mixed<any>().notRequired(),
    ladateForm: yup.mixed<any>().notRequired(),
    liredateForm: yup.string().nonNullable().notRequired(),
    liredateModePaiementForm: yup.string().nonNullable().notRequired(),
    scolariteTypeForm: yup.mixed<ScolariteType>().nullable().notRequired(),
});


export const excelVersementDataMapping = (
    {rows=[], intl={}, infoSpace=``} :
        {rows:any[]; intl:any; infoSpace: string;  }
) => {
    const echeanciers = _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`] = data.scolariteGroupForm?.scolariteAnneeForm?.nom;
        obj[`${infoSpace} ${intl.formatMessage({id:messages.header_code})}`] = data.code;
        obj[`${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_title})}`] = data.nom;
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`] = data.montantForm;
        obj[`${infoSpace} ${intl.formatMessage({id:messages.paiement_date})}`] = data.liredateForm;
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_agent_caisse})}`] = data.agentCaisse;
        return obj;
    });
    return echeanciers;
}


export const versementCustomOptions = {
    columnVisibility : {
        ...childCustomOptionsPlugins.columnVisibility,
        'payerpar': false,
        'code': false,
        'modePaiementTag': false,
        'codeModePaiement': false,
        'liredateModePaiementForm': false,
        'commentaire': false,
        'agentCaisse': false,
        'childForm.childnomForm':true,
    }
} ;

export const columnsVersement = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        {
            accessorKey: 'scolariteGroupForm.scolariteAnneeForm.nom',
            header: intl.formatMessage({id: messages.table_h_scolarite}),
            size: 80,
        },
        ...columnsChildPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_enfant})}),
        {
            accessorKey: 'code',
            header:   intl.formatMessage({id: messages.header_code}),
            //size: 700,
        },
        {
            accessorKey: 'nom',
            header: intl.formatMessage({id: messages.schooling_fees_title}),
        },
        {
            accessorKey: 'montant',
            header:  intl.formatMessage({id: messages.table_h_montant}),
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive', // default (or between)
            muiFilterSliderProps: {
                //no need to specify min/max/step if using faceted values
                marks: true,
                step: 5_000,
                valueLabelFormat: (value:number) =>
                    Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(value)
            },
            muiTableBodyCellProps: {
                align: 'right',
                fontWeight: 'bold'
            },
            // @ts-ignore
            Cell: ({cell}) => ( <b>
                    {
                        // @ts-ignore
                        Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(cell.getValue<number>())
                    }
                </b>
            ),
        },
        {
            accessorKey: 'liredateForm',
            header: intl.formatMessage({id:messages.paiement_date}),
            size: 130,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
        },
        {
            accessorKey: 'agentCaisse',
            header: intl.formatMessage({id: messages.table_h_agent_caisse}),
            size: 100,
        },
        {
            accessorKey: 'payerpar',
            header: intl.formatMessage({id: messages.paiement_by}),
            size: 100,
        },
        {
            accessorKey: 'modePaiementTag',
            filterVariant: 'multi-select',
            header: intl.formatMessage({id: messages.paiement_mode}),
            size: 100,
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                const key = `paiement_${cell.getValue<string>()}`;
                const messageId = messages[key];
                return messageId ? intl.formatMessage({id: messageId}) : '';
            },
        },
        {
            accessorKey: 'codeModePaiement',
            header: intl.formatMessage({id: messages.paiement_mode_numero}),
            size: 100,
        },
        {
            accessorKey: 'liredateModePaiementForm',
            header: intl.formatMessage({id: messages.paiement_mode_date}),
            size: 100,
        },
        {
            accessorKey: 'commentaire',
            header: intl.formatMessage({id: messages.comment}),
        },
    ];
} ;

export type ScolariteVersement = yup.InferType<typeof scolariteVersementSchema>;
