import React, {useContext, useEffect, useMemo, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {Grid, MenuItem, TextField} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../messages/general/messages";
import {MaterialReactTable, MRT_ColumnDef, type MRT_Localization, useMaterialReactTable} from "material-react-table";
import styles from "../config/config-table-jss";
import childrenData from "../../data/childrenData";
import {ChilDayContext} from "./ChildDayAtIms";
import {columnsImsDay, ImsDay, IMSDAY_CONSTANT} from "../../constants/type/ImsDay";
import _ from "lodash";
import {request} from "../../helpers/axios_helpers";
import {getErrorMessage} from "../../helpers/errorManager";
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme: any) => {
    return {
        active: {
            color: 'red',
        },
        button: {},
        buttonContainer: {
            textAlign: 'center',
            marginBottom: 20,
            '& $button': {
                color: theme.palette.grey[700],
                '&:hover': {},
                '&:focus': {
                    //color: theme.palette.secondary.main,
                },
            },
            '& $active': {
                color: theme.palette.secondary.main,
            },
        },
        content: {
            padding: theme.spacing(3),
            paddingTop: 30,
        },
        tableContainer: {
        }
    }
});

type ChildrenType = {
    id: number,
    firstName: string,
    lastName: string,
    photo: string,
    birthDate: string,
    gender: string,
    address: string,
    classroomName: string,
};

const data:ChildrenType[] = childrenData;

const useBusinessLogic = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses, imsDaySelect, activeLang} = props;

}

export const loadImsDayOfClasse = (logic:any, id:number) => {
    logic.seTableDataLoading(true);
    const daysAgo = 30;
    return new Promise<boolean>(resolve =>
        request("GET",  `/extra/imsday/classe/${id}/since/${daysAgo}`, {}).then((response) => {
            let items : ImsDay[]   = response.data._embedded?.imsDayDTOModelList ?? [];
            const imsdays =  _.map(items, item => IMSDAY_CONSTANT.convertToFrontEnd(logic, item))
            const imsdaysSorts =_.reverse(_.sortBy(imsdays, ['theDate'])) ;
            logic.setImsDayList(imsdaysSorts) ;
            logic.seTableDataLoading(false);
            resolve(true);
        }).catch((error) => {
            logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            logic.seTableDataLoading(false);
            resolve(false);
        })
    );
}
function ChildDayList(props:any) {
    const {theme, locale,styleClasses} = props;
    const logic: any = useContext(ChilDayContext);



    const intl = useIntl() ;
    //const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    //const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';

    const handleOnClasseChange = (event:any) =>  logic.setClasseSel(_.toInteger(event.target.value));

    const columns = useMemo<MRT_ColumnDef<ImsDay>[]>(
        // @ts-ignore
        () => [
            ...columnsImsDay(
                {
                    intl:intl,
                    enfantSpace: ``,
                }
            )
        ],
        [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    const tableConfig = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.imsDayList,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={logic.addImsDay}
                handleRowsDelete={() => logic.handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={styleClasses}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => logic.editImsDay(row)}
                styleClasses={styleClasses}
            />
        )
    });

    useEffect(()=>{
        if(logic.initialized && logic.showForm === false){
            loadImsDayOfClasse(logic, logic.classeSel) ;
        }
    }, [logic.classeSel, logic.showForm, logic.initialized]);


    return (
        <div className={styleClasses.root}>
            {
                !logic.isTeacherRef.current &&
                <Grid container spacing={2} sx={{mb:4}}>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.classroom_child} />}
                            sx={{ width: { xs: '100% !important'}, }}
                            color={"secondary"}
                            value={logic.classeSel}
                            onChange={handleOnClasseChange}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight:400}}>-------</MenuItem>
                            {logic.classeRoomRef.current?.map((opt:any) => (
                                <MenuItem key={opt.id} value={opt.id} sx={{fontWeight:400}}>
                                    {opt.nom}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                </Grid>
            }

            <div className={styleClasses.tableContainer}>
                <MaterialReactTable
                    table={tableConfig}
                    key={logic.tableKeyValue}
                />
                {/*<MaterialReactTable
                    columns={columns}
                    data={logic.imsDayList}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableColumnResizing={true}
                    initialState={{
                        pagination: {
                            pageIndex: 0,
                            pageSize: 50,
                        },
                    }}
                    state={{
                        density:'compact',
                        isLoading: !logic.showTableContent,
                    }}
                    localization={logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR}
                    enableEditing
                    key={logic.tableKeyValue}
                    // editDisplayMode="row"
                    //onEditingRowSave={handleSaveRow}
                    enableRowSelection
                    positionActionsColumn="last"
                    icons={{
                        SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                        CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
                    }}
                    muiTableContainerProps={{ sx: {maxHeight:'500px'} }}
                    renderTopToolbarCustomActions={({ table }: {table:any}) => (
                        <Box
                            className={styleClasses.topToolbarActionsButton}
                            sx={{ display: 'flex',  flexWrap: 'wrap' }}
                        >
                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="primary"
                                    sx={{ textTransform: 'none', marginLeft:1, paddingLeft:1, paddingRight:2}}
                                    onClick={logic.addImsDay}
                            >
                                <Icon sx={{marginRight:1}}>add</Icon>
                                <FormattedMessage id={messages.add_action} />
                            </Button>

                            <Button variant={"contained"}
                                    component="label"
                                    size="small"
                                    color="error"
                                    sx={{ textTransform: 'none', marginLeft:2,  marginRight:2, paddingLeft:1, paddingRight:2}}
                                    disabled={_.isEmpty(table.getSelectedRowModel().rows)}
                                    onClick={() => logic.handleRowsDelete(table.getSelectedRowModel().rows)}
                            >
                                <Icon sx={{marginRight:1}}>delete</Icon>
                                <FormattedMessage id={messages.delete_action} />
                            </Button>

                            <IconButton title={export_xls}>
                                <img width="24" height="24" src={IMGS.excelIcon} alt="Excel icon" />
                            </IconButton>

                            <IconButton title={export_pdf}>
                                <img width="24" height="24" src={IMGS.pdfIcon} alt="PDF icon" />
                            </IconButton>
                        </Box>
                    )}

                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                            <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit} />}>
                                <IconButton
                                    onClick={() => logic.editImsDay(row)}
                                    sx={{ color: 'rgba(0, 0, 0, 0.3)'}}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="right" title={"Generate this personal login acces"}>
                                <IconButton
                                    color="error"
                                    onClick={() => {}}
                                    sx={{ color: 'rgba(0, 0, 0, 0.3)'}}
                                >
                                    <EditCalendarIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />*/}
            </div>
        </div>
    );
}

export default ChildDayList;
