import React, {useContext, useMemo} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {ScolariteEcheancierContext} from "./ScolariteEcheancier";
import scolariteEcheancierStateUpdate from "./businesslogic/ScolariteEcheancierStateUpdate";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

import {
    columnsEcheanciersGrouping,
    EcheancierGrouping,
    echeancierGroupingDataImpl,
    excelEcheancierGroupingDataMapping
} from "../../../constants/type/EcheancierGrouping";
import {EcheancierGroupingDetails} from "../../../constants/type/EcheancierGroupingDetails";


function ScolariteEcheancierList() {
    const logic: any = useContext(ScolariteEcheancierContext);
    const styleClasses = logic.styleClasses;

    const intl = useIntl() ;

    const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    //const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';


    
    const addItem = () => {
        scolariteEcheancierStateUpdate.dataSel(logic, _.cloneDeep(echeancierGroupingDataImpl));
        scolariteEcheancierStateUpdate.openEditDialogForm(logic, true);
    }
    const editItem = (row:MRT_Row<any>) => {
        //console.log("data.scolariteGroupId >>> ", row);
        //const data:any = logic.dataList[row.index];
        const data:any = row.original ;
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        let dataToSel = data ;
        if(data.typeModeForm === 'EcheancierGroupingDetails'){
            dataToSel = _.find(logic.dataList, {'typeModeForm': 'EcheancierGrouping', 'scolariteGroupId':data.scolariteGroupId});
        }
        if(_.isNull(dataToSel) || _.isUndefined(dataToSel)){
            return ;
        }
        scolariteEcheancierStateUpdate.dataSel(logic, _.cloneDeep(dataToSel));
        scolariteEcheancierStateUpdate.openEditDialogForm(logic, true);
    }

    const handleRowsDelete = (rows:any) => {
       const dataSelected:EcheancierGroupingDetails[] = [];
        for(const row of rows){
            if(row.original.typeModeForm !== 'EcheancierGroupingDetails'){
                continue ;
            }
            dataSelected.push(row.original);
        }
        if(_.isEmpty(dataSelected)){
            return ;
        }
        scolariteEcheancierStateUpdate.datasDeleteSelected(logic, dataSelected);
        scolariteEcheancierStateUpdate.openDeleteDialogForm(logic, true);
        
    }

    const columns = useMemo<MRT_ColumnDef<EcheancierGrouping>[]>(
        // @ts-ignore
        () => [...columnsEcheanciersGrouping({intl:intl})
        ], [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        enableExpanding: true,
        filterFromLeafRows: true,
        paginateExpandedRows: false,
        enableSubRowSelection:true,
        getSubRows: (row) => row.echeanciersDetailsForm,
        localization,
        columns,
        data: logic.dataList,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addItem}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().flatRows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                export_xls_title={export_xls}
                xls_nom_fichier="Financial_Schedules"
                excelDataMapping={excelEcheancierGroupingDataMapping({
                    rows:table.getPrePaginationRowModel().flatRows,
                    intl,
                    infoSpace: ``,
                })}
                styleClasses={styleClasses}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={styleClasses}
            />
        )
    });

    return (
        <MaterialReactTable
            table={logic.tableConfigRef.current}
            key={logic.tableKeyValue}
        />
    );
}
export default ScolariteEcheancierList ;