import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {processingJobs} from "../../../../helpers/loader_helpers";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../../constants/type/ClasseRoom";
import securiteEmployeeStateUpdate from "./SecuriteEmployeeStateUpdate";
import {Employee, EMPLOYEE_CONSTANT} from "../../../../constants/type/Employee";
import {UserCompteType} from "../../../../constants/type/UserCompteType";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {Fonction} from "../../../../constants/type/Fonction";
import securiteParentStateUpdate from "../../parent/businesslogic/SecuriteParentStateUpdate";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {getErrorMessage} from "../../../../helpers/errorManager";

const securiteEmployeeBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };
        const requestbody = ['classes', 'profils', 'fonctions'];
        const convertToFrontEnd = (data:any) => {
            const itemscl : ClasseRoom[]   = data.classes.content ?? [];
            logic.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);
            logic.classeRoomRef.current.forEach((data:ClasseRoom) => {
                logic.classeRoomMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsprof : Fonction[]   = data.fonctions.content ?? [];
            logic = processingJobs(logic, itemsprof);
        };

        securiteParentStateUpdate.tableDataLoading(logic, true);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            securiteEmployeeBusinessLogic.loadEmployees(logic);
        }).catch(error => {
            securiteEmployeeStateUpdate.tableDataLoading(logic, false);
            logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });/*
        loadClasseRooms(logic).then(value => {
            logic.classeRoomRef.current.forEach((data:ClasseRoom) => {
                logic.classeRoomMapRef.current.set(data.id, data);
            });
            loadProfils(logic).then((val:any)=>{
                loadChildren(logic).then((value:boolean) => {
                    logic.childrenClasseRef.current = _.cloneDeep(logic.childrenRef.current);
                    logic.childrenClasseRef.current = logic.childrenClasseRef.current
                        // @ts-ignore
                        .sort((a, b) => (a.eleves[0]?.classe?.nom?.localeCompare(b.eleves[0]?.classe?.nom) || a.person.nom.localeCompare(b.person.nom)) );

                        loadJobs(logic, filterApi).then(value =>{
                            loadEmployees(logic, filterApi).then( value => securiteEmployeeBusinessLogic.loadEmployees(logic));
                        });
                    }
                );
            });

        });*/
    },
    generateAccounts: async (logic:any, messages:any, toSend:any):Promise<boolean> => {
        securiteEmployeeStateUpdate.processingStateNormBtn(logic, true);
        return new Promise<boolean>((resolve, reject) => {
            request('POST', `/public/auth/editeur/${logic.userRef.current.id}/generer/compte/${UserCompteType.ERP}`, toSend).then((response) => {
                securiteEmployeeBusinessLogic.loadEmployees(logic);
                securiteEmployeeStateUpdate.processingStateNormBtn(logic, false);
                securiteEmployeeStateUpdate.openConfirmDialogForm(logic, false);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                securiteEmployeeStateUpdate.processingStateNormBtn(logic, false);
                console.error(error);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logic.snackbarShowMessage(error.response.data?.message, 'error')
                    : logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    },
    activerAccounts: async (logic:any, messages:any, toSend:any):Promise<boolean> => {
        securiteEmployeeStateUpdate.processingStateNormBtn(logic, true);
        return new Promise<boolean>((resolve, reject) => {
            request('PUT', `/public/auth/editeur/${logic.userRef.current.id}/activer/compte`, toSend).then((response) => {
                securiteEmployeeBusinessLogic.loadEmployees(logic);
                securiteEmployeeStateUpdate.processingStateNormBtn(logic, false);
                securiteEmployeeStateUpdate.openConfirmDialogForm(logic, false);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                securiteEmployeeStateUpdate.processingStateNormBtn(logic, false);
                console.error(error);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logic.snackbarShowMessage(error.response.data?.message, 'error')
                    : logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    },
    suspendreAccounts: async (logic:any, messages:any, toSend:any):Promise<boolean> => {
        securiteEmployeeStateUpdate.processingStateNormBtn(logic, true);
        return new Promise<boolean>((resolve, reject) => {
            request('PUT', `/public/auth/editeur/${logic.userRef.current.id}/suspendre/compte`, toSend).then((response) => {
                securiteEmployeeBusinessLogic.loadEmployees(logic);
                securiteEmployeeStateUpdate.processingStateNormBtn(logic, false);
                securiteEmployeeStateUpdate.openConfirmDialogForm(logic, false);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                securiteEmployeeStateUpdate.processingStateNormBtn(logic, false);
                console.error(error);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logic.snackbarShowMessage(error.response.data?.message, 'error')
                    : logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    },
    supprimerAccounts: async (logic:any, messages:any, toSend:any):Promise<boolean> => {
        securiteEmployeeStateUpdate.processingStateNormBtn(logic, true);
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/public/auth/editeur/${logic.userRef.current.id}/supprimer/compte/${UserCompteType.ERP}`, toSend).then((response) => {
                securiteEmployeeBusinessLogic.loadEmployees(logic);
                securiteEmployeeStateUpdate.processingStateNormBtn(logic, false);
                securiteEmployeeStateUpdate.openConfirmDialogForm(logic, false);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                resolve(false);
                securiteEmployeeStateUpdate.processingStateNormBtn(logic, false);
                console.error(error);
                // Error message
                error.response?.data?.codeMessage === "DATA_LOCKED" ?
                    logic.snackbarShowMessage(error.response.data?.message, 'error')
                    : logic.snackbarShowMessage(<FormattedMessage id={messages.sb_error} />, 'error');
            });
        });
    },
    loadEmployees: (logic:any) => {
        securiteEmployeeStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET", `/corebase/employees/role/admin-teacher`, {}).then((response) => {
                const items: Employee[] = response.data._embedded?.employeeDTOModelList ?? [];
                const itemsFilter = _.filter(items, item => _.size(item.person?.email) > 1 && item.person?.email?.includes("@"));
                // @ts-ignore
                const datas = _.map(itemsFilter, item => EMPLOYEE_CONSTANT.convertToFrontEnd(logic, item));
                const itemsmp = _.filter(datas, function (item) {
                    return ! _.isEmpty(item.fonctions);
                });

                securiteEmployeeStateUpdate.dataList(logic, _.sortBy(itemsmp, ['person.nom', 'person.prenom'])) ;
                securiteEmployeeStateUpdate.tableKeyValue(logic, new Date().getTime());
                securiteEmployeeStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                securiteEmployeeStateUpdate.tableDataLoading(logic, false);
                console.error(error);
                resolve(false);
            })
        );
    },
}
export default securiteEmployeeBusinessLogic ;