import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {ScolariteEcheancier, SCOLARITEECHEANCIER_CONSTANT} from "../../../../constants/type/ScolariteEcheancier";
import {processingChildren, processingEmployees, processingJobs} from "../../../../helpers/loader_helpers";
import scolaritePaiementStateUpdate from "./ScolaritePaiementStateUpdate";
import {ScolariteData, SCOLARITEDATA_CONSTANT} from "../../../../constants/type/ScolariteData";
import {ScolariteEtat} from "../../../../constants/type/ScolariteEtat";
import {ScolariteType, SCOLARITETYPE_CONSTANT} from "../../../../constants/type/ScolariteType";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {ScolariteGroup, SCOLARITEGROUP_CONSTANT} from "../../../../constants/type/ScolariteGroup";
import {Child} from "../../../../constants/type/Child";
import {Fonction} from "../../../../constants/type/Fonction";
import {Employee} from "../../../../constants/type/Employee";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {ScolariteVersement, SCOLARITEVERSEMENT_CONSTANT} from "../../../../constants/type/ScolariteVersement";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {ScolariteAnnee, SCOLARITEANNEE_CONSTANT} from "../../../../constants/type/ScolariteAnnee";
import {Groupesection, GROUPESECTION_CONSTANT} from "../../../../constants/type/Groupesection";
import {GroupEnfant, GROUPENFANT_CONSTANT} from "../../../../constants/type/GroupEnfant";
import alertMessageStateUpdate from "../../../alertmessage/businesslogic/AlertMessageStateUpdate";

const scolaritePaiementBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };


        const requestbody = ['groups', 'profils', 'scolaritetypes', 'scolaritegroups', 'scolariteannees', 'groupenfants', 'fonctions', 'enfants', 'employees'];
        const convertToFrontEnd = (data:any) => {
            const itemssca : ScolariteAnnee[]   = data.scolariteannees ?? [];
            logic.scolariteAnneesRef.current  = _.sortBy(_.map(itemssca,  item => SCOLARITEANNEE_CONSTANT.convertToFrontEnd(logic, item)), ['dateDebut']);
            scolaritePaiementStateUpdate.idScolariteAnneeSel(logic, _.find(itemssca, {migrateNextYear:false})?.id ?? 0);

            const itemscl : Groupesection[]   = data.groups.content ?? [];
            logic.groupesectionsRef.current  = _.sortBy(_.map(itemscl, item => GROUPESECTION_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.groupesectionsRef.current.forEach((data:Groupesection) => {
                logic.groupesectionMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsst : ScolariteType[]   = data.scolaritetypes.content ?? [];
            logic.scolariteTypeRef.current  = _.sortBy(_.map(itemsst,  item => SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.scolariteTypeRef.current.forEach((data:ScolariteType) => {
                logic.scolariteTypeMapRef.current.set(data.id, data);
            });

            const itemsg : ScolariteGroup[]   = data.scolaritegroups.content ?? [];
            logic.scolariteGroupsRef.current  = _.sortBy(_.map(itemsg,  item => SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.scolariteGroupsRef.current.forEach((data:ScolariteGroup) => {
                logic.scolariteGroupsMapRef.current.set(data.id, data);
            });

            const itemsprof : Fonction[]   = data.fonctions.content ?? [];
            logic = processingJobs(logic, itemsprof);

            const itemchilds : Child[]   = data.enfants.content ?? [];
            logic = processingChildren(logic, itemchilds);

            const itememployees : Employee[]   = data.employees.content ?? [];
            logic = processingEmployees(logic, itememployees);

            const itemge : GroupEnfant[]   = data.groupenfants.content ?? [];
            logic.groupEnfantsRef.current  = _.map(itemge,  item => GROUPENFANT_CONSTANT.convertToFrontEnd(logic, item));
            logic.groupEnfantsRef.current.forEach((data:GroupEnfant) => {
                logic.groupEnfantsMapRef.current.set(data.id, data);
            });
        };

        scolaritePaiementStateUpdate.contentLoaded(logic, false);
        scolaritePaiementStateUpdate.tableDataLoading(logic, true) ;
        scolaritePaiementStateUpdate.paiementListInited(logic, false);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            scolaritePaiementStateUpdate.contentLoaded(logic, true);
            scolaritePaiementStateUpdate.paiementListInited(logic, true);
            scolaritePaiementStateUpdate.tableDataLoading(logic, false) ;
        }).catch(error => {
            scolaritePaiementStateUpdate.contentLoaded(logic, true);
            scolaritePaiementStateUpdate.tableDataLoading(logic, false) ;
            logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });
    },
    saveEdit: async (logic:any, messages:any, toSend:any):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request('POST', `/extra/scolaritenfant`, toSend).then((response) => {
                const dataDB : ScolariteData   =  SCOLARITEDATA_CONSTANT.convertToFrontEnd(logic, response.data);
                // @ts-ignore
                scolaritePaiementStateUpdate.dataSel(logic, dataDB) ;

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    loadScolariteForPaiement: (logic:any, dataToGet:any) => {
        const dataOrigin = _.cloneDeep(dataToGet);
        scolaritePaiementStateUpdate.showDetailsTableContent(logic, false) ;
        return new Promise<boolean>(resolve =>
            request("PUT",  `/extra/scolaritenfant/enfant`, dataToGet).then((response) => {
                const dataDB : ScolariteData   =  SCOLARITEDATA_CONSTANT.convertToFrontEnd(logic, response.data);
                dataDB.versementReference.dateModePaiementForm = dataOrigin.versementReference.dateModePaiementForm ;
                dataDB.scolariteTypeIds = dataOrigin.scolariteTypeIds ;
                dataDB.scolaritePaiementmodeId = dataOrigin.scolaritePaiementmodeId ;
                // @ts-ignore
                scolaritePaiementStateUpdate.dataSel(logic, dataDB) ;
                scolaritePaiementStateUpdate.showDetailsTableContent(logic, true) ;
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                scolaritePaiementStateUpdate.showDetailsTableContent(logic, true) ;
                resolve(false);
            })
        );
    },
    loadVersements: (logic:any, enfantId:number, scolariteTypeIdList:number[], groupesectionId:number, scolariteAnneeId:number) => {
        let endpoint = `/extra/scolaritenfant/enfant/${enfantId}/group/${groupesectionId}/${scolariteAnneeId}` ;

        if(_.isEmpty(scolariteTypeIdList) === false){
            const scolariteTypeIds = _.join(scolariteTypeIdList, ',');
            endpoint = `/extra/scolaritenfant/enfant/${enfantId}/group/${groupesectionId}/scolaritetype/${scolariteTypeIds}/${scolariteAnneeId}` ;
        }
        scolaritePaiementStateUpdate.tableDataLoading(logic, true) ;
        return new Promise<boolean>(resolve =>
            request("GET",  endpoint, {}).then((response) => {
                const items : ScolariteVersement[]   = response.data._embedded?.scolariteVersementDTOModelList ?? [];
                const itemsfrontend = _.map(items,  item => SCOLARITEVERSEMENT_CONSTANT.convertToFrontEnd(logic, item));
                scolaritePaiementStateUpdate.versements(logic, itemsfrontend) ;
                scolaritePaiementStateUpdate.tableDataLoading(logic, false) ;
                scolaritePaiementStateUpdate.tableKeyValue(logic, new Date().getTime());
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                scolaritePaiementStateUpdate.tableDataLoading(logic, false) ;
                resolve(false);
            })
        );
    },
    loadlastVersements: (logic:any, scolariteAnneeId:number) => {
        const lastVersements = 100;
        console.log("scolariteAnneeId loadlastVersements >>> ", scolariteAnneeId);
        scolaritePaiementStateUpdate.showDetailsTableContent(logic, false) ;
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/scolaritenfant/last/${lastVersements}/scolariteannee/${scolariteAnneeId}`, {}).then((response) => {
                const items : ScolariteVersement[]   = response.data._embedded?.scolariteVersementDTOModelList ?? [];
                const itemsfrontend = _.map(items,  item => SCOLARITEVERSEMENT_CONSTANT.convertToFrontEnd(logic, item));
                scolaritePaiementStateUpdate.versements(logic, itemsfrontend) ;
                scolaritePaiementStateUpdate.showDetailsTableContent(logic, true) ;
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                scolaritePaiementStateUpdate.showDetailsTableContent(logic, true) ;
                resolve(false);
            })
        );
    },
    delete: async (logic:any, messages:any, ids:number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/extra/scolaritecheancier/bouquet`, ids).then((response) => {
                // @ts-ignore
                const datas = _.cloneDeep(logic.dataList);
                _.remove(datas, function(n:any) {
                    return ids.includes(n.id);
                });
                const itemsfrontend = _.sortBy(_.map(datas,  item => SCOLARITEECHEANCIER_CONSTANT.convertToFrontEnd(logic, item)), ['scolariteTypeForm.nom']);

                scolaritePaiementStateUpdate.dataList(logic, itemsfrontend) ;
                scolaritePaiementStateUpdate.tableKeyValue(logic, new Date().getTime());
                scolaritePaiementStateUpdate.tableDataLoading(logic, false);

                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    deleteDatas: async (logic:any, messages:any, toSends:ScolariteEcheancier[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:ScolariteEcheancier) => {
                if(_.isNumber(l.id)){ ids.push(l.id);}
            });
            scolaritePaiementBusinessLogic.delete(logic, messages, toSends);
        });
    }
}
export default scolaritePaiementBusinessLogic ;