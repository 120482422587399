import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import {GroupEnfantProfils} from "./GroupEnfantProfils";
import {
    Child,
    childCustomOptionsPlugins,
    childDataImpl,
    columnsChildPlugin,
    excelChildDataMappingPlugin
} from "./Child";
import {
    columnsGroupPlugin,
    excelGroupDataMappingPlugin,
    Groupesection,
    groupeSectionCustomOptionsPlugin,
    groupesectionDataImpl
} from "./Groupesection";
import {ScolariteAnnee, scolariteAnneeDataImpl} from "./ScolariteAnnee";
import messages from "../../page/messages/general/messages";


export const GROUPENFANT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:GroupEnfant) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(groupEnfantDataImpl);
        const data = {...itemInit, ...item} ;
        data.groupesectionForm = _.find(logic.groupesectionsRef.current, {id:data.groupesectionId}) ?? _.cloneDeep(groupesectionDataImpl);

        data.childForm = logic.childrenMapRef.current.get(data.enfantId) ?? _.cloneDeep(childDataImpl);

        data.scolariteAnneeForm = _.find(logic.scolariteAnneesRef.current, {id:data.scolariteAnneeId}) ?? _.cloneDeep(scolariteAnneeDataImpl);
        data.scolariteYearNameForm = data.scolariteAnneeForm?.nom ?? ``;
        data.groupEnfantprofilForm   = _.reduce(data.groupEnfantProfils, function(profilNameReduce, groupEnfantProfil) {
            const profilname = _.find(logic.profilsRef.current, {id:groupEnfantProfil?.profilsId})?.nom ?? ``;
            return `${profilNameReduce}${profilname}, `;
        }, ``);


        return data ;
    },
    convertToBackEnd: (logic:any, item:GroupEnfant) => item,
};

export  const groupEnfantDataImpl: GroupEnfant = {
    id: null,
    enfantId: 0,
    groupesectionId: 0,
    scolariteAnneeId: 0,
    groupEnfantProfils: [],
    common: _.cloneDeep(commonDataImp),
    groupEnfantprofilForm: ``,
    scolariteYearNameForm:``,
    childForm: _.cloneDeep(childDataImpl),
    groupesectionForm:_.cloneDeep(groupesectionDataImpl),
    scolariteAnneeForm:_.cloneDeep(scolariteAnneeDataImpl),
}

export const groupEnfantSchema = yup.object().shape({
    id: yup.number().nullable(),
    enfantId: yup.number().nullable().required(),
    groupesectionId: yup.number().nullable().required(),
    scolariteAnneeId: yup.number().nullable().required(),
    groupesectionForm: yup.mixed<Groupesection>(),
    childForm: yup.mixed<Child>(),
    groupEnfantprofilForm: yup.string(),
    scolariteAnneeForm: yup.mixed<ScolariteAnnee>(),
    scolariteYearNameForm: yup.string(),
    groupEnfantProfils: yup.array().of(yup.mixed<GroupEnfantProfils>()).nonNullable(),
    common: yup.mixed<Common>()
});


export const excelGroupEnfantDataMapping = (
    {rows=[], intl={}, infoSpace=``, childSpace=``, groupSpace=``} :
        {rows:any[]; intl:any; infoSpace: string; childSpace: string; groupSpace: string; }
) => {
    return  _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;
        obj = excelChildDataMappingPlugin({obj, row:data, intl, infoSpace:intl.formatMessage({id:messages.table_h_enfant})});
        obj = excelGroupDataMappingPlugin({obj, row:data, intl, infoSpace:intl.formatMessage({id:messages.table_h_group})});
        obj[intl.formatMessage({id:messages.table_h_profil})] = data.groupEnfantprofilForm;
        obj[intl.formatMessage({id:messages.table_h_scolarite})] = data.scolariteAnneeForm.nom;
        return obj;
    });
}

export const excelGroupEnfantDataMappingPlugin = ({obj={}, row={}, intl={}, infoSpace=``}:{obj:any; row:any; intl:any; infoSpace:string;}) => {
    const data = row?.groupEnfantForm;
    if(_.isObject(data) === false){
        return obj ;
    }
    obj = excelChildDataMappingPlugin({obj, row:data, intl, infoSpace:intl.formatMessage({id:messages.table_h_enfant})});
    obj = excelGroupDataMappingPlugin({obj, row:data, intl, infoSpace:intl.formatMessage({id:messages.table_h_group})});
    obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`] = data.groupEnfantprofilForm;
    obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`] = data.scolariteAnneeForm.nom;
    return obj;
}

export const groupEnfantCustomOptions = {
    columnVisibility : {
        ...childCustomOptionsPlugins.columnVisibility,
        ...groupeSectionCustomOptionsPlugin.columnVisibility,
    }
} ;

export const groupEnfantCustomOptionsPlugin = {
    columnVisibility : {
        'groupEnfantForm':{
            ...groupEnfantCustomOptions.columnVisibility
        },
        'groupEnfantForm.childForm.childnomForm':true,
    }
} ;

export const columnsGroupEnfant = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        ...columnsChildPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_enfant})}),
        ...columnsGroupPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_group})}),
        {
            accessorKey: 'groupEnfantprofilForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`,
            size: 150,
        },
        {
            accessorKey: 'scolariteYearNameForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`,
            size: 150,
        },
    ];
} ;

export const columnsGroupEnfantPlugin = (props:any) => {
    const {intl, nomSpace, options} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        {
            accessorKey: 'groupEnfantForm.scolariteYearNameForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`,
            size: options?.scolariteYearNameFormSize ?? undefined,
        },
        {
            accessorKey: 'groupEnfantForm.groupEnfantprofilForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`,
            size: options?.groupEnfantprofilFormSize ?? undefined,
            filterVariant: 'multi-select',
        },
        ...columnsChildPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_enfant})}),
        ...columnsGroupPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_group})}),
    ];
} ;

export type GroupEnfant = yup.InferType<typeof groupEnfantSchema>;


