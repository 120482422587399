import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {FormattedMessage} from "react-intl";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import messages from "../../../page/messages/general/messages";
// @ts-ignore
import {MRT_PaginationState, type MRT_RowData, type MRT_TableOptions, MRT_Updater} from 'material-react-table';
import EditIcon from "@mui/icons-material/Edit";
import {ExcelButton} from "../../../helpers/helpers";

//define re-useable default table options for all tables in your app
export const getMyReactTableDefaultOptions = <TData extends MRT_RowData>(logic:any): any => {
    const defaultOptions:Partial<MRT_TableOptions<TData>> = (
        {
            layoutMode: 'grid',
            enableColumnResizing:true,
            enableColumnOrdering:true,
            enableRowSelection: true,
            enableEditing: true,
            enableStickyHeader:true,
            enableExpandAll:true,
            enableFacetedValues: true,
            positionActionsColumn: "last",
            initialState: {
                isLoading: false,
                showLoadingOverlay: false,
                ...logic.etat,
            },
            icons:{
                SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
                CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
            },
            muiTablePaperProps:({ table }) => ({
                //not sx
                style: {
                    marginTop: table.getState().isFullScreen ? 65 : 0,
                },
            }),
            muiTableBodyProps: {
                sx: {
                    //stripe the rows, make odd rows a darker color
                    '& tr:nth-of-type(odd) > td': {
                        backgroundColor: '#f9f9f9',
                    },
                },
            },
            muiSearchTextFieldProps: {
                size: 'small',
            },
            muiPaginationProps: {
                variant: 'outlined',
            },
            muiTableContainerProps:{ sx: { maxHeight: '500px' } },
            defaultColumn: {
                muiTableHeadCellProps: {
                    //no useTheme hook needed, just use the `sx` prop with the theme callback
                    sx: (theme) => ({
                        color: theme.palette.text.secondary,
                    }),
                },
                muiFilterTextFieldProps: {
                    variant: 'filled',
                },
            },
            displayColumnDefOptions: {
                'mrt-row-select': {
                    enableColumnOrdering: false,
                    muiTableHeadCellProps: {
                        align: 'center', //change head cell props
                    },
                    muiTableBodyCellProps: {
                        align: 'center', //change head cell props
                    },
                    minSize: 50, //min size enforced during resizing
                    maxSize: 50, //max size enforced during resizing
                    size: 50,
                    grow:false,
                },
                'mrt-row-expand': {
                    enableColumnOrdering: false,
                    minSize: 50, //min size enforced during resizing
                    maxSize: 50, //max size enforced during resizing
                    size: 50,
                    grow:false,
                    muiTableBodyCellProps: ({row}) => ({
                        sx: (theme) => ({
                            fontStyle: row.depth === 0 ? 'normal' : 'italic',
                        }),
                    }),
                },
                'mrt-row-actions': {
                    enableColumnOrdering: false,
                    muiTableHeadCellProps: {
                        align: 'center', //change head cell props
                    },
                    muiTableBodyCellProps: {
                        align: 'center', //change head cell props
                    },
                },
            },
            //...logic.onChange,
        }
    );

    return {
        defaultOptions,
        ...logic,
    }
};


export const MyRenderTopToolbarActions = (
    {
        otherComponents = <></>,
        addNewDataTable = ()=>{},
        handleRowsDelete = ()=>{},
        excelDataMapping = (data:any)=>{},
        styleClasses = undefined,
        table = undefined,
        showAddButton= true,
        showDeleteButton= true,
        addDisabled = false,
        deleteDisabled = true,
        ContainerStyle = {display: 'flex', gap: '0.3rem'},
        export_xls_title = `Excel export`,
        xls_nom_fichier = ``,
    } : {
        otherComponents ?: any;
        handleRowsDelete ?: any;
        addNewDataTable ?: any;
        excelDataMapping ?: any;
        table ?: any;
        styleClasses ?: any;
        addDisabled ?: boolean,
        deleteDisabled ?: boolean,
        showAddButton ?: boolean,
        showDeleteButton ?: boolean,
        ContainerStyle ?: any;
        export_xls_title ?: string;
        xls_nom_fichier ?: string;
    } ={}
) => {
    return (
        <Box
            className={styleClasses?.topToolbarActionsButton}
            sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}
        >
            {otherComponents}
            {
                showAddButton &&
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    disabled={addDisabled}
                    onClick={addNewDataTable}
                >
                    <FormattedMessage id={messages.add_action} />
                </Button>
            }

            {
                showDeleteButton &&
                <Button
                    //only export selected rows
                    color="error"
                    startIcon={<DeleteIcon />}
                    variant="contained"
                    disabled={deleteDisabled}
                    onClick={handleRowsDelete}
                >
                    <FormattedMessage id={messages.delete_action} />
                </Button>
            }


            {
                !_.isEmpty(xls_nom_fichier) &&
                <ExcelButton
                    title={export_xls_title}
                    nomFichier={xls_nom_fichier}
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    excelDataMapping={excelDataMapping}
                    sx={{}}
                />
            }

        </Box>
    );
}



export const MyRenderRowActions = (
    {
        editIcon = <EditIcon/>,
        editRow = ()=>{},
        IconButtonStyle = {color: 'rgba(0, 0, 0, 0.3)'},
        ContainerStyle = {display: 'flex', gap: '0.3rem'},
        styleClasses = undefined,
    } : {
        editIcon ?: any;
        editRow ?: any;
        ContainerStyle ?: any;
        IconButtonStyle ?: any,
        styleClasses ?: any,
    } ={}
) => {
    return (
        <Box sx={ContainerStyle}>
            <Tooltip arrow placement="left" title={<FormattedMessage id={messages.edit} /> }>
                <IconButton
                    onClick={editRow}
                    sx={IconButtonStyle}
                >
                    {editIcon}
                </IconButton>
            </Tooltip>
        </Box>
    );
}



export const getDefaultMRTOptions = <TData extends MRT_RowData>(): Partial<
    MRT_TableOptions<TData>
> => ({
    layoutMode: 'grid',
    enableColumnResizing:true,
    enableColumnOrdering:true,
    enableRowSelection: true,
    enableEditing: true,
    enableStickyHeader:true,
    columnFilterDisplayMode: 'popover',
    positionActionsColumn:"last",
    initialState: {
        density: 'compact',
        isLoading: false,
        showLoadingOverlay: false,
        pagination: { pageSize: 20, pageIndex: 0 }
    },
    icons:{
        SaveIcon: (props:any) => (<DoneIcon {...props} color="primary" sx={{ marginLeft:-2, }} />),
        CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
    },
    //muiTableHeadCellProps: {
    //  sx: { fontSize: '1.1rem' },
    //},

    muiTablePaperProps: ({ table }) => ({
        //not sx
        style: {
            marginTop: table.getState().isFullScreen ? 65 : 0,
        },
    }),
    muiTableBodyProps: {
        sx: {
            //stripe the rows, make odd rows a darker color
            '& tr:nth-of-type(odd) > td': {
                backgroundColor: '#f9f9f9',
            },
        },
    },
    muiSearchTextFieldProps: {
        size: 'small',
    },
    muiPaginationProps: {
        variant: 'outlined',
    },
    muiTableContainerProps:{ sx: { maxHeight: '500px' } },
    defaultColumn: {
        muiFilterTextFieldProps: {
            variant: 'filled',
        },
    },
});
