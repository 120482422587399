import React, {useEffect, useState} from 'react';
import {Box, Grid, MenuItem, TextField} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {USER_GENRE} from "../../constants";
import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import FormCrudButton from "../../components/ui/form/FormCrudButton";
import SaveIcon from "@mui/icons-material/Save";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import PersonalService from "../../service/PersonalService";
import {dropZoneFilePreview} from "../../helpers/helpers";
import ClassroomService from "../../service/ClassroomService";
import {useDispatch, useSelector} from "react-redux";
import {uploadFileRequestAsync} from "../../helpers/axios_helpers";
import {updateUser} from "../../features/user/userSlice";
import {getErrorMessage} from "../../helpers/errorManager";
import {BASEURL_RESOURCES} from "../../config/AppUrl";

function UserProfileForm(props:any) {
    const {
        classes,
        activeLanguage,
        snackbarShowMessage
    } = props;
    const [sexe, setSexe] = useState('');
    const [employeeJob, setEmployeeJob] = useState<any>('');
    const [classroomData, setClassroomData] = useState<any>([]);
    const [classroom, setClassroom] = useState<any | ''>('');
    const [classroomStatus, setClassroomStatus] = useState(false);
    const [jobData, setJobData] = useState<any[]>([]);
    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState<any>(null);
    const [birthDate, setBirthDate] = useState(moment(new Date()));
    const [hireDate, setHireDate] = useState(moment(new Date()));
    const {user} = useSelector((state:any) => state.user);
    const dispatch = useDispatch();

    const personalService = PersonalService();
    const classroomService = ClassroomService();

    const schema = yup.object().shape({
        id: yup.number().nullable(),
        sexe: yup.string().required(),
        prenom: yup.string().min(2).required(),
        nom: yup.string().min(2).required(),
        email: yup.string().email().required(),
        phone1: yup.string().required(),
        note: yup.string().nullable(),
        jobNameId: yup.number().required(),
        poste: yup.string().nullable(),
        showClassroom: yup.boolean(),
        classroomEdit: yup.number()
            .when('showClassroom', {
                is: true, // alternatively: (val) => val == true
                then: (schema:any) => schema.min(1),
                otherwise: (schema:any) => schema.notRequired(),
            }),
    });


    const form = useForm({
        defaultValues:{},
        resolver: yupResolver(schema),
    });

    const {control, register, handleSubmit, formState, reset, setValue, getValues} = form;
    const {errors} = formState;

    const handleSexeChange = (event:any) => {
        setSexe(event.target.value);
    }

    const handleEmployeeJobChange = (event:any) => {
        setClassroom('');
        setClassroomStatus(false);
        setValue('showClassroom', false);
        setValue('classroomEdit', 0);
        for(let i=0; i<jobData.length; i++){
            if(jobData[i].id === event?.target?.value) {
                setEmployeeJob(jobData[i]);
                if(jobData[i].role === 'TEACHER') {
                    setClassroomStatus(true);
                    setValue('showClassroom', true);
                }
            }
        }
    }

    const handleClassroomChange = (event:any) => {
        setClassroom('');
        for(let i=0; i<classroomData.length; i++){
            if(classroomData[i].id === event.target.value){
                setClassroom(classroomData[i]);
            }
        }
    }

    const handleFileChange = (event:any) => {
        const file = event.target.files[0];
        setFile(file);
    }

    const handleBirthDateChange = (date:any) => {
        setBirthDate(date)
    };

    const handleHireDateChange = (date:any) => {
        setHireDate(date)
    };

    const onSubmitEdit = async (data:any) => {
        try {
            const hire_date:any = hireDate;
            const birth_date:any = birthDate;
            const userFind:any = user.userDetails.personDetails;

            let formData:any = new FormData();
            formData.append("photo", file);

            const userToSend = {
                ...userFind,

                person: {
                    id: userFind.person.id,
                    prenom: data?.prenom?.trim(),
                    nom: data?.nom?.trim(),
                    sexe: data?.sexe,
                    photo: file !== null ? fileDataURL : userFind?.person?.photo,
                    dateNaissance: birth_date?._d?.valueOf(),
                    email: data?.email?.trim(),
                    phone1: data?.phone1,
                    phone2: userFind.person?.phone2,
                    cniNumero: userFind?.person?.cniNumero,
                    cniPhoto: userFind?.person?.cniPhoto,
                    poste: data?.poste?.trim(),
                    note: data?.note,
                    adresse: userFind?.person?.adresse,
                    paysPersons: userFind?.person?.paysPersons,
                    //...userFind,
                },
                fonctions: [
                    {
                        ...userFind?.fonctions[0],
                        theDate: hire_date?._d?.valueOf(),
                    }
                ],
                teacherClasses: userFind.teacherClasses,
                rdvChoosable: userFind.rdvChoosable,
                common: userFind.common,
            }

            formData.append('model', new Blob([JSON.stringify(userToSend)], {
                type: "application/json"
            }));

            const response:any = await uploadFileRequestAsync('PUT', `/corebase/employees/${userFind.person.id}`, formData);
            const userUpdated = {
                ...user,
                userDetails: {
                    ...user.userDetails,
                    personDetails: {
                        ...user.userDetails.personDetails,
                        ...response?.data
                    }
                }
            }

            dispatch(updateUser(userUpdated));
            snackbarShowMessage(<FormattedMessage id={messages.sb_edit_success} />);
        }
        catch (error) {
            console.log(error);
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        }
    }


    useEffect(() => {
        (async () => {
            try {
                const dataList:any = await personalService.getAllFonctions();
                setJobData(dataList);

                const classDataList = await classroomService.getAllClassDataOrdered();
                setClassroomData(classDataList);

                //SET USER FORM VALUE
                moment.locale(activeLanguage[0]);
                setValue('prenom', user.userDetails.personDetails.person.prenom);
                setValue('nom', user.userDetails.personDetails.person.nom);
                setValue('sexe', user.userDetails.personDetails.person.sexe);
                setSexe(user.userDetails.personDetails.person.sexe);
                //setFileDataURL(user.userDetails.personDetails.person.photoDataTable);
                const photo:string = `${BASEURL_RESOURCES}/`+user.userDetails.personDetails.person.photo;
                setFileDataURL(photo);
                setValue('email', user.userDetails.personDetails.person.email);
                setValue('phone1', user.userDetails.personDetails.person.phone1);
                setValue('poste', user.userDetails.personDetails.person.poste);
                setValue('note', user.userDetails.personDetails.person.note);
                setValue('showClassroom', false);
                setValue('classroomEdit', 0);
                setClassroom('');
                if(!classroomStatus){
                    setClassroom('');
                }

                for(let i=0; i<dataList.length; i++){
                    if(dataList[i].id === user.userDetails.personDetails.fonctions[0].fonctionId){
                        setValue('jobNameId', dataList[i].id);
                        setEmployeeJob(dataList[i]);
                        setClassroomStatus(false);
                        if(dataList[i].role === 'TEACHER') {
                            setClassroomStatus(true);
                            setValue('showClassroom', true);
                            for(let j=0; j<classDataList.length; j++){
                                if(user.userDetails.personDetails.teacherClasses.length > 0){
                                    if(classDataList[j].id === user.userDetails.personDetails.teacherClasses[0]?.classeId) {
                                        setClassroom(classDataList[j]);
                                        setValue('classroomEdit', classDataList[j].id);
                                    }
                                }
                            }
                        }
                    }
                }

                let hireDateFonction:any = 0;
                moment.locale(activeLanguage[0]);
                const editHireDate = user.userDetails.personDetails.fonctions.length > 0 ? moment(user.userDetails.personDetails.fonctions[0]?.theDate).format("L") : 0;
                const editBirthDate = moment(user.userDetails.personDetails.person?.dateNaissance).format("L");

                if(user.userDetails.personDetails.fonctions.length > 0){
                    hireDateFonction = moment(editHireDate, activeLanguage[0] === 'en' ? ["MM-DD-YYYYTHH:mm:ss.SSSZ"] : ["DD-MM-YYYYTHH:mm:ss.SSSZ"]);
                    setHireDate(hireDateFonction);
                }
                else {
                    setHireDate(moment(new Date()));
                }

                setBirthDate(moment(editBirthDate, activeLanguage[0] === 'en' ? ["MM-DD-YYYYTHH:mm:ss.SSSZ"] : ["DD-MM-YYYYTHH:mm:ss.SSSZ"]));

            }
            catch (error) {
                console.log(error);
            }
        })()
    }, [user]);

    useEffect(() => {
        dropZoneFilePreview(file, setFileDataURL);
    }, [file]);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <div className={classes.dialogAvatar}>
                        <Avatar src={fileDataURL ? fileDataURL : ''} sx={{width:100, height:100}} />
                       {/* <Avatar src={fileDataURL ? fileDataURL : ''} sx={{width:100, height:100}} />*/}
                        <div className={classes.iconButtonLabel}>
                            <label htmlFor="file-upload">
                                <div className={classes.iconButton} style={{textAlign:"center"}}>
                                    <EditIcon />
                                </div>
                                <input
                                    id="file-upload"
                                    type="file"
                                    accept="image/*"
                                    hidden={true}
                                    onChange={(a:any) => handleFileChange(a)}
                                />
                            </label>
                        </div>
                    </div>
                </Grid>

                {/* LEFT PART */}
                <Grid item md={6} xs={12}>
                    <div className={classNames(classes.inputField)}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.lastname_personal}/>}</p>
                        <TextField
                            size="small"
                            color={"secondary"}
                            required
                            sx={{ width: { xs: '100% !important'} }}
                            inputProps={{
                                ...register("nom", {
                                    required: "Required field"
                                })
                            }}
                        />
                        <div className={classes.errorHelper}>
                            {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classNames(classes.inputField)}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.firstname_personal}/>}</p>
                        <TextField
                            size="small"
                            color={"secondary"}
                            required
                            sx={{ width: { xs: '100% !important'} }}
                            inputProps={{
                                ...register("prenom", {
                                    required: "Required field"
                                })
                            }}
                        />
                        <div className={classes.errorHelper}>
                            {errors.prenom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classNames(classes.inputField)}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.birth_date_personal} />}</p>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLanguage[0]}>
                            <DatePicker
                                value={birthDate}
                                maxDate={moment(new Date())}
                                onChange={handleBirthDateChange}
                                slotProps={{ textField: { color: 'secondary' } }}
                                sx={{ width:'100% !important' }}
                            />
                        </LocalizationProvider>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classNames(classes.inputField)}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.genre_personal} />}</p>
                        <TextField
                            select
                            defaultValue={''}
                            value={sexe || ''}
                            sx={{ width: { xs: '100% !important'}}}
                            color={"secondary"}
                            required
                            onChange={(a:any) => handleSexeChange(a)}
                            inputProps={{
                                ...register('sexe', {
                                    required: 'Required field',
                                })
                            }}
                        >
                            {
                                USER_GENRE.map((option:any, index) => (
                                    <MenuItem key={index} value={option.key} sx={{fontWeight:400}}>
                                        {option.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                        <div className={classes.errorHelper}>
                             {errors.sexe && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>


                <Grid item md={6} xs={12}>
                    <div className={classNames(classes.inputField)}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.phone_number_personal}/>}</p>
                        <TextField
                            size="small"
                            color={"secondary"}
                            required
                            sx={{ width: { xs: '100% !important'} }}
                            inputProps={{
                                ...register("phone1", {
                                    required: "Required field"
                                })
                            }}
                        />
                        <div className={classes.errorHelper}>
                            {errors.phone1 && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classNames(classes.inputField)}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.email_personal}/>}</p>
                        <TextField
                            size="small"
                            color={"secondary"}
                            required
                            sx={{ width: { xs: '100% !important'} }}
                            inputProps={{
                                ...register("email", {
                                    required: "Required field"
                                })
                            }}
                        />
                        <div className={classes.errorHelper}>
                            {errors?.email && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classNames(classes.inputField)}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.job_name_personal} />}</p>
                        <TextField
                            select
                            defaultValue={''}
                            value={employeeJob?.id || ''}
                            sx={{ width: { xs: '100% !important'}}}
                            color={"secondary"}
                            required
                            onChange={(a:any) => handleEmployeeJobChange(a)}
                            inputProps={{
                                ...register("jobNameId", {
                                    required: "Required field"
                                })
                            }}
                            disabled
                        >
                            {jobData.map((option:any, index:any) => (
                                <MenuItem key={index} value={option.id} sx={{fontWeight:400}}>
                                    {option.jobName}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div className={classes.errorHelper}>
                            {errors.jobNameId && <span><FormattedMessage id={messages.required_field_child}/></span>}
                        </div>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classNames(classes.inputField)}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.post_personal} />}</p>
                        <TextField
                            size="small"
                            color={"secondary"}
                            sx={{ width: { xs: '100% !important'} }}
                            inputProps={{
                                ...register("poste")
                            }}
                        />
                    </div>
                </Grid>

                {
                    classroomStatus && (
                        <Grid item md={12} xs={12}>
                            <Box
                                className={classNames(classes.inputField)}
                            >
                                <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.dialog_input_classroom} />}</p>
                                <TextField
                                    select
                                    defaultValue={''}
                                    value={classroom.id || ''}
                                    sx={{ width: { xs: '100% !important'}}}
                                    color={"secondary"}
                                    required={classroomStatus}
                                    onChange={(a:any) => handleClassroomChange(a)}
                                    inputProps={{
                                        ...register("classroomEdit", {
                                            required: "Required field"
                                        })
                                    }}
                                    disabled
                                >
                                    {classroomData.map((option:any, index:any) => (
                                        <MenuItem key={index} value={option.id} sx={{fontWeight:400}}>
                                            {option.className}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <div className={classes.errorHelper}>
                                    {errors.classroomEdit && <span><FormattedMessage id={messages.required_field_child}/></span>}
                                </div>
                            </Box>
                        </Grid>
                    )
                }

                <Grid item md={6} xs={12}>
                    <div className={classNames(classes.inputField)}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.hire_date_personal} />}</p>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={activeLanguage[0]}>
                            <DatePicker
                                value={hireDate}
                                maxDate={moment(new Date())}
                                onChange={handleHireDateChange}
                                slotProps={{ textField: { color: 'secondary' } }}
                                sx={{ width:'100% !important' }}
                            />
                        </LocalizationProvider>
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className={classNames(classes.inputField)}>
                        <p style={{padding:0, margin:0, marginBottom:5}}>{<FormattedMessage id={messages.more_info_personal}/>}</p>
                        <TextField
                            size="small"
                            color={"secondary"}
                            sx={{ width:'100% !important' }}
                            inputProps={{
                                ...register("note")
                            }}
                        />
                    </div>
                </Grid>

                <Grid item md={12} xs={12}>
                    <div className={classes.save_button}>
                        <FormCrudButton
                            color="primary"
                            variant="contained"
                            icon={SaveIcon}
                            textButton={<FormattedMessage id={messages.save_form_crud_btn} />}
                            onClickFormCrudAction={handleSubmit(onSubmitEdit)}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default UserProfileForm;
