import React, {useContext} from "react";

import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import scolariteEcheancierEnfantBusinessLogic from "./businesslogic/ScolariteEcheancierEnfantBusinessLogic";
import scolariteEcheancierEnfantStateUpdate from "./businesslogic/ScolariteEcheancierEnfantStateUpdate";
import {ScolariteEcheancierEnfantContext} from "./ScolariteEcheancierEnfant";
import moment from "moment/moment";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ScolariteEcheancierEnfantDeleteForm = (props:any) => {
    const logic: any = useContext(ScolariteEcheancierEnfantContext);
    const styleClasses = logic.styleClasses ;


    const onSubmit = async () => {

        scolariteEcheancierEnfantStateUpdate.processingStateNormBtn(logic, true);
        // @ts-ignore
       // const dataToSend: DocumentScolaire = _.cloneDeep(data);

        // @ts-ignore
       // const toSend = SCOLARITEPROFORMA_CONSTANT.convertToBackEnd(logic, dataToSend);
        scolariteEcheancierEnfantBusinessLogic.deleteDatas(
            logic,
            messages,
            logic.dataListSelected).then((response:any) => {
            scolariteEcheancierEnfantStateUpdate.openDeleteDialogForm(logic, false);
            scolariteEcheancierEnfantStateUpdate.processingStateNormBtn(logic, false);
            scolariteEcheancierEnfantStateUpdate.numeriChange(logic, moment().valueOf());
        }).catch((e:any) => {
            console.log("DELTTE ERROR", e);
            scolariteEcheancierEnfantStateUpdate.processingStateNormBtn(logic, false);
        });
    };

    React.useEffect(() => {
    }, [logic.openDeleteDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={<FormattedMessage id={messages.selection_delete} />}
            open={logic.openDeleteDialogForm}
            setOpen={logic.setOpenDeleteDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={() => onSubmit()}>
                <Box  sx={{ width: '100%' }}>
                    <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                        <FormattedMessage id={messages.selection_to_delete} />
                    </div>
                </Box>
        </DialogFormKiller>
    );
}

export default ScolariteEcheancierEnfantDeleteForm ;