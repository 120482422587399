import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {processingChildren, processingJobs} from "../../../../helpers/loader_helpers";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../../../constants/type/ClasseRoom";
import securiteParentStateUpdate from "./SecuriteParentStateUpdate";
import {Parent, PARENT_CONSTANT} from "../../../../constants/type/Parent";
import {UserCompteType} from "../../../../constants/type/UserCompteType";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {Fonction} from "../../../../constants/type/Fonction";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {Child} from "../../../../constants/type/Child";

const securiteParentBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };

        const requestbody = ['classes', 'profils', 'fonctions', 'enfants'];
        const convertToFrontEnd = (data:any) => {
            const itemscl : ClasseRoom[]   = data.classes.content ?? [];
            logic.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);
            logic.classeRoomRef.current.forEach((data:ClasseRoom) => {
                logic.classeRoomMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsprof : Fonction[]   = data.fonctions.content ?? [];
            logic = processingJobs(logic, itemsprof);

           const itemchilds : Child[]   = data.enfants.content ?? [];
           logic = processingChildren(logic, itemchilds);
        };

        securiteParentStateUpdate.tableDataLoading(logic, true);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            securiteParentBusinessLogic.loadParents(logic);
        }).catch(error => {
            securiteParentStateUpdate.tableDataLoading(logic, false);
            logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });

        // @ts-ignore
        /*loadClasseRooms(logic).then(value => {
            logic.classeRoomRef.current.forEach((data:ClasseRoom) => {
                logic.classeRoomMapRef.current.set(data.id, data);
            });
            loadProfils(logic).then((val:any)=>{
                loadChildren(logic).then((value:boolean) => {

                        loadJobs(logic, filterApi).then(value =>{
                            loadEmployees(logic, filterApi).then( value => );
                        });
                    }
                );
            });

        });*/
    },
    generateAccounts: async (logic:any, messages:any, toSend:any):Promise<boolean> => {
        securiteParentStateUpdate.processingStateNormBtn(logic, true);
        return new Promise<boolean>((resolve, reject) => {
            request('POST', `/public/auth/editeur/${logic.userRef.current.id}/generer/compte/${UserCompteType.PARENT}`, toSend).then((response) => {
                securiteParentBusinessLogic.loadParents(logic);
                securiteParentStateUpdate.processingStateNormBtn(logic, false);
                securiteParentStateUpdate.openConfirmDialogForm(logic, false);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                securiteParentStateUpdate.processingStateNormBtn(logic, false);
                resolve(false);
            });
        });
    },
    activerAccounts: async (logic:any, messages:any, toSend:any):Promise<boolean> => {
        securiteParentStateUpdate.processingStateNormBtn(logic, true);
        return new Promise<boolean>((resolve, reject) => {
            request('PUT', `/public/auth/editeur/${logic.userRef.current.id}/activer/compte`, toSend).then((response) => {
                securiteParentBusinessLogic.loadParents(logic);
                securiteParentStateUpdate.processingStateNormBtn(logic, false);
                securiteParentStateUpdate.openConfirmDialogForm(logic, false);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                securiteParentStateUpdate.processingStateNormBtn(logic, false);
                resolve(false);
            });
        });
    },
    suspendreAccounts: async (logic:any, messages:any, toSend:any):Promise<boolean> => {
        securiteParentStateUpdate.processingStateNormBtn(logic, true);
        return new Promise<boolean>((resolve, reject) => {
            request('PUT', `/public/auth/editeur/${logic.userRef.current.id}/suspendre/compte`, toSend).then((response) => {
                securiteParentBusinessLogic.loadParents(logic);
                securiteParentStateUpdate.processingStateNormBtn(logic, false);
                securiteParentStateUpdate.openConfirmDialogForm(logic, false);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                securiteParentStateUpdate.processingStateNormBtn(logic, false);
                resolve(false);
            });
        });
    },
    supprimerAccounts: async (logic:any, messages:any, toSend:any):Promise<boolean> => {
        securiteParentStateUpdate.processingStateNormBtn(logic, true);
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/public/auth/editeur/${logic.userRef.current.id}/supprimer/compte/${UserCompteType.PARENT}`, toSend).then((response) => {
                securiteParentBusinessLogic.loadParents(logic);
                securiteParentStateUpdate.processingStateNormBtn(logic, false);
                securiteParentStateUpdate.openConfirmDialogForm(logic, false);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                securiteParentStateUpdate.processingStateNormBtn(logic, false);
                resolve(false);
            });
        });
    },
    loadParents: (logic:any) => {
        securiteParentStateUpdate.tableDataLoading(logic, true);
        return new Promise<boolean>(resolve =>
            request("GET", `/corebase/parents`, {}).then((response) => {
                const items: Parent[] = response.data._embedded?.parentDTOModelList ?? [];
                // @ts-ignore
                const itemsFilter: Parent[] = _.filter(items, item => !_.isEmpty(item.enfantsIds) && _.size(item.person?.email) > 1 && item.person?.email?.includes("@"));

                // @ts-ignore
                const datas = _.map(itemsFilter, item => PARENT_CONSTANT.convertToFrontEnd(logic, item));
                securiteParentStateUpdate.dataList(logic, _.sortBy(datas, ['person.nom', 'person.prenom'])) ;
                securiteParentStateUpdate.tableKeyValue(logic, new Date().getTime());
                securiteParentStateUpdate.tableDataLoading(logic, false);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                securiteParentStateUpdate.tableDataLoading(logic, false);
                resolve(false);
            })
        );
    },
}
export default securiteParentBusinessLogic ;