import React, {useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {Box, Grid, MenuItem, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import messages from "../../messages/general/messages";
import {FraisSansEcheanceEnfantContext} from "./FraisOneShotEnfant";
import fraisOneShotEnfantStateUpdate from "./businesslogic/FraisOneShotEnfantStateUpdate";
import fraisOneShotEnfantBusinessLogic from "./businesslogic/FraisOneShotEnfantBusinessLogic";
import {NumericFormat} from "react-number-format";
import {Profils} from "../../../constants/type/Profils";
import InputField from "../../../components/ui/form/InputField";
import {
    ScolariteSansEcheanceEnfant,
    SCOLARITESANSECHEANCEENFANT_CONSTANT,
    scolariteSansEcheanceEnfantDataImpl,
    scolariteSansEcheanceEnfantSchema
} from "../../../constants/type/ScolariteSansEcheanceEnfant";
import {LocalizationProvider, MobileDateTimePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {ScolariteGroup, scolariteGroupDataImpl} from "../../../constants/type/ScolariteGroup";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getProfils(logic:any, profilId:number):Profils | undefined{
    return _.find(logic.profilsRef?.current, {id:profilId})  ;
}
function getProfilName(logic:any, profilId:number):string{
    const profil: Profils | undefined =  getProfils(logic, profilId)  ;
    return _.isObject(profil) ? profil.nom : ``;
}

const FraisOneShotEnfantEditForm = (props:any) => {
    const logic: any = useContext(FraisSansEcheanceEnfantContext);
    const styleClasses = logic.styleClasses ;
    const [scolariteGroupList, setScolariteClasseList] = React.useState<ScolariteGroup[]>([]);

    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(logic.dataSel),
        // @ts-ignore
        resolver: yupResolver(scolariteSansEcheanceEnfantSchema),
    }));
    const {errors} = formRef.current.formState;


    const updateScolariteClasse = () => {
        const scolclasseFiltre  = _.filter(logic.scolariteGroupsRef.current, item => _.includes(logic.scolariteGroupIdListSel, item.id));
        setScolariteClasseList(scolclasseFiltre);
    }

    const handleOnScolariteClasseChange = (event: any) => {
        const idcompo  = parseInt(event.target.value);
        const dataScolariteClasse: ScolariteGroup      = _.find(scolariteGroupList, {id:idcompo}) ?? _.cloneDeep(scolariteGroupDataImpl);
        if(_.isObject(dataScolariteClasse) === false){
            return ;
        }
        const data = _.cloneDeep(logic.dataSel);
        data.scolariteGroupId   = dataScolariteClasse.id ;
        data.montant             = dataScolariteClasse.montant;
        data.ladate              = dataScolariteClasse.ladate;
        data.ladateForm          = dataScolariteClasse.ladateForm;
        data.liredateForm        = dataScolariteClasse.liredateForm;
        data.scolariteTypeForm   = logic.scolariteTypeMapRef.current.get(dataScolariteClasse.scolariteTypeId) ;

        fraisOneShotEnfantStateUpdate.dataSel(logic, data);
        formRef.current.setValue('scolariteGroupId', data.scolariteGroupId);
        formRef.current.setValue('montant', data.montant);
        formRef.current.setValue('ladate', data.ladate);
        formRef.current.setValue('ladateForm', data.ladateForm);
        formRef.current.setValue('liredateForm', data.liredateForm);
    };

    const handleMontantChange = (values: any) => {
        let data:ScolariteSansEcheanceEnfant = _.cloneDeep(logic.dataSel);
        data.montant = values.floatValue;

        fraisOneShotEnfantStateUpdate.dataSel(logic, data);
        formRef.current.setValue('montant', data.montant);
    }

    const updateLaDate = (data:ScolariteSansEcheanceEnfant, ladate: moment.Moment):ScolariteSansEcheanceEnfant => {
        data.ladateForm = ladate;
        // @ts-ignore
        data.ladate = data.ladateForm.valueOf();
        // @ts-ignore
        data.liredateForm =  data.ladateForm?.clone().format('L');

        return data ;
    }
    const handleLaDateChange = (value: moment.Moment,     context: any) => {
        const newday = moment(value.valueOf()).startOf('day');
        let data:ScolariteSansEcheanceEnfant = _.cloneDeep(logic.dataSel);
        data = updateLaDate(data, newday);

        fraisOneShotEnfantStateUpdate.dataSel(logic, data);
        formRef.current.setValue('ladate', data.ladate);
        formRef.current.setValue('ladateForm', data.ladateForm);
        formRef.current.setValue('liredateForm', data.liredateForm);
    };

    const onSubmit = async (data: FormData) => {
       
        fraisOneShotEnfantStateUpdate.processingStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: ScolariteSansEcheanceEnfant = _.cloneDeep(data);
        dataToSend.enfantId             = logic.childSelected.id ;
        // @ts-ignore
        const toSend = SCOLARITESANSECHEANCEENFANT_CONSTANT.convertToBackEnd(logic, dataToSend);

        console.log(toSend);
        fraisOneShotEnfantBusinessLogic.saveEdit(
            logic,
            messages,
            toSend,
            toSend.id).then((response:any) => {
            fraisOneShotEnfantStateUpdate.openEditDialogForm(logic, false);
            fraisOneShotEnfantStateUpdate.processingStateNormBtn(logic, false);
            fraisOneShotEnfantStateUpdate.numeriChange(logic, moment().valueOf());
        }).catch((e:any) => fraisOneShotEnfantStateUpdate.processingStateNormBtn(logic, false));
    };



    useEffect(() => {
        if(logic.openEditDialogForm === false){
            const data = _.cloneDeep(scolariteSansEcheanceEnfantDataImpl);
            fraisOneShotEnfantStateUpdate.dataSel(logic, data);
            formRef.current.reset(data);
            return ;
        }
        formRef.current.reset(_.cloneDeep(logic.dataSel));
        updateScolariteClasse();
        return () => {
            formRef.current.reset(_.cloneDeep(scolariteSansEcheanceEnfantDataImpl));
        };
    }, [logic.openEditDialogForm]);

    console.log(errors);
    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={<FormattedMessage id={messages.selection_edition}/>}
            open={logic.openEditDialogForm}
            setOpen={logic.setOpenEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <InputField
                            label={<FormattedMessage id={messages.the_group}/>}
                            size="small"
                            color={"secondary"}
                            value={logic.childSelected.classeNameForm}
                            disabled
                        />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.schooling_fees_title}/>}
                            sx={{width: {xs: '100% !important'},}}
                            color={"secondary"}
                            value={logic.dataSel.scolariteGroupId}
                            onChange={handleOnScolariteClasseChange}
                            inputProps={{
                                required: "Required field",
                                min: 1,
                            }}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                            {scolariteGroupList?.map((opt: any) => (
                                <MenuItem key={opt.id} value={opt.id} sx={{fontWeight: 400}}>
                                    {opt.nom} ({opt.scolariteTypeForm?.nom})
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>


                    <Grid item md={12} xs={12}>
                        <NumericFormat
                            label={<FormattedMessage id={messages.schooling_amount}/>}
                            value={logic.dataSel.montant}
                            customInput={TextField}
                            thousandSeparator={' '}
                            onValueChange={(values, sourceInfo) => {
                                handleMontantChange(values);
                            }}/>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <div className={styleClasses.inputField}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logic.activeLang}>
                                <MobileDateTimePicker
                                    label={<FormattedMessage id={messages.schooling_period_payment} />}
                                    value={logic.dataSel.ladateForm}
                                    //@ts-ignore
                                    onChange={handleLaDateChange}
                                    views={['year', 'month', 'day']}
                                    slotProps={{ textField: { color: 'secondary' } }}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '100%',
                                            fontWeight: 'normal',
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>
                    
                </Grid>


            </Box>
        </DialogFormKiller>
    );
}

export default FraisOneShotEnfantEditForm ;