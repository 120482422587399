import moment from "moment/moment";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {ObservationCantine} from "./ObservationCantine";
import {Child, childCustomOptionsPlugins, childDataImpl, columnsChild, columnsChildPlugin} from "./Child";
import _ from "lodash";
import {ImsDayChildStuff} from "./ImsDayChildStuff";
import messages from "../../page/messages/general/messages";
import React from "react";
import {FormattedMessage} from "react-intl";
import Typography from "@mui/material/Typography";

//TODO: ADD boolean for nap time
export const IMSDAY_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ImsDay) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(imsDayDataImpl);
        item = {...itemInit, ...item} ;

        //const classeRoom            = _.find(logic.classeRoomRef.current, {id:item?.classeId});
        item.theDateForm            = moment(item.theDate);
        item.commentaireParent      = item.commentaireParent ?? '';
        item.commentaireCantine     = item.commentaireCantine ?? '';
        item.observationTeacher     = item.observationTeacher ?? '';
        item.timeDebutSieste        = item.sieste ? item.timeDebutSieste : null;
        item.timeDebutSiesteForm    = _.isNull(item.timeDebutSieste) ? moment().set({hour:13,minute:0,second:0,millisecond:0}) : moment(item.timeDebutSieste);
        item.timeFinSiesteForm      = _.isNull(item.timeFinSieste) ? moment().set({hour:13,minute:30,second:0,millisecond:0}) : moment(item.timeFinSieste);
        // @ts-ignore
        item.theDateReadForm        = `${_.upperFirst(item.theDateForm.clone().format('dddd'))}, ${item.theDateForm.clone().format('L')}`;
        item.childForm              = logic.childrenMapRef.current.get(item?.enfantId) ?? _.cloneDeep(childDataImpl);
        item.napTimeReadForm        = logic.activeLang === 'en' ? `No nap` : `Pas de sieste`;

        if(item.sieste){
            item.napTimeReadForm = `${item.timeDebutSiesteForm.clone().format('LT')} - ${item.timeFinSiesteForm.clone().format('LT')}`;
        }
        if(_.isObject(item.childForm)){
            item.childNomPrenomForm =  item.childForm.childNomPrenomForm ;
            // @ts-ignore
            item.classeNameForm = item.childForm.classeNameForm  ;
            // @ts-ignore
            item.childphotoForm = item.childForm.childphotoForm ;
        }

        return item;
    },
    convertToBackEnd: (item:ImsDay) => {
        item.theDate = item.theDateForm.valueOf();
        item.timeDebutSieste = item.sieste ? item.timeDebutSiesteForm.valueOf() : null;
        item.timeFinSieste =  item.sieste ?  item.timeFinSiesteForm.valueOf(): null;
        return item ;
    },
};

export  const imsDayDataImpl: ImsDay = {
    id: null,
    theDate: 0,
    menuJourId:0,
    sieste:true,
    timeDebutSieste:0,
    timeFinSieste:0,
    enfantId: 0,
    observationCantine: ObservationCantine.GOOD,
    childForm: _.cloneDeep(childDataImpl),
    observationTeacher:'',
    commentaireParent: '',
    commentaireParentId: 0,
    commentaireCantine:'',
    bowelMvt:0,
    classeId:0,
    incident: '',
    imsDayChildStuffs:[],
    common:  _.cloneDeep(commonDataImp),
    napTimeReadForm:'',
    theDateReadForm:'',
    classeNameForm:'',
    childNomPrenomForm:'',
    childphotoForm:'',
    theDateForm: moment(),
    timeDebutSiesteForm: moment().set({hour:13,minute:0,second:0,millisecond:0}),
    timeFinSiesteForm: moment().set({hour:13,minute:30,second:0,millisecond:0}),
}

export const imsDaySchema = yup.object().shape({
    id: yup.number().nullable(),
    theDate: yup.number(),
    menuJourId: yup.number().min(1).required(),
    sieste: yup.boolean(),
    timeDebutSieste: yup.number().nullable(),
    timeFinSieste: yup.number().nullable(),
    enfantId:  yup.number().min(1).required(),
    observationCantine: yup.mixed<ObservationCantine>().oneOf(Object.values(ObservationCantine)).required(),
    observationTeacher: yup.string().default(''),
    commentaireParent: yup.string().default(''),
    commentaireParentId: yup.number(),
    commentaireCantine: yup.string().default(''),
    bowelMvt: yup.number(),
    classeId: yup.number().required(),
    besoinMenuJour: yup.string().nullable(),
    incident: yup.string().nullable(),
    imsDayChildStuffs:  yup.array().of(yup.mixed<ImsDayChildStuff>()).nonNullable().default([]),
    common:  yup.mixed<Common>(),
    childForm: yup.mixed<Child>(),
    theDateReadForm: yup.string(),
    napTimeReadForm: yup.mixed<any>(),
    childNomPrenomForm: yup.string().default('').required(),
    childphotoForm: yup.string(),
    classeNameForm: yup.string().default(''),
    theDateForm: yup.mixed<moment.Moment>().default(moment()),
    timeDebutSiesteForm: yup.mixed<moment.Moment>().default(moment()),
    timeFinSiesteForm: yup.mixed<moment.Moment>().default(moment()),
});


export const imsDayCustomOptions = {
    columnVisibility : {
        ...childCustomOptionsPlugins.columnVisibility,
        'childForm.childnomForm':true,
        'observationCantine': false,
        'observationTeacher': false,
        'incident': false,
    }
} ;

export const columnsImsDay = (props:any) => {
    const {intl, nomSpace, enfantSpace} = props ;
    const infoSpace = nomSpace ?? ``;

    return [
        // @ts-ignore
        ...columnsChildPlugin({intl, nomSpace: enfantSpace ?? ``}),
        // @ts-ignore
        {
            accessorKey: 'theDateReadForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_ims_day_date})}`,
            size: 125,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
        },
        // @ts-ignore
        {
            accessorKey: 'bowelMvt',
            header: `${infoSpace} ${intl.formatMessage({id: messages.ims_day_bowel})}`,
            size: 80,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
        },
        {
            accessorKey: 'napTimeReadForm',
            header:`${infoSpace} ${intl.formatMessage({id: messages.table_h_ims_day_naptime})}`,
            size: 100,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
        },
        {
            accessorKey: 'observationCantine',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_ims_day_obs_general})}`,
            // @ts-ignore
            Cell: ({ cell }) =>  {
                // @ts-ignore
                const messageId = messages[`ims_menu_obs_${_.toLower(cell.getValue<string>())}`] ;
                if(_.isString(messageId)){
                    return <FormattedMessage id={messageId} />;
                }
                return ``;
            },
            size: 100,
        },
        {
            accessorKey: 'observationTeacher',
            header: `${infoSpace} ${intl.formatMessage({id: messages.comment})}`,
            // @ts-ignore
            Cell: ({ cell }) => (
                <Typography sx={{fontStyle: 'italic'}} variant="body2" gutterBottom>
                    {
                        //@ts-ignore
                        cell.getValue<string>()
                    }
                </Typography>
            ),
        },
        {
            accessorKey: 'commentaireParent',
            header: `${infoSpace} ${intl.formatMessage({id: messages.obs_parent})}`,
            // @ts-ignore
            Cell: ({ cell }) => (
                <Typography sx={{fontStyle: 'italic'}} variant="body2" gutterBottom>
                    {
                        //@ts-ignore
                        cell.getValue<string>()
                    }
                </Typography>
            ),
        },
        {
            accessorKey: 'incident',
            header: `${infoSpace} ${intl.formatMessage({id: messages.incident})}`,
            // @ts-ignore
            Cell: ({ cell }) => (
                <Typography sx={{fontStyle: 'italic'}} variant="body2" gutterBottom>
                    {
                        //@ts-ignore
                        cell.getValue<string>()
                    }
                </Typography>
            ),
        },
    ]
};


export const columnsImsDayPlugin = (props:any) => {
    const {intl, nomSpace, enfantSpace} = props ;
    const infoSpace = nomSpace ?? ``;

    return [
        // @ts-ignore
        ...columnsChild({intl, nomSpace: enfantSpace ?? ``}),
        // @ts-ignore
        {
            accessorKey: 'imsDayForm.theDateReadForm',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_ims_day_date})}`,
            size: 125,
        },
        // @ts-ignore
        {
            accessorKey: 'imsDayForm.bowelMvt',
            header: `${infoSpace} ${intl.formatMessage({id: messages.ims_day_bowel})}`,
            size: 80,
        },
        {
            accessorKey: 'imsDayForm.napTimeReadForm',
            header:`${infoSpace} ${intl.formatMessage({id: messages.table_h_ims_day_naptime})}`,
            size: 100,
        },
        {
            accessorKey: 'imsDayForm.observationCantine',
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_ims_day_obs_general})}`,
            // @ts-ignore
            Cell: ({ cell }) =>  {
                // @ts-ignore
                const messageId = messages[`ims_menu_obs_${_.toLower(cell.getValue<string>())}`] ;
                if(_.isString(messageId)){
                    return <FormattedMessage id={messageId} />;
                }
                return ``;
            },
            size: 100,
        },
        {
            accessorKey: 'imsDayForm.observationTeacher',
            header: `${infoSpace} ${intl.formatMessage({id: messages.comment})}`,
            // @ts-ignore
            Cell: ({ cell }) => (
                <Typography sx={{fontStyle: 'italic'}} variant="body2" gutterBottom>
                    {
                        //@ts-ignore
                        cell.getValue<string>()
                    }
                </Typography>
            ),
        },
        {
            accessorKey: 'imsDayForm.commentaireParent',
            header: `${infoSpace} ${intl.formatMessage({id: messages.obs_parent})}`,
            // @ts-ignore
            Cell: ({ cell }) => (
                <Typography sx={{fontStyle: 'italic'}} variant="body2" gutterBottom>
                    {
                        //@ts-ignore
                        cell.getValue<string>()
                    }
                </Typography>
            ),
        },
        {
            accessorKey: 'imsDayForm.incident',
            header: `${infoSpace} ${intl.formatMessage({id: messages.incident})}`,
            // @ts-ignore
            Cell: ({ cell }) => (
                <Typography sx={{fontStyle: 'italic'}} variant="body2" gutterBottom>
                    {
                        //@ts-ignore
                        cell.getValue<string>()
                    }
                </Typography>
            ),
        },
    ]
};
export type ImsDay = yup.InferType<typeof imsDaySchema>;