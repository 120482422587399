import {Common, commonDataImp} from "./Common";
import moment from "moment/moment";
import _ from "lodash";
import * as yup from "yup";
import {Child, childDataImpl} from "./Child";
import {ScolariteType} from "./ScolariteType";
import {GroupEnfant, groupEnfantDataImpl} from "./GroupEnfant";
import {isPositiveValue} from "../../helpers/helpers";

export const SCOLARITEPROFORMA_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteProforma) => {
        const itemInit = _.cloneDeep(scolariteProformaDataImpl);
        item = {...itemInit, ...item} ;

        item.laDateForm         = isPositiveValue(item.ladate) ? moment(item.ladate) : moment();
        // @ts-ignore
        item.lireLaDate         = item.laDateForm.clone().format('MMMM YYYY');
        item.rappeldateForm     = _.isObject(item.rappeldate) ? moment(item.rappeldate) : null;
        // @ts-ignore
        item.lireRappeldateDateForm  = _.isObject(item.rappeldateForm) ? item.rappeldateForm.clone().format('MMMM YYYY') : '';
        item.nomForm                = `${item.nom} - ${item.lireLaDate}`;
        item.scolariteTypeIds       = _.map(_.split(item.scolaritetypes, ','), (s:string) => _.toNumber(s));
        item.groupesectionsIds      = _.map(_.split(item.groupesections, ','), (s:string) => _.toNumber(s));
        item.profilsIds             = _.map(_.split(item.profils, ','), (s:string) => _.toNumber(s));

        return item ;
    },
    convertToBackEnd: (logic:any, item:ScolariteProforma) => {
        // @ts-ignore
        item.ladate             = item.laDateForm?.clone().valueOf();
        // @ts-ignore
        item.lireLaDate         = item.laDateForm?.clone().format('L');
        // @ts-ignore
        item.rappeldate         = item.rappeldateForm?.clone().valueOf();



        item.groupesections     = _.join(item.groupesectionsIds, ',');

        const fraisName: string[] = [] ;
        // @ts-ignore
        item.scolariteTypeIds.forEach((id:number) => {
            const item : ScolariteType = logic.scolariteTypeMapRef.current.get(id);
            if(_.isObject(item) === false){
                return ;
            }
            // @ts-ignore
            fraisName.push(item.nom);
        });
        item.scolaritetypesnames    = _.join(fraisName, ', ');
        item.scolaritetypes         = _.join(item.scolariteTypeIds, ',');
        item.profils                = _.join(item.profilsIds, ',');
        return item ;
    },
};
export  const scolariteProformaGroupEnfantDataImpl: ScolariteProformaGroupEnfant = {
    childForm: _.cloneDeep(childDataImpl),
    child: _.cloneDeep(childDataImpl),
    groupEnfantForm: _.cloneDeep(groupEnfantDataImpl),
    groupEnfant: _.cloneDeep(groupEnfantDataImpl),
}
export const scolariteProformaGroupEnfantSchema = yup.object().shape({
    child : yup.mixed<Child>(),
    childForm : yup.mixed<Child>(),
    groupEnfantForm : yup.mixed<GroupEnfant>(),
    groupEnfant : yup.mixed<GroupEnfant>(),
});
export type ScolariteProformaGroupEnfant = yup.InferType<typeof scolariteProformaGroupEnfantSchema>;

export  const scolariteProformaDataImpl: ScolariteProforma = {
    id: null,
    scolariteAnneeId: 0,
    ladate: moment().endOf('month').valueOf(),
    rappeldate: 0,
    nom: '',
    message: '',
    notabene: '',
    titreDocument: '',
    profils: '',
    groupesections: '',
    scolaritetypes: '',
    scolaritetypesnames: '',
    scolariteTypeIds:[],
    groupesectionsIds:[],
    profilsIds:[],
    notifier: false,
    groupEnfantMap: new Map(),
    scolariteProformaGroupEnfants:[],
    nomForm: ``,
// @ts-ignore
    laDateForm: moment().endOf('month'),
    lireLaDate: '',
    rappeldateForm: null,
    lireRappeldateDateForm: '',
    common: _.cloneDeep(commonDataImp),
}

export const scolariteProformaSchema = yup.object().shape({
    id:  yup.number().nullable(),
    scolariteAnneeId: yup.number().min(1).required(),
    ladate: yup.number(),
    rappeldate: yup.number(),
    nom: yup.string().default('').required(),
    message: yup.string().default(''),
    notabene: yup.string().default(''),
    titreDocument: yup.string().default(''),
    profils: yup.string(),
    groupesections: yup.string(),
    scolaritetypes: yup.string(),
    scolaritetypesnames: yup.string(),
    notifier: yup.boolean(),
    groupEnfantMap:  yup.mixed<Map<GroupEnfant, Child>>().default(new Map()).nonNullable(),
    scolariteProformaGroupEnfants: yup.array().of(yup.mixed<ScolariteProformaGroupEnfant>()).nonNullable().min(1).required(),
    scolariteTypeIds: yup.array().of(yup.number()).nonNullable().min(1).required(),
    groupesectionsIds: yup.array().of(yup.number()).nonNullable().min(1).required(),
    profilsIds: yup.array().of(yup.number()).nonNullable().notRequired(),
    nomForm: yup.string().default(''),
    laDateForm: yup.object().nullable().required(),
    lireLaDate: yup.string().default(''),
    rappeldateForm: yup.object().nullable(),
    lireRappeldateDateForm: yup.string().default(''),
    common: yup.mixed<Common>(),
});

export type ScolariteProforma = yup.InferType<typeof scolariteProformaSchema>;
