import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {Box, Checkbox, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import messages from "../../messages/general/messages";
import {ScolariteEcheancier, scolariteEcheancierDataImpl} from "../../../constants/type/ScolariteEcheancier";
import {ScolariteEcheancierContext} from "./ScolariteEcheancier";
import scolariteEcheancierStateUpdate from "./businesslogic/ScolariteEcheancierStateUpdate";
import scolariteEcheancierBusinessLogic from "./businesslogic/ScolariteEcheancierBusinessLogic";
import Chip from "@mui/material/Chip";
import scolariteFraisGroupStateUpdate from "../montantdesfrais/businesslogic/ScolariteFraisGroupStateUpdate";
import {ScolariteGroup, scolariteGroupDataImpl} from "../../../constants/type/ScolariteGroup";
import ScolariteEcheancierDetailsList from "./ScolariteEcheancierDetailsList";
import {NumericFormat} from "react-number-format";
import {Profils} from "../../../constants/type/Profils";
import {isNotBlank, isPositiveValue} from "../../../helpers/helpers";
import {ECHEANCIERGROUPING_CONSTANT, echeancierGroupingDataImpl} from "../../../constants/type/EcheancierGrouping";
import {EcheancierGroupingDetails} from "../../../constants/type/EcheancierGroupingDetails";
import {MyMoneyFormat} from "../../../components/ui/numericformat/MyMoneyFormat";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getProfils(logic:any, profilId:number):Profils | undefined{
    return _.find(logic.profilsRef?.current, {id:profilId})  ;
}
function getProfilName(logic:any, profilId:number):string{
    const profil: Profils | undefined =  getProfils(logic, profilId)  ;
    return _.isObject(profil) ? profil.nom : ``;
}

const ScolariteEcheancierEditForm = (props:any) => {
    const logic: any = useContext(ScolariteEcheancierContext);
    const styleClasses = logic.styleClasses ;
    const [scolariteGroupOptions, setScolariteGroupOptions] = useState<ScolariteGroup[]>([]);
    const [groupesectionIds, setGroupesectionIds] = useState<number[]>([]);
    const [scolariteTypeSel, setScolariteTypeSel] = useState<number>(0);
    const [idScolariteAnneeSel, setIdScolariteAnneeSel] = useState<number>(0);


    const formRef = useRef<any>(useForm({
        defaultValues:_.cloneDeep(logic.dataSel),
        // @ts-ignore
        resolver: yupResolver(echeancierGroupingDataImpl),
    }));
    const {errors} = formRef.current.formState;



    const handleOnScolariteAnneChange = (event: any) => {
        const scolariteAnneeId = parseInt(event.target.value);
        setIdScolariteAnneeSel(scolariteAnneeId);
        formRef.current.setValue('scolariteAnneeId', scolariteAnneeId);
    };
    const handleOnTypeChange = (event: any) => {
        const scolariteTypeId = parseInt(event.target.value);
        setScolariteTypeSel(scolariteTypeId);
        formRef.current.setValue('scolariteTypeId', scolariteTypeId);
    };
    const handleOnGroupChange = (event: any) => {
        const ids : number[]= [] ;
        event.target.value.forEach((id:string) => {
            ids.push(parseInt(id));
        });
        setGroupesectionIds(ids);
        formRef.current.setValue('groupesectionIds', ids);
    };

    const updateScolariteGroupChange = (dataCompoSel: ScolariteGroup) => {
        const data = _.cloneDeep(logic.dataSel);
        data.scolariteGroupId = dataCompoSel.id ;
        data.scolariteGroupMontant = dataCompoSel.montant ;
        data.scolariteGroupProfilId = dataCompoSel.profilsId ;
        scolariteFraisGroupStateUpdate.dataSel(logic, data);
        scolariteEcheancierStateUpdate.disabledEcheancierdetailsEdition(logic, _.isEmpty(scolariteGroupOptions) || !isPositiveValue(data.scolariteGroupId));

        formRef.current.setValue('scolariteGroupId', data.scolariteGroupId);
    }

    const handleOnScolariteGroupChange = (event: any) => {
        const idcompo  = parseInt(event.target.value);
        const dataCompoSel      = _.find(scolariteGroupOptions, {id:idcompo}) ?? _.cloneDeep(scolariteGroupDataImpl);
        if(_.isObject(dataCompoSel) === false){
            return ;
        }
        updateScolariteGroupChange(dataCompoSel);
    };


    const updateScolariteFraisGroup = () => {
        let scolariteGroups: ScolariteGroup[] = [];
        if(idScolariteAnneeSel > 0  && scolariteTypeSel > 0 && !_.isEmpty(groupesectionIds)){
            scolariteGroups =  _.filter(logic.scolariteGroupsRef.current, {'scolariteAnneeId':idScolariteAnneeSel,  'scolariteTypeId': scolariteTypeSel, 'groupesectionId': groupesectionIds[0]});
        }
        scolariteEcheancierStateUpdate.disabledEcheancierdetailsEdition(logic, _.isEmpty(scolariteGroups) || !isPositiveValue(logic.dataSel.scolariteGroupId));
        setScolariteGroupOptions(scolariteGroups);

        if(_.size(scolariteGroups) === 1){
            updateScolariteGroupChange(scolariteGroups[0]);
            scolariteEcheancierStateUpdate.disabledEcheancierdetailsEdition(logic, false);
        }
    }

    const onSubmit = async () => {
        if(_.isEmpty(logic.scolaEcheancierDetails)){
            const msg = logic.activeLang === 'en' ? 'Please add financial scheme' : 'Vous devez ajouter au moins un échéancier'
            logic.snackbarShowMessage(msg, 'error');
            return Promise.resolve() ;
        }

        if(_.isEmpty(groupesectionIds)){
            const msg = logic.activeLang === 'en' ? 'Please choose at least one group' : 'Veuillez choisir au moins un group'
            logic.snackbarShowMessage(msg, 'error');
            return Promise.resolve() ;
        }

        if(!isPositiveValue(scolariteTypeSel)){
            const msg = logic.activeLang === 'en' ? 'Please choose type of fees' : 'Veuillez choisir un type de frais'
            logic.snackbarShowMessage(msg, 'error');
            return Promise.resolve() ;
        }

        if(!isPositiveValue(logic.dataSel.scolariteGroupId)){
            const msg = logic.activeLang === 'en' ? 'Please choose schooling' : 'Veuillez choisir la scolarité'
            logic.snackbarShowMessage(msg, 'error');
            return Promise.resolve() ;
        }

        scolariteEcheancierStateUpdate.processingStateNormBtn(logic, true);

        const itemInit = _.cloneDeep(scolariteEcheancierDataImpl);
        // @ts-ignore
        const toSend: ScolariteEcheancier = _.merge(itemInit, ECHEANCIERGROUPING_CONSTANT.convertToBackEnd(logic, logic.dataSel)) ;

        const datasToSendList: ScolariteEcheancier[] = [];
        console.log("groupesectionIds >>> ", groupesectionIds);
        groupesectionIds.forEach(groupesectionId=>{
            const scolGroup:ScolariteGroup = _.find(logic.scolariteGroupsRef.current, {'scolariteAnneeId':idScolariteAnneeSel, 'groupesectionId': groupesectionId, 'scolariteTypeId': scolariteTypeSel, 'profilsId': logic.dataSel.scolariteGroupProfilId }) ?? _.cloneDeep(scolariteGroupDataImpl);

            if(!isPositiveValue(scolGroup?.id) ){
                return ;
            }

            logic.scolaEcheancierDetails.forEach((scolDetails:EcheancierGroupingDetails) => {
                let toSendByClasseId = _.cloneDeep(toSend);
                // @ts-ignore
                toSendByClasseId.id                 = isPositiveValue(scolDetails.id) ? scolDetails.id : null;
                toSendByClasseId.scolariteGroup     = scolGroup;
                toSendByClasseId.nom                = scolDetails.nom;
                toSendByClasseId.code               = scolDetails.code;
                toSendByClasseId.ladate             = scolDetails.ladate;
                toSendByClasseId.montant            = scolDetails.montant;
                toSendByClasseId.ladateForm         = scolDetails.ladateForm;

                datasToSendList.push(toSendByClasseId);
            });
        });

       scolariteEcheancierBusinessLogic.saveEdit(
            logic,
            messages,
            datasToSendList,
            toSend.id).then((response:any) => {
            scolariteEcheancierStateUpdate.openEditDialogForm(logic, false);
            scolariteEcheancierStateUpdate.processingStateNormBtn(logic, false);
        }).catch((e:any) => scolariteEcheancierStateUpdate.processingStateNormBtn(logic, false));
    };

    useEffect(() => {
        updateScolariteFraisGroup();
        return () => {};
    }, [idScolariteAnneeSel, scolariteTypeSel, groupesectionIds]);


    const initAtDialogOpen = () => {
        if(isPositiveValue(logic.dataSel.scolariteGroupId)){
            scolariteEcheancierBusinessLogic.loadScolariteEcheanciersDetails(logic, logic.dataSel.scolariteGroupId);
        }
        let groupesectionIds: number[] = [];
        if(isPositiveValue(logic.dataSel.groupeSectionId)){
            groupesectionIds = [logic.dataSel.groupeSectionId];
        }
        setGroupesectionIds(groupesectionIds);
        setScolariteTypeSel(logic.dataSel.scolariteTypeId);


        const scolariteAnneeId = isPositiveValue(logic.dataSel.scolariteGroupId) ?
            logic.dataSel.scolariteAnneeId :
            _.find(logic.scolariteAnneesRef.current, {migrateNextYear:false})?.id ?? 0;

        setIdScolariteAnneeSel(scolariteAnneeId);
        setScolariteGroupOptions([]);
        scolariteEcheancierStateUpdate.scolaEcheancierDetails(logic, []);
        formRef.current.reset(_.cloneDeep(logic.dataSel));
        formRef.current.reset(logic.dataSel);
        formRef.current.setValue('groupesectionIds', groupesectionIds);
    }



    const scolariteAnneesMenuItems = useMemo<any>(() => {
        return _.map(logic.scolariteAnneesRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight:400}}>
                {item.nom}
            </MenuItem>
        ));
    }, [logic.scolariteAnneesRef?.current]) ;

    const groupesectionsMenuItems = useMemo<any>(() => {
        return _.map(logic.groupesectionsRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                <Checkbox checked={groupesectionIds.includes(item.id)}/>
                <ListItemText primary={item.nom}/>
            </MenuItem>
        ));
    }, [logic.groupesectionsRef?.current, groupesectionIds]) ;

    const scolariteTypeMenuItems = useMemo<any>(() => {
        return _.map(logic.scolariteTypeRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                {item.nom}
            </MenuItem>
        ));
    }, [logic.scolariteTypeRef?.current]) ;

    const scolariteGroupMenuItems = useMemo<any>(() => {
        return _.map(scolariteGroupOptions, item => {
            const nom = isNotBlank(item.code) ? item.code : item.nom ;
            const libelle = `${nom} ${isNotBlank(item.profilsName) ? item.profilsName : ''}`;
            return (
                // @ts-ignore
                <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                    {libelle}
                </MenuItem>
            );
        });
    }, [scolariteGroupOptions]) ;

    useEffect(() => {
        if(logic.openEditDialogForm === false){
            const data = _.cloneDeep(scolariteEcheancierDataImpl);
            scolariteEcheancierStateUpdate.dataSel(logic, data);
            scolariteEcheancierStateUpdate.scolaEcheancierDetails(logic, []);
            formRef.current.reset(data);
            return ;
        }

        initAtDialogOpen();
        return () => {
            formRef.current.reset(_.cloneDeep(scolariteEcheancierDataImpl));
        };
    }, [logic.openEditDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            width={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={isPositiveValue(logic.dataSel.id) ? <FormattedMessage id={messages.selection_edition} /> : <FormattedMessage id={messages.add_item} />}
            open={logic.openEditDialogForm}
            setOpen={logic.setOpenEditDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
            <Box sx={{width: '100%'}}>
                <Grid container spacing={3}>
                    <Grid item md={7} xs={12}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.scolaritee_annee}/>}
                            sx={{width: {xs: '100% !important'},}}
                            color={"secondary"}
                            value={idScolariteAnneeSel}
                            onChange={handleOnScolariteAnneChange}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                            {scolariteAnneesMenuItems}
                        </TextField>
                    </Grid>
                    
                    <Grid item md={5} xs={12}>
                        {
                            isPositiveValue(logic.dataSel.id) &&
                            <TextField
                                color={"secondary"}
                                sx={{ width:'100% !important' }}
                                label={<FormattedMessage id={messages.the_group} />}
                                value={logic.dataSel.groupNomForm}
                                disabled
                                //error={!!errors.period?.message}
                                //helperText={errors.username?.message}
                            />
                        }
                        {
                            !isPositiveValue(logic.dataSel.id) &&
                            <>
                                <InputLabel id="demo-multiple-2-label">
                                    <FormattedMessage id={messages.the_group} />
                                </InputLabel>
                                <Select
                                    labelId="demo-multiple-2-label"
                                    id="demo-multiple-2"
                                    multiple
                                    size="small"
                                    sx={{width: {xs: '100% !important'},}}
                                    color={"secondary"}
                                    value={groupesectionIds}
                                    onChange={handleOnGroupChange}
                                    input={<OutlinedInput label={<FormattedMessage id={messages.the_group}/>}/>}
                                    renderValue={(selected: any) => (
                                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                            {selected.map((value: any) => (
                                                <Chip key={value} label={logic.groupesectionMapRef.current.get(value)?.nom ?? ``}/>
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="0" value="" sx={{fontWeight: 400}}>-------</MenuItem>
                                    {groupesectionsMenuItems}
                                </Select>
                            </>
                        }
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.fees_type}/>}
                            sx={{width: {xs: '100% !important'},}}
                            color={"secondary"}
                            value={scolariteTypeSel}
                            onChange={handleOnTypeChange}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                            {scolariteTypeMenuItems}
                        </TextField>
                    </Grid>


                    <Grid item md={7} xs={12}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.schooling_fees_title}/>}
                            sx={{width: {xs: '100% !important'},}}
                            color={"secondary"}
                            value={logic.dataSel.scolariteGroupId}
                            onChange={handleOnScolariteGroupChange}
                            disabled={_.isEmpty(scolariteGroupOptions)}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                            {scolariteGroupMenuItems}
                        </TextField>

                    </Grid>


                   {/* <Grid item md={7} xs={7}>
                        <InputField
                            label={<FormattedMessage id={messages.header_nom}/>}
                            size="small"
                            color={"secondary"}
                            value={logic.dataSel.scolariteGroup.nom}
                            disabled
                        />
                    </Grid>*/}
                    <Grid item md={5} xs={12} sx={{fontWeight:'bold'}}>
                        <MyMoneyFormat
                            sx={{width:'100% !important', fontWeight:'bold', textAlign:'right'}}
                            id="scolclasfrais"
                            label={<FormattedMessage id={messages.schooling_amount}/>}
                            value={logic.dataSel.scolariteGroupMontant}
                            customInput={TextField}
                            disabled
                        />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <ScolariteEcheancierContext.Provider value={logic}>
                            <ScolariteEcheancierDetailsList />
                        </ScolariteEcheancierContext.Provider>
                        {/*<div className={styleClasses.inputField}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logic.activeLang}>
                                    <MobileDateTimePicker
                                        label={<FormattedMessage id={messages.day} />}
                                        value={logic.dataSel.ladateForm}
                                        //@ts-ignore
                                        onChange={handleLaDateChange}
                                        views={['year', 'month', 'day']}
                                        slotProps={{ textField: { color: 'secondary' } }}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '100%',
                                                fontWeight: 'normal',
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>*/}
                    </Grid>
                </Grid>


            </Box>
        </DialogFormKiller>
    );
}

export default ScolariteEcheancierEditForm ;