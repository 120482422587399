import React, {useEffect, useMemo, useRef, useState} from 'react';
import Box from "@mui/material/Box";
import {MaterialReactTable, MRT_ColumnDef, type MRT_Localization, useMaterialReactTable} from "material-react-table";
import CloseIcon from "@mui/icons-material/Close";
import {Checkbox, FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import useAlertMessageVariables from "./businesslogic/AlertMessageVariables";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import _ from "lodash";
import alertMessageStateUpdate from "./businesslogic/AlertMessageStateUpdate";
import alertMessageBusinessLogic from "./businesslogic/AlertMessageBusinessLogic";
import {AlertMessageParent, columnsAlertMessageParent} from "../../constants/type/AlertMessageParent";
import alertMessageStyles from "./jss/alertmessage-jss";
import messages from "../messages/general/messages";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import PeopleIcon from '@mui/icons-material/People';
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {AlertMessage, ALERTMESSAGE_CONSTANT, alertMessageDataImpl} from "../../constants/type/AlertMessage";
import InputField from "../../components/ui/form/InputField";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import PapperBlock from '../../components/ui/papperBlock/PapperBlock';
import {MyRenderTopToolbarActions} from "../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useBusinessLogic = (props: any): any => {
    const styleClasses = alertMessageStyles();

    return {
        theme: props.theme,
        styleClasses: styleClasses,
        ...useAlertMessageVariables({styleClasses, ...props})
    }
};


function AlertMessageNotification(props:any) {
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;
    const menuLeaveTimerRef = useRef<any>();
    const searchPlaceholderRef =  useRef<string>(logic.activeLang === 'en' ? 'Find a child' : 'Rechercher un enfant');
    const [classeIds, setClasseIds] = useState<number[]>([]);

    const handleOnClasseChange = (event: any) => {
        const ids: number[] = [] ;
        event.target.value.forEach((id:string) => {
            ids.push(parseInt(id));
        });
        setClasseIds(ids);
    };
    const onTitleChange = (event: any) => {
        const data:AlertMessage = _.cloneDeep(logic.dataSel);
        data.nom = event.target.value ;
        alertMessageStateUpdate.dataSel(logic, data);
    };
    const onTextMessageChange = (event: any) => {
        const data:AlertMessage = _.cloneDeep(logic.dataSel);
        data.message = event.target.value ;
        alertMessageStateUpdate.dataSel(logic, data);
    };
    const handleRowsDelete = (rows:any) => {
        const data = _.cloneDeep(logic.dataSel);
        let datas = _.cloneDeep(data.alertMessageParents);
        const idsToDelete:number[] = [];
        rows.map((row:any) => {
            const rowData:AlertMessageParent = datas[row.index];
            // @ts-ignore
            idsToDelete.push(rowData.id);
        });
        _.remove(datas, function(n:AlertMessageParent) {
            return idsToDelete.includes(n.id ?? 0);
        });

        data.alertMessageParents = datas;
        alertMessageStateUpdate.dataSel(logic, data);
        alertMessageStateUpdate.tableKeyValue(logic, new Date().getTime());
    }

    const handleSubmit = async () => {
        if(_.isEmpty(logic.dataSel.alertMessageParents)){
            return ;
        }
        console.log(_.size(logic.dataSel?.nom));
        if(_.size(logic.dataSel?.nom) < 2){
            logic.snackbarShowMessage("Veuillez renseigner le titre", 'error');
            return ;
        }
        if(_.size(logic.dataSel?.message) < 5){
            logic.snackbarShowMessage("Veuillez renseigner le message", 'error');
            return ;
        }
        alertMessageStateUpdate.processingSubmitStateNormBtn(logic, true);
        alertMessageStateUpdate.tableDataLoading(logic, true);
        const toSend = ALERTMESSAGE_CONSTANT.convertToBackEnd(logic, logic.dataSel);

        alertMessageBusinessLogic.saveEdit(logic, messages, toSend).then((response:any) => {
            const data = _.cloneDeep(alertMessageDataImpl);
            alertMessageStateUpdate.dataSel(logic, data);
            alertMessageStateUpdate.processingSubmitStateNormBtn(logic, false);
            alertMessageStateUpdate.tableKeyValue(logic, new Date().getTime());
            alertMessageStateUpdate.tableDataLoading(logic, false);
        }).catch((e:any) => {
            alertMessageStateUpdate.processingSubmitStateNormBtn(logic, false);
            alertMessageStateUpdate.tableKeyValue(logic, new Date().getTime());
            alertMessageStateUpdate.tableDataLoading(logic, false);
        });
    }
    const handleLoadParents = () => {
        if(_.isEmpty(classeIds)){
            return ;
        }

        alertMessageStateUpdate.tableDataLoading(logic, true);
        alertMessageBusinessLogic.loadParents(logic, _.join(classeIds, ',')).then((val:any)=>{
            alertMessageStateUpdate.tableDataLoading(logic, false);
            alertMessageStateUpdate.tableKeyValue(logic, new Date().getTime());
        });
    }


    const columns = useMemo<MRT_ColumnDef<AlertMessageParent>[]>(
        () => [...columnsAlertMessageParent({intl:intl}) ],
        [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    const tableConfig = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.dataSel.alertMessageParents,
        enableEditing: true,
        editDisplayMode: "row",
        enableRowSelection: true,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        icons:{
            CancelIcon: (props:any) => (<CloseIcon color="primary" {...props} />),
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={styleClasses}
                showAddButton={false}
            />
        )
    });

    const classRoomMenuItems = useMemo<any>(() => {
        return _.map(logic.classeRoomRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                <Checkbox checked={classeIds?.includes(item.id)}/>
                <ListItemText primary={item.nom}/>
            </MenuItem>
        ));
    }, [logic.classeRoomRef?.current, classeIds]) ;

    useEffect(() => {
        moment.locale(logic.activeLang);
        alertMessageBusinessLogic.initPostConstruct(logic)
        return () => logic.storeState(tableConfig.getState());
    }, []);


    
    // @ts-ignore
    return (
        <div className={styleClasses.root}>
            <PapperBlock whiteBg noMargin displayNoTitle={true}>
                <div className={styleClasses.tableContainer}>
                    <Box className={styleClasses.searchBox}>
                        <Grid container spacing={2}>
                            <Grid item md={8} xs={8}>
                                <FormControl
                                    sx={{
                                        display: {xs: 'block', sm: 'inline-block'},
                                        marginBottom: {xs: 2},
                                        marginRight: {sm: 2},
                                        width: '100%',
                                    }}
                                >
                                    <Select
                                        multiple
                                        sx={{width: {xs: '100% !important'},}}
                                        color={"primary"}
                                        value={classeIds}
                                        onChange={handleOnClasseChange}
                                        input={<OutlinedInput
                                            label={<FormattedMessage id={messages.classroom_child}/>}/>}
                                        renderValue={(selected: any) => (
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {selected.map((value: any) => (
                                                    <Chip key={value}
                                                          label={logic.classeRoomMapRef.current.get(value)?.nom ?? ``}/>
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key="0" value="" sx={{fontWeight: 400}}>-------</MenuItem>
                                        {classRoomMenuItems}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item md={4} xs={4} sx={{textAlign: 'right'}}>
                                <LoadingButton
                                    loading={logic.processingStateNormBtn}
                                    color="primary"
                                    variant="contained"
                                    loadingPosition="start"
                                    startIcon={<PeopleIcon/>}
                                    aria-label="Loading Parents"
                                    size="large"
                                    onClick={handleLoadParents}
                                >
                                    <FormattedMessage id={messages.loading_list_for_notifications}/>
                                </LoadingButton>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <div className={styleClasses.inputField}>
                                    <InputField
                                        label={<FormattedMessage id={messages.libelle_title}/>}
                                        size="small"
                                        color={"secondary"}
                                        value={logic.dataSel.nom}
                                        onChange={onTitleChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <InputField
                                    label={intl.formatMessage({id: messages.compose_message})}
                                    value={logic.dataSel.message}
                                    variant="outlined"
                                    InputProps={{
                                        rows: 3,
                                        multiline: true,
                                        inputComponent: 'textarea',
                                    }}
                                    onChange={onTextMessageChange}
                                />
                            </Grid>

                        </Grid>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <MaterialReactTable
                                table={tableConfig}
                                key={logic.tableKeyValue}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}
                              sx={{paddingRight: 2, marginTop: 5, marginBottom: 3, textAlign: 'right'}}>
                            <LoadingButton
                                loading={logic.processingSubmitStateNormBtn}
                                color="secondary"
                                variant="contained"
                                loadingPosition="start"
                                startIcon={<SaveIcon/>}
                                aria-label="Send notification"
                                size="large"
                                onClick={handleSubmit}
                            >
                                Send notification
                            </LoadingButton>
                        </Grid>
                    </Grid>

                </div>
            </PapperBlock>
        </div>
    );
}

export default withSnackbar(AlertMessageNotification);
