import {makeStyles} from "@mui/styles";
import {alpha} from "@mui/material";


export const  useStylesChilday = makeStyles((theme: any) => {
    return {
        root: {
            padding: theme.spacing(2),
            paddingTop:0,
            fontSize: '14px',
        },
        active: {
            color: 'red',
        },
        button: {},
        buttonContainer: {
            textAlign: 'center',
            marginBottom: 20,
            '& $button': {
                color: theme.palette.grey[700],
                '&:hover': {},
                '&:focus': {
                    //color: theme.palette.secondary.main,
                },
            },
            '& $active': {
                color: theme.palette.secondary.main,
            },
        },
        content: {
            padding: theme.spacing(0),
            paddingTop: 30,
        },
        childContainer:{
            //marginBottom:10,
            '& .css-1ijs4i7-MuiInputBase-root-MuiOutlinedInput-root ':{
                fontWeight: 400,
                fontSize: '1rem',
            },
            '& .css-1hcsjek-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root':{
                fontWeight: 400,
                fontSize: '1rem',
            },
            '& .css-cs5oy5-MuiButtonBase-root-MuiButton-root':{
                fontSize: '1rem',
            },
        },
        /*titleUserAppointment: {
            position: 'relative',
            fontSize: '18px !important',
            fontWeight: 700,
            color: alpha(theme.palette.text.primary, 0.7),
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
                fontWeight: 600,
                marginBottom: theme.spacing(1)
            },
        },*/
        userDetails:{
            marginBottom : theme.spacing(3),
            padding: 10,
            paddingLeft:15,
            paddingTop:1,
            backgroundColor:"#F5F5F5",
        },
        userInfoBox:{
            flex:1,
            display: 'flex',
            padding: 5,
            paddingTop:0,
            overflow:'hidden',
            marginTop: theme.spacing(2),
        },
        customAvatar:{
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(0),
        },
        customTitle: {
            flex:1,
            paddingTop:7,
            color: alpha(theme.palette.text.primary, 0.7),
        },
        titleUserInfo: {
            fontSize: '16px !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px !important',
            },
            fontWeight: '700 !important',
        },
        btnChangeWeek: {},
        weekInputField: {
            display: 'flex',
            margin: 'auto',
            '& .css-ov5o72-MuiTypography-root': {
                fontWeight: 'normal',
            },
            '& .MuiOutlinedInput-input': {
                fontWeight: 'normal',
                fontSize: '0.97rem',
            },
            '& .css-38f7aq-MuiInputBase-root-MuiOutlinedInput-root': {
                paddingLeft: 0,
                paddingRight: 0
            },
            '& $btnChangeWeek': {
                textTransform: 'none',
                color: theme.palette.text.secondary,
                fontWeight: 500,
            }
        },
        imgItem: {},
        contentCanteen: {
            marginRight: 20,
            fontSize: 16,
            width: '90%',
        },
        boxImg: {
            width: '260px',
            height: '190px',
            overflow: 'hidden',
            marginRight: 20,
            borderRadius: '10px',
            '& $imgItem': {
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    height: '100%',
                },
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }
        },
        toMenuContentCanteen: {
            marginRight: 20,
            fontSize: 15,
            width: '100%'
        },
        howIateConaiter: {
            paddingTop: 15,
        },
        title: {
            fontWeight: 500,
        },
        radioContainer: {
            '& .css-ov5o72-MuiTypography-root': {
                fontWeight: 400,
            },
            '& .css-1m9pwf3': {}
        },
        containerObservation: {
            width: '100%',
            '& .css-18eur9w-MuiInputBase-root-MuiOutlinedInput-root ': {
                fontWeight: 400,
                padding: 0,
            },
            '& .css-ovmfc2-MuiInputBase-root-MuiOutlinedInput-root': {
                fontWeight: 400,
                padding: 0,
                borderColor: theme.palette.secondary.main,
            }
        },
        napTimeContainer: {
            paddingTop: 20,
            //backgroundColor: 'red'
        },
        inputField: {
            marginBottom: 15,
            '& .css-ov5o72-MuiTypography-root': {
                fontWeight: 'normal',
            },
            '& .MuiOutlinedInput-input': {
                fontWeight: 'normal',
                fontSize: '0.97rem',
            },
            '& .css-38f7aq-MuiInputBase-root-MuiOutlinedInput-root': {
                paddingLeft: 0,
                paddingRight: 0,
            }
        },
        bowelContainer: {
            paddingTop: 10,
            '& .css-13f4jq3-MuiInputBase-root-MuiOutlinedInput-root': {
                fontWeight: 'normal',
            },
            '& .MuiOutlinedInput-input': {
                fontWeight: 'normal',
                fontSize: '1rem',
            },
        },
        injuriesContainer: {
            marginTop:20,
            paddingTop: 15,
        },
        injuriesItem: {
            marginBottom: 5,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        },
        errorHelper:{
            color: '#FF0000',
            fontSize: 13,
            paddingTop:7,
            paddingLeft: 2,
        },
        errorMessage: {
            flex:1,
            color: '#FF0000',
            fontSize: 14,
            marginBottom: 15,
        }
    }
});
const useStyles2 = makeStyles((theme: any) => {
    return {
        active: {
            color: 'red',
        },
        button: {},
        buttonContainer: {
            textAlign: 'center',
            marginBottom: 20,
            '& $button': {
                color: theme.palette.grey[700],
                '&:hover': {},
                '&:focus': {
                    //color: theme.palette.secondary.main,
                },
            },
            '& $active': {
                color: theme.palette.secondary.main,
            },
        },
        content: {
            padding: theme.spacing(3),
            paddingTop: 30,
        },
        tableContainer: {
        }
    }
});